import React, {useState, useMemo, useEffect, useRef, useCallback} from 'react';
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import VideoSelector from "./VideoSelector";
import ImageSelector from "./ImageSelector";
import SimpleAutoVideoUploader from "../../../bulk_uploader/SimpleAutoVideoUploader";
import BulkDndPlaceholder from "../../../bulk_uploader/BulkDndPlaceholder";
import NiceModal from "../../../modals/NiceModal";
import SlideItemEditTool from "../../../lessons/tools/SlideItemEditTool";
import PhotoEditorPhotoPicker from "../../../photos/tools/PhotoEditorPhotoPicker";
import SimpleImageUploader from "../../../uploader/tools/SimpleImageUploader";
import SearchOrCreateMaterialsPanel from "../../../materials/panels/SearchOrCreateMaterialsPanel";
import {Checkbox} from "@blueprintjs/core";
import {Editor} from '@tinymce/tinymce-react';
import {TINY_MCE_API_KEY} from "../../../../constants/config";
import Select from "react-select";
import help_svg from "../../../../assets/img/help.svg";
import write_svg from "../../../../assets/img/write.svg";
import {useDispatch, useMappedState} from "redux-react-hook";
import toast from "react-hot-toast";
import axios from "axios";
import {API_ENDPOINT} from "../../../../constants/config";
import {BlueButton, RedButton} from "../../../ira/ui/Buttons";
import Spin from "arui-feather/spin";
import {Code} from "react-content-loader";
import cit_img from "../../../../assets/img/citation.svg";
import SimpleCitationTool from "../../../citation/SimpleCitationTool";
import SimpleFileUploader from "../../../uploader/tools/SimpleFileUploader";
import * as scaleActions from '../../../../redux/actions/TreadmillScaleActions';
import {translate} from "../../../../utils/Translate";
import { Popover2 } from '@blueprintjs/popover2';
import TextToSpeechTool from '../../../ai/tools/TextToSpeechTool';

let TYPES_OPTIONS = [
    {
        label: 'DEFAULT 1',
        value: 'default1',
        scale: {
            name: 'DEFAULT 1',
            a: 97,
            b: 92,
            c: 86
        }
    },
    {
        label: 'DEFAULT 2',
        value: 'default2',
        scale: {
            name: 'DEFAULT 2',
            a: 90,
            b: 75,
            c: 65
        }
    },
    {
        label: 'DEFAULT 3',
        value: 'default3',
        scale: {
            name: 'DEFAULT 3',
            a: 80,
            b: 60,
            c: 40
        }
    },
    {
        label: 'ADD',
        value: 'custom',
        scale: undefined
    }
];

let MINE_LANGUAGES = [
    {
        label: 'EN',
        value: 'en'
    },
    {
        label: 'RU',
        value: 'ru'
    }
];

export default function ItemForm(props) {

    const {
        currentUserId,
        removeBlocksAfter = undefined,
        userScale = undefined,
        newSize = 0,
        isNewSize = false,
        loading,
        newSizeSet = () => {

        },
        removeMine = d => {

        },
        addMine = d => {

        },
        onPlugChange = d => {

        },
        onSave = d => {

        },
        createPlugBeforeInput = (id, type) => {

        },
        setOldPlugData = d => {

        },
    } = props;

    const textSizeLimit = 250;

    const [correctAnswer, setCorrectAnswer] = useState(props.correctAnswer == undefined ? '' : props.correctAnswer);
    const [textTranslate, setTextTranslate] = useState(props.textTranslate == undefined ? false : props.textTranslate);

    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const [imageModalVisible, setImageModalVisible] = useState(false);
    const [helpModalVisible, setHelpModalVisible] = useState(false);
    const [addScaleModalVisible, setAddScaleModalVisible] = useState(false);

    const [textSize, setTextSize] = useState(props.textSize == undefined ? 15 : props.textSize);
    const [hasCountDown, setHasCountDown] = useState(props.hasCountDown == undefined ? false : props.hasCountDown);

    const [textPosition, setTextPosition] = useState(props.textPosition == undefined ? 'center' : props.textPosition);
    const [inputType, setInputType] = useState(props.inputType == undefined ? 'none' : props.inputType);

    const [text, setText] = useState(props.text == undefined ? '' : props.text);
    const [duration, setDuration] = useState(props.duration == undefined ? '3' : props.duration);
    const [videoSrc, setVideoSrc] = useState(props.videoSrc);
    const [imageSrc, setImageSrc] = useState(props.imageSrc);
    const [audioSrc, setAudioSrc] = useState(props.audioSrc);
    const [audioVolume, setAudioVolume] = useState(props.audioVolume === undefined ? '10' : props.audioVolume);
    const [audioPlaybackRate, setAudioPlaybackRate] = useState(props.audioPlaybackRate === undefined ? '10' : props.audioPlaybackRate);
    const [videoVolume, setVideoVolume] = useState(props.videoVolume === undefined ? '10' : props.videoVolume);
    const [videoPlaybackRate, setVideoPlaybackRate] = useState(props.videoPlaybackRate === undefined ? '10' : props.videoPlaybackRate);

    const [autoFit, setAutoFit] = useState(props.autoFit === undefined ? false : props.autoFit);

    const [textColor, setTextColor] = useState(props.textColor === undefined ? '#ffffff' : props.textColor);
    const [textBackgroundColor, setTextBackgroundColor] = useState(props.textBackgroundColor === undefined ? '#000000' : props.textBackgroundColor);
    const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor === undefined ? '#000000' : props.backgroundColor);
    const [hasBackgroundColor, setHasBackgroundColor] = useState(props.hasBackgroundColor === undefined ? false : props.hasBackgroundColor);
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription === undefined ? '' : props.copyrightDescription);
    const [citationModalVisible, setCitationModalVisible] = useState(false);
    const [hasMine, setHasMine] = useState(props.hasMine === undefined ? false : props.hasMine);
    const [mineLanguage, setMineLanguage] = useState(props.mineLanguage === undefined ? MINE_LANGUAGES[0] : props.mineLanguage);
    const [mineAnswer, setMineAnswer] = useState(props.mineAnswer === undefined ? '' : props.mineAnswer);
    const [removeBlock, setRemoveBlock] = useState(props.removeBlock === undefined ? false : props.removeBlock);
    const [videoReplay, setVideoReplay] = useState(props.videoReplay !== undefined ? props.videoReplay : {
        shouldReplay: false,
        times: 1,
    });
    const [audioReplay, setAudioReplay] = useState(props.audioReplay !== undefined ? props.audioReplay : {
        shouldReplay: false,
        times: 1,
    });

    const [coverBackground, setCoverBackground] = useState(props.coverBackground == undefined ? false : props.coverBackground);

    const [scale, setScale] = useState(props.scale === undefined ? TYPES_OPTIONS[0] : props.scale);
    const [customScale, setCustomScale] = useState(!(props.scale === undefined || props.scale.value !== 'custom'));
    const [scaleName, setScaleName] = useState(userScale === undefined ? '' : userScale?.name);
    const [scaleA, setScaleA] = useState(userScale === undefined ? '' : userScale?.a);
    const [scaleB, setScaleB] = useState(userScale === undefined ? '' : userScale?.b);
    const [scaleC, setScaleC] = useState(userScale === undefined ? '' : userScale?.c);
    
    const [canEdit, setCanEdit] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0);
    const [textToSpeechModal, setTextToSpeechModal] = useState(false);

    const editorRef = useRef();
    const dispatch = useDispatch();
    const isHebrew = window.location.href.includes('hebrew');
    
    let video = document.getElementById('item_form_video');

    const currData = useMemo(() => {
        return {
            text: text,
            inputType: inputType,
            textTranslate: textTranslate,
            textPosition: textPosition,
            copyrightDescription: copyrightDescription,
            correctAnswer: correctAnswer,
            duration: +duration,
            imageSrc: imageSrc,
            videoSrc: videoSrc,
            videoVolume: videoVolume,
            videoReplay: videoReplay,
            videoPlaybackRate: videoPlaybackRate,
            audioSrc: audioSrc,
            audioVolume: audioVolume,
            audioReplay: audioReplay,
            audioPlaybackRate: audioPlaybackRate,
            textSize: textSize,
            hasCountDown: hasCountDown,
            autoFit: autoFit,
            backgroundColor: backgroundColor,
            coverBackground: coverBackground,
            textColor: textColor,
            textBackgroundColor: textBackgroundColor,
            hasBackgroundColor: hasBackgroundColor,
            hasMine: hasMine,
            mineLanguage: mineLanguage,
            mineAnswer: mineAnswer,
            removeBlock: removeBlock,
            scale: scale,
            isPlug: props?.isPlug || false
        }
    }, [text, textPosition, correctAnswer, duration, imageSrc, videoSrc, videoVolume, videoReplay, audioSrc, audioVolume, audioReplay,
        textSize, textBackgroundColor, hasCountDown, inputType, backgroundColor, coverBackground, textColor, hasBackgroundColor,
        copyrightDescription, hasMine, mineLanguage, mineAnswer, removeBlock, scale, autoFit, audioPlaybackRate, videoPlaybackRate,
        textTranslate]);

    useEffect(() => {
        if (userScale) {
            TYPES_OPTIONS[TYPES_OPTIONS.length - 1] = {
                label: userScale.name,
                value: 'custom',
                scale: userScale
            };
            if (customScale === true) {
                setScale({
                    label: userScale.name,
                    value: 'custom',
                    scale: userScale
                });
            }
            setScaleName(userScale.name);
            setScaleA(userScale.a);
            setScaleB(userScale.b);
            setScaleC(userScale.c);
        }
    }, [userScale]);

    useEffect(() => {
        if (isNewSize && newSize !== textSize) {
            autoFitTextResize(newSize);
        }
        else {
            setAutoFit(false);
            newSizeSet();
            onSave({
                ...currData,
                autoFit: false
            });
        }
    }, [isNewSize]);

    useEffect(() => {
        if (video) {
            video.volume = videoVolume / 10;
            video.playbackRate = videoPlaybackRate / 10;
        }
    }, [videoVolume, videoPlaybackRate])

    if (video) {
        video.onloadedmetadata = () => {
            if (video.duration > 0) {
                setVideoDuration(video?.duration);
            }
        };
    }

    function autoFitTextResize(newSize) {
        setTextSize(newSize);
        setAutoFit(false);
        newSizeSet();
        onSave({
            ...currData,
            textSize: +newSize,
            autoFit: false,
        });
    }

    return (
        <Wrapper>
            {props?.isPlug == true && canEdit == false ?
                <BlueButton style={{marginTop: 5}}
                    onClick={() => {
                        setCanEdit(true);
                        setOldPlugData({...currData});
                    }}
                >
                    Редактировать заглушку
                </BlueButton>
                :
                <>
                    <Field>
                        <Label>
                            Текст
                        </Label>
                        <Editor
                            onInit={(evt, editor) => {
                                editorRef.current = editor;
                            }}
                            value={text}
                            apiKey={TINY_MCE_API_KEY}
                            // contextmenu_never_use_native={true}
                            onEditorChange={(newValue, editor) => {
                                const direction = editor.selection.getNode().dir;
                                let temp = newValue.replace(/(<[^/>]+>)/g, `<p dir="${direction}">`).replace(/(<\/[^>]+>)/g, '</p>');
                                if (temp.replace(/(<[^>]+>)|(&nbsp;)+/g, '').length <= textSizeLimit) {
                                    // let s = editor.getContent({format: 'text'});
                                    // let t = editor.getContent();
                                    let t = temp;
                                    setText(t);
                                    onSave({
                                        ...currData,
                                        text: t
                                    });
                                }
                            }}
                            init={{
                                min_height: 100,
                                menubar: false,
                                toolbar_location: 'bottom',
                                plugins: isHebrew ? 'charmap directionality' : 'charmap',
                                toolbar: isHebrew ? 'charmap ltr rtl' : 'charmap',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } p { margin: 0; }',
                            }}
                        />
                        {text.replace(/(<[^>]+>)|(&nbsp;)+/g, '').length < 250 - 50 ? null :
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                Осталось символов:
                                <div style={{}}>
                                    {250 - text.replace(/(<[^>]+>)|(&nbsp;)+/g, '').length}
                                </div>
                            </div>
                        }
                    </Field>

                    {
                        // ((videoSrc == undefined || videoSrc == '')) ?
                        <React.Fragment>
                            {text == undefined || text.replace(/ /g, '') == '' ? null :
                                <Field>
                                    <Label>
                                        Размер текста (%, от 1 до 50)
                                    </Label>
                                    <Input value={textSize} onChange={evt => {
                                        let s = evt.target.value;
                                        if (window.isNaN(s) == false && +s > 50) {
                                            return;
                                        }
                                        setTextSize(s);
                                        if (window.isNaN(s) == true) {
                                            return;
                                        }
                                        onSave({
                                            ...currData,
                                            textSize: +s,
                                        });
                                    }}>
                                    </Input>
                                    <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
                                        <AutoFitTextButton style={{ cursor: 'pointer' }} onClick={() => {
                                            onSave({
                                                ...currData,
                                                autoFit: true,
                                            });
                                            setAutoFit(true);
                                        }}>
                                            fit text
                                        </AutoFitTextButton>
                                    </div>
                                </Field>
                            }


                            <Field>
                                <ColorPickersPlaceholder style={{ alignItems: hasBackgroundColor ? 'flex-start' : 'center' }}>
                                    <PickerPlaceholder>
                                        <Label style={{ display: 'flex', flexDirection: 'column' }}>
                                            Цвет текста
                                        </Label>
                                        <ColorPicker style={{ marginLeft: '5px' }} type="color"
                                            value={textColor}
                                            onChange={(evt) => {
                                                let s = evt.target.value;
                                                setTextColor(s);
                                                onSave({
                                                    ...currData,
                                                    textColor: s
                                                });
                                            }}>
                                        </ColorPicker>
                                    </PickerPlaceholder>


                                    <PickerPlaceholder>
                                        <Label>
                                            Цвет фона
                                        </Label>
                                        <ColorPicker style={{ marginLeft: '5px' }} type="color"
                                            value={backgroundColor}
                                            onChange={(evt) => {
                                                let s = evt.target.value;
                                                setBackgroundColor(s);
                                                onSave({
                                                    ...currData,
                                                    backgroundColor: s
                                                });
                                            }} />
                                    </PickerPlaceholder>

                                    <CoverBackground onClick={(evt) => {
                                        let s = coverBackground;
                                        setCoverBackground(!s);
                                        onSave({
                                            ...currData,
                                            coverBackground: !s
                                        })
                                    }}>
                                        Фон поверх всего
                                        <input
                                            type="checkbox"
                                            checked={coverBackground}
                                            onChange={(evt) => {
                                                let s = evt.target.checked;
                                                setCoverBackground(s);
                                                onSave({
                                                    ...currData,
                                                    coverBackground: s
                                                })
                                            }}
                                        />
                                    </CoverBackground>

                                    <TextBackgroundColorPicker>
                                        <TextBackgroundColorCheckbox onClick={() => {
                                            let cur = hasBackgroundColor;
                                            setHasBackgroundColor(!cur);
                                            onSave({
                                                ...currData,
                                                hasBackgroundColor: !cur
                                            });
                                        }}>
                                            Подложка
                                            <input type="checkbox"
                                                checked={hasBackgroundColor}
                                                onChange={() => {
                                                    let cur = hasBackgroundColor;
                                                    setHasBackgroundColor(!cur);
                                                    onSave({
                                                        ...currData,
                                                        hasBackgroundColor: !cur
                                                    });
                                                }} />
                                        </TextBackgroundColorCheckbox>

                                        {hasBackgroundColor === false ? null :
                                            <PickerPlaceholder>
                                                <Label>
                                                    Цвет подложки
                                                </Label>
                                                <ColorPicker style={{ marginLeft: '5px' }} type="color"
                                                    value={textBackgroundColor}
                                                    onChange={(evt) => {
                                                        let s = evt.target.value;
                                                        setTextBackgroundColor(s);
                                                        onSave({
                                                            ...currData,
                                                            textBackgroundColor: s
                                                        });
                                                    }} />
                                            </PickerPlaceholder>
                                        }
                                    </TextBackgroundColorPicker>
                                </ColorPickersPlaceholder>
                            </Field>

                            {text == undefined || text.replace(/ /g, '') == '' ? null :
                                <Field>
                                    <Label>
                                        Положение текста
                                    </Label>
                                    <TypePlaceholder>
                                        <TypeItem selected={textPosition == 'top'} onClick={() => {
                                            setTextPosition('top');
                                            onSave({
                                                ...currData,
                                                textPosition: 'top'
                                            });
                                        }}>
                                            Верх
                                        </TypeItem>
                                        <TypeItem selected={textPosition == 'center'} onClick={() => {
                                            setTextPosition('center');
                                            onSave({
                                                ...currData,
                                                textPosition: 'center'
                                            });
                                        }}>
                                            Центр
                                        </TypeItem>
                                        <TypeItem selected={textPosition == 'bottom'} onClick={() => {
                                            setTextPosition('bottom');
                                            onSave({
                                                ...currData,
                                                textPosition: 'bottom'
                                            });
                                        }}>
                                            Низ
                                        </TypeItem>
                                    </TypePlaceholder>
                                </Field>
                            }
                        </React.Fragment> //: null

                    }


                    <Field>
                        <Label>
                            Длительность (секунды)
                        </Label>
                        <Input value={duration} onChange={evt => {
                            let s = evt.target.value;
                            setDuration(s);
                            if (window.isNaN(s) == true) {
                                return;
                            }
                            onSave({
                                ...currData,
                                duration: +s,
                            });
                        }}>
                        </Input>
                    </Field>

                    {props?.isPlug === true ? null :
                        <Field>
                            <Label>
                                Видео
                            </Label>
                            {videoSrc == undefined ?
                                <AddVideoDiv>
                                    <AddSpan onClick={() => {
                                        setVideoModalVisible(true);
                                    }}>
                                        Добавить видео
                                    </AddSpan>
                                </AddVideoDiv>
                                :
                                <div>
                                    {(imageSrc == undefined || imageSrc.trim() == '') && coverBackground == false ? null : 
                                        <>
                                            <VideoPlaceholder id="item_form_video" controls>
                                                <source src={videoSrc} />
                                            </VideoPlaceholder>
                                            <div>{videoDuration > 0 ? 'Длительность видео: ' + videoDuration.toFixed(2) / (videoPlaybackRate / 10) : ''}</div>
                                        </>
                                    }
                                    <VolumePlaceholder>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Громкость</span>
                                            <input style={{ maxWidth: '120px' }} type='range' min='0' max='10' value={videoVolume}
                                                onChange={(evt) => {
                                                    let vol = evt.target.value;
                                                    setVideoVolume(vol);
                                                    onSave({
                                                        ...currData,
                                                        videoVolume: vol
                                                    });
                                                }
                                                } />
                                        </div>
                                        <div>{videoVolume * 10}%</div>
                                    </VolumePlaceholder>

                                    <VolumePlaceholder>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <span style={{ fontWeight: 'bold', width: 'fit-content' }}>Скорость воспроизведения</span>
                                                <input style={{ width: '80px' }} type='range' min='1' max='10'
                                                    value={videoPlaybackRate}
                                                    onChange={(evt) => {
                                                        let rate = evt.target.value;
                                                        setVideoPlaybackRate(rate);
                                                        onSave({
                                                            ...currData,
                                                            videoPlaybackRate: rate,
                                                        });
                                                    }
                                                    } />
                                            </div>
                                            <div style={{width: '45px', textAlign: 'right'}}>{videoPlaybackRate / 10}</div>
                                        </VolumePlaceholder>

                                    <VideoReplayPlaceholder>
                                        <VideoReplay onClick={() => {
                                            setVideoReplay({
                                                ...videoReplay,
                                                shouldReplay: !videoReplay.shouldReplay
                                            });
                                            onSave({
                                                ...currData,
                                                videoReplay: {
                                                    ...videoReplay,
                                                    shouldReplay: !videoReplay.shouldReplay
                                                }
                                            });
                                        }}>
                                            Повторять видео
                                            <input style={{ cursor: 'pointer' }}
                                                type="checkbox"
                                                checked={videoReplay?.shouldReplay}
                                                onChange={() => {
                                                    setVideoReplay({
                                                        ...videoReplay,
                                                        shouldReplay: !videoReplay.shouldReplay
                                                    });
                                                    onSave({
                                                        ...currData,
                                                        videoReplay: {
                                                            ...videoReplay,
                                                            shouldReplay: !videoReplay.shouldReplay
                                                        }
                                                    });
                                                }}
                                            />
                                        </VideoReplay>

                                        {videoReplay === undefined || videoReplay?.shouldReplay === false ? null :
                                            <VideoReplayPlaceholder>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    Количество повторов
                                                    <VideoReplayInput value={videoReplay?.times} disabled={videoReplay.noLimit} onChange={evt => {
                                                        let s = evt.target.value;
                                                        if (s > 0 || s === '') {
                                                            setVideoReplay({
                                                                ...videoReplay,
                                                                times: s
                                                            });
                                                            onSave({
                                                                ...currData,
                                                                videoReplay: {
                                                                    ...videoReplay,
                                                                    times: s
                                                                }
                                                            });
                                                        }
                                                    }
                                                    } />
                                                </div>
                                            </VideoReplayPlaceholder>
                                        }
                                    </VideoReplayPlaceholder>

                                    <div>
                                        <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => {
                                            setVideoSrc(undefined);
                                            setHasBackgroundColor(false);
                                            onSave({
                                                ...currData,
                                                videoSrc: null,
                                                hasBackgroundColor: false
                                            });
                                        }}>
                                            удалить видео
                                        </span>
                                    </div>
                                </div>
                            }
                        </Field>
                    }

                    <Field>
                        <Label>
                            Картинка
                        </Label>
                        {imageSrc == undefined
                            ? <AddVideoDiv>
                                <AddSpan onClick={() => {
                                    setImageModalVisible(true);
                                }}>
                                    Добавить картинку из редактора
                                </AddSpan>
                                <UploadPlaceholder>
                                    <SimpleImageUploader onSave={x => {
                                        setImageSrc(x);
                                        setImageModalVisible(false);
                                        onSave({
                                            ...currData,
                                            imageSrc: x
                                        });
                                    }} />
                                </UploadPlaceholder>

                            </AddVideoDiv> :
                            <div>
                                <div>
                                    <img src={imageSrc} style={{ maxWidth: '100%' }} />
                                </div>
                                <div>
                                    <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => {
                                        setImageSrc(undefined);
                                        onSave({
                                            ...currData,
                                            imageSrc: null
                                        });
                                    }}>
                                        удалить картинку
                                    </span>
                                </div>
                            </div>
                        }
                    </Field>

                    {props?.isPlug === true ? null :
                        <Field>
                            <Label>
                                Аудио
                            </Label>
                            <UploadPlaceholder>
                                {audioSrc === undefined || audioSrc === '' ?
                                    <div>
                                        <SimpleFileUploader
                                            onSave={s => {
                                                setAudioSrc(s);
                                                onSave({
                                                    ...currData,
                                                    audioSrc: s,
                                                    hasAudio: s == undefined || s == '' ? false : true
                                                });
                                            }}
                                        />

                                        <GenerateAudioButton onClick={() => setTextToSpeechModal(true)}>AI аудио</GenerateAudioButton>
                                    </div>
                                    :
                                    <div>
                                        <VolumePlaceholder>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <span style={{ fontWeight: 'bold' }}>Громкость</span>
                                                <input style={{ maxWidth: '120px' }} type='range' min='0' max='10'
                                                    value={audioVolume}
                                                    onChange={(evt) => {
                                                        let vol = evt.target.value;
                                                        setAudioVolume(vol);
                                                        onSave({
                                                            ...currData,
                                                            audioVolume: vol
                                                        });
                                                    }
                                                    } />
                                            </div>
                                            <div>{audioVolume * 10}%</div>
                                        </VolumePlaceholder>

                                        <VolumePlaceholder>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                <span style={{ fontWeight: 'bold', width: 'fit-content' }}>Скорость воспроизведения</span>
                                                <input style={{ width: '80px' }} type='range' min='1' max='10'
                                                    value={audioPlaybackRate}
                                                    onChange={(evt) => {
                                                        let rate = evt.target.value;
                                                        setAudioPlaybackRate(rate);
                                                        onSave({
                                                            ...currData,
                                                            audioPlaybackRate: rate,
                                                        });
                                                    }
                                                    } />
                                            </div>
                                            <div style={{width: '45px', textAlign: 'right'}}>{audioPlaybackRate / 10}</div>
                                        </VolumePlaceholder>

                                        <VideoReplayPlaceholder>
                                            <VideoReplay onClick={() => {
                                                setAudioReplay({
                                                    ...audioReplay,
                                                    shouldReplay: !audioReplay.shouldReplay
                                                });
                                                onSave({
                                                    ...currData,
                                                    audioReplay: {
                                                        ...audioReplay,
                                                        shouldReplay: !audioReplay.shouldReplay
                                                    }
                                                });
                                            }}>
                                                Повторять аудио
                                                <input style={{ cursor: 'pointer' }}
                                                    type="checkbox"
                                                    checked={audioReplay?.shouldReplay}
                                                    onChange={() => {
                                                        setAudioReplay({
                                                            ...audioReplay,
                                                            shouldReplay: !audioReplay.shouldReplay
                                                        });
                                                        onSave({
                                                            ...currData,
                                                            audioReplay: {
                                                                ...audioReplay,
                                                                shouldReplay: !audioReplay.shouldReplay
                                                            }
                                                        });
                                                    }}
                                                />
                                            </VideoReplay>

                                            {audioReplay === undefined || audioReplay?.shouldReplay === false ? null :
                                                <VideoReplayPlaceholder>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        Количество повторов
                                                        <VideoReplayInput value={audioReplay?.times} disabled={audioReplay.noLimit} onChange={evt => {
                                                            let s = evt.target.value;
                                                            if (s > 0 || s === '') {
                                                                setAudioReplay({
                                                                    ...audioReplay,
                                                                    times: s
                                                                });
                                                                onSave({
                                                                    ...currData,
                                                                    audioReplay: {
                                                                        ...audioReplay,
                                                                        times: s
                                                                    }
                                                                });
                                                            }
                                                        }
                                                        } />
                                                    </div>
                                                </VideoReplayPlaceholder>
                                            }
                                        </VideoReplayPlaceholder>

                                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => {
                                            setAudioSrc('');
                                            onSave({
                                                ...currData,
                                                audioSrc: undefined,
                                                hasAudio: false
                                            });
                                        }}>
                                            удалить
                                        </span>
                                    </div>
                                }
                            </UploadPlaceholder>
                        </Field>
                    }

                    <Field>
                        <Label>
                            <span>
                                Информация об источниках
                            </span>
                            <CitImg src={cit_img} onClick={() => {
                                setCitationModalVisible(true);
                            }} />
                        </Label>
                        <Textarea value={copyrightDescription} placeholder={'Информация об источниках'}
                            onChange={evt => {
                                let s = evt.target.value;
                                setCopyrightDescription(s);
                                onSave({
                                    ...currData,
                                    copyrightDescription: s
                                });
                            }}></Textarea>
                    </Field>

                    <Field>
                        <Label>
                            Показывать обратный отсчет
                        </Label>
                        <TypePlaceholder>
                            <TypeItem selected={hasCountDown} onClick={() => {
                                setHasCountDown(true);
                                onSave({
                                    ...currData,
                                    hasCountDown: true
                                });
                            }}>
                                Да
                            </TypeItem>
                            <TypeItem selected={!hasCountDown} onClick={() => {
                                setHasCountDown(false);
                                onSave({
                                    ...currData,
                                    hasCountDown: false
                                });
                            }}>
                                Нет
                            </TypeItem>
                        </TypePlaceholder>
                    </Field>

                    {props.isPlug === true || hasMine === true ? null :
                        <Field>
                            <Label>
                                Тип ответа
                            </Label>
                            <TypePlaceholder>
                                <TypeItem selected={(inputType == 'none')}
                                    style={{ fontSize: 11 }}
                                    onClick={() => {
                                        setInputType('none');
                                        onSave({
                                            ...currData,
                                            inputType: 'none'
                                        });
                                    }}>
                                    Не требовать
                                </TypeItem>
                                <TypeItem selected={(inputType == 'text')}
                                    style={{ fontSize: 12 }}
                                    onClick={() => {
                                        setInputType('text');
                                        onSave({
                                            ...currData,
                                            inputType: 'text'
                                        });
                                    }}>
                                    Клавиатура
                                </TypeItem>

                                {videoSrc != undefined ? null :
                                    <TypeItem selected={(inputType == 'voice')}
                                        style={{ fontSize: 12 }}
                                        onClick={() => {
                                            setInputType('voice');
                                            onSave({
                                                ...currData,
                                                inputType: 'voice'
                                            });
                                        }}>
                                        Микрофон
                                    </TypeItem>
                                }
                            </TypePlaceholder>
                            {inputType != 'voice' && inputType != 'text' ? null :
                                <CreatePlug onClick={() => {
                                    let type = inputType == 'voice' ? 'mic' : 'text'
                                    createPlugBeforeInput(props.id, type);
                                }}>
                                    Создать блок-предупреждение
                                </CreatePlug>
                            }
                        </Field>
                    }

                    {inputType !== 'text' ? null :
                        <Field>
                            <Label>
                                Правильный ответ
                                <Popover2
                                    interactionKind='hover'
                                    content={
                                        <div style={{padding: 15}}>
                                            <span>Для ввода нескольких вариантов используйте двойные квадратные скобки [[]].<br/>
                                            Пример: [[Polar star]] [[North Star]]</span>
                                        </div>
                                    }
                                    placement='auto'
                                >
                                    <Help src={help_svg} style={{paddingLeft: 5}}/>
                                </Popover2>
                            </Label>
                            <Textarea value={correctAnswer} onChange={evt => {
                                let s = evt.target.value;
                                setCorrectAnswer(s);
                                onSave({
                                    ...currData,
                                    correctAnswer: s
                                });
                            }}>
                            </Textarea>

                            <div
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}
                                onClick={() => {
                                    setTextTranslate(!textTranslate);
                                    onSave({
                                        ...currData,
                                        textTranslate: !textTranslate
                                    });
                                }}
                            >
                                <Label>
                                    Перевод
                                    <Popover2
                                        interactionKind='hover'
                                        content={
                                            <div style={{padding: 15}}>
                                                <span>Автоматическая проверка выполняться не будет</span>
                                            </div>
                                        }
                                        placement='auto'
                                    >
                                        <Help src={help_svg} style={{paddingLeft: 5}}/>
                                    </Popover2>
                                </Label>
                                <input
                                    type='checkbox'
                                    checked={textTranslate}
                                    onChange={(evt) => {
                                        setTextTranslate(evt.target.checked);
                                        onSave({
                                            ...currData,
                                            textTranslate: evt.target.checked
                                        });
                                    }}
                                />
                            </div>

                            <Label style={{ marginTop: '5px' }}>Шкала</Label>
                            <div style={{ width: '100%', display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <div style={{ flexGrow: '1' }}>
                                    <Select
                                        maxMenuHeight={100}
                                        placeholder={'Выберите шкалу'}
                                        value={TYPES_OPTIONS.filter(x => (x.value === scale.value))[0]}
                                        options={TYPES_OPTIONS}
                                        onChange={x => {
                                            setScale(x);
                                            if (x.value === 'custom') {
                                                setCustomScale(true);
                                                if (scaleName.trim() === '') {
                                                    setAddScaleModalVisible(true);
                                                }
                                            } else {
                                                setCustomScale(false);
                                            }
                                            onSave({
                                                ...currData,
                                                scale: x
                                            });
                                        }}
                                    />
                                </div>
                                {customScale === false ? null :
                                    <ChangeScale src={write_svg} onClick={() => setAddScaleModalVisible(true)}></ChangeScale>
                                }
                                <Popover2
                                    interactionKind='hover'
                                    content={
                                        <div style={{padding: 15, fontSize: '16px'}}>
                                            <div style={{ alignSelf: 'center' }}><b>Методические рекомендации</b></div>
                                            <div>D-1: Шкалу рекомендуется применять для итогового контроля</div>
                                            <div>D-2: Шкалу рекомендуется применять для предытогового контроля</div>
                                            <div>D-3: Шкалу рекомендуется применять для промежуточного контроля</div>
                                            <div style={{ fontSize: '14px' }}>
                                                <b>Внимание: </b>применение шкал D-2 и D-3 невозможно, если в ответе предусматривается менее 3 лексических единиц
                                            </div>
                                        </div>
                                    }
                                    placement='auto'
                                >
                                    <Help src={help_svg} style={{paddingLeft: 5}}/>
                                </Popover2>
                                {/* <Help src={help_svg} onClick={() => setHelpModalVisible(true)} /> */}
                            </div>
                        </Field>
                    }

                    {props.isPlug === true || inputType === 'text' || inputType === 'voice' ? null :
                        <Field>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Label>Минирование</Label>
                                <ToggleInput type="checkbox" id="switch" checked={hasMine} onChange={(evt) => {
                                    let s = !hasMine;
                                    setRemoveBlock(false);
                                    if (s === true) {
                                        let result = addMine(props.id);
                                        if (result === true) {
                                            setHasMine(s);
                                            onSave({
                                                ...currData,
                                                hasMine: s,
                                                removeBlock: false
                                            });
                                        }
                                    } else {
                                        removeMine(props.id);
                                        setHasMine(s);
                                        onSave({
                                            ...currData,
                                            hasMine: s,
                                            removeBlock: false
                                        });
                                    }
                                }} />
                                <ToggleLabel htmlFor="switch">Toggle</ToggleLabel>
                            </div>

                            {hasMine === false ? null :
                                <div>
                                    <LangPlaceholder>
                                        <Label>Язык</Label>
                                        <Lang>
                                            <Select
                                                maxMenuHeight={100}
                                                value={MINE_LANGUAGES.filter(x => (x.value === mineLanguage.value))[0]}
                                                options={MINE_LANGUAGES}
                                                onChange={x => {
                                                    setMineLanguage(x);
                                                    onSave({
                                                        ...currData,
                                                        mineLanguage: x
                                                    });
                                                }}
                                            />
                                        </Lang>
                                    </LangPlaceholder>

                                    <Label style={{ marginTop: '5px' }}>
                                        Правильный ответ
                                        <Popover2
                                            interactionKind='hover'
                                            content={
                                                <div style={{padding: 15}}>
                                                    <span>Для ввода нескольких вариантов используйте двойные квадратные скобки [[]].<br/>
                                                    Пример: [[Polar star]] [[North Star]]</span>
                                                </div>
                                            }
                                            placement='auto'
                                        >
                                            <Help src={help_svg} style={{paddingLeft: 5}}/>
                                        </Popover2>
                                    </Label>
                                    <Textarea value={mineAnswer} onChange={(evt) => {
                                        let s = evt.target.value;
                                        setMineAnswer(s);
                                        onSave({
                                            ...currData,
                                            mineAnswer: s
                                        });
                                    }}></Textarea>
                                </div>
                            }
                            {hasMine == false ? null :
                                <CreatePlug onClick={() => {
                                    let type = 'mic';
                                    createPlugBeforeInput(props.id, type);
                                }}>
                                    Создать блок-предупреждение
                                </CreatePlug>
                            }
                        </Field>
                    }


                    {hasMine == true ? null :
                        <Field>
                            <RemoveBlockPlaceholder onClick={() => {
                                let value = removeBlock;
                                setRemoveBlock(!value);
                                onSave({
                                    ...currData,
                                    removeBlock: !value
                                });
                            }}>
                                <div>Скрыть блок после 2-го проигрывания</div>
                                <input type="checkbox" checked={removeBlock} onChange={() => {
                                    let value = removeBlock;
                                    setRemoveBlock(!value);
                                    onSave({
                                        ...currData,
                                        removeBlock: !value
                                    });
                                }} />
                            </RemoveBlockPlaceholder>
                        </Field>
                    }

                    {props?.isPlug == false ? null :
                        <Field>
                            <BlueButton onClick={() => {
                                onPlugChange({...currData});
                                setOldPlugData(undefined);
                                setCanEdit(false);
                            }}>
                                Сохранить изменения заглушки
                            </BlueButton>
                        </Field>
                    }

                    <Sidebar
                        width={Math.min(1200, window.innerWidth)}
                        visible={videoModalVisible} onCloserClick={() => {
                            setVideoModalVisible(false);
                        }}>

                        {videoModalVisible == false ? null :
                            <div>
                                <SearchOrCreateMaterialsPanel
                                    type={'materials'}
                                    hasSimpleUploader={true}
                                    onSelect={m => {
                                        setVideoSrc(m.url);
                                        let copyright = copyrightDescription;
                                        if (m.copyrightDescription !== undefined && m.copyrightDescription.trim() !== '') {
                                            copyright = copyrightDescription + '\n' + m.copyrightDescription;
                                            setCopyrightDescription(copyright);
                                        }
                                        setVideoModalVisible(false);

                                        let newInputType = currData.inputType == 'voice' ? 'none' : currData.inputType;
                                        setInputType(newInputType);
                                        if (currData.inputType == 'voice') {
                                            toast('Тип ответа изменен на "Не требовать"');
                                        }

                                        onSave({
                                            ...currData,
                                            videoSrc: m.url,
                                            copyrightDescription: copyright,
                                            inputType: newInputType
                                        });
                                    }} />
                                {/*<BulkDndPlaceholder*/}
                                {/*    singleMode={true}*/}
                                {/*    onSingleFileUploaded={d => {*/}
                                {/*        setVideoSrc(d.url);*/}
                                {/*        setVideoModalVisible(false);*/}
                                {/*        onSave({*/}
                                {/*            ...currData,*/}
                                {/*            videoSrc: d.url*/}
                                {/*        });*/}
                                {/*    }}*/}
                                {/*/>*/}
                            </div>
                        }

                    </Sidebar>

                    {imageModalVisible == false ? null :
                        <NiceModal onClose={() => {
                            setImageModalVisible(false);
                        }}>

                            <div
                                style={{
                                    width: `calc(100vw - 160px)`,
                                    height: `calc(100vh - 160px)`
                                }}
                            >
                                <PhotoEditorPhotoPicker
                                    url={currData?.imageSrc}
                                    copyrightDescription={props?.item?.content?.copyrightDescription}
                                    hasCopyrightSection={true}
                                    onSave={(newUrl, copText) => {
                                        setImageSrc(newUrl);
                                        setImageModalVisible(false);
                                        onSave({
                                            ...currData,
                                            imageSrc: newUrl
                                        });
                                    }}
                                />
                            </div>

                        </NiceModal>
                    }

                    {/* {helpModalVisible === false ? null :
                        <NiceModal onClose={() => {
                            setHelpModalVisible(false);
                        }}>

                            <HelpModalPlaceholder>
                                <div style={{ alignSelf: 'center' }}><b>Методические рекомендации</b></div>
                                <div>D-1: Шкалу рекомендуется применять для итогового контроля</div>
                                <div>D-2: Шкалу рекомендуется применять для предытогового контроля</div>
                                <div>D-3: Шкалу рекомендуется применять для промежуточного контроля</div>
                                <div style={{ fontSize: '14px' }}>
                                    <b>Внимание: </b>применение шкал D-2 и D-3 невозможно, если в ответе предусматривается менее 3 лексических единиц
                                </div>
                            </HelpModalPlaceholder>

                        </NiceModal>
                    } */}

                    {addScaleModalVisible === false ? null :
                        <NiceModal onClose={() => {
                            setAddScaleModalVisible(false);
                        }}>

                            <AddScaleModalPlaceholder>
                                <b>Изменить шкалу</b>
                                <div>Название</div>
                                <Input value={scaleName} onChange={(evt) => {
                                    setScaleName(evt.target.value);
                                }}></Input>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div>Шкала</div>
                                        <div>Нижнее значение</div>
                                        <div>Верхнее значение</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div>A</div>
                                        <ScaleInput value={scaleA} onChange={(evt) => {
                                            let s = evt.target.value;
                                            if ((s > 0 && s < 100) || s === '') {
                                                setScaleA(s);
                                            }
                                        }} />
                                        <ScaleInput value='100' disabled={true} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div>B</div>
                                        <ScaleInput value={scaleB} onChange={(evt) => {
                                            let s = evt.target.value;
                                            if ((s > 0 && s < 100) || s === '') {
                                                setScaleB(s);
                                            }
                                        }} />
                                        <ScaleInput value={scaleA === '' ? scaleA : +scaleA - 1} onChange={(evt) => {
                                            let s = evt.target.value;
                                            if ((s > 0 && s < 100) || s === '') {
                                                setScaleA(+s + 1);
                                            }
                                        }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div>C</div>
                                        <ScaleInput value={scaleC} onChange={(evt) => {
                                            let s = evt.target.value;
                                            if ((s > 0 && s < 100) || s === '') {
                                                setScaleC(s);
                                            }
                                        }} />
                                        <ScaleInput value={scaleB === '' ? scaleB : +scaleB - 1} onChange={(evt) => {
                                            let s = evt.target.value;
                                            if ((s > 0 && s < 100) || s === '') {
                                                setScaleB(+s + 1);
                                            }
                                        }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div>D</div>
                                        <ScaleInput value='0' disabled={true} />
                                        <ScaleInput value={scaleC === '' ? scaleC : +scaleC - 1} onChange={(evt) => {
                                            let s = evt.target.value;
                                            if ((s > 0 && s < 100) || s === '') {
                                                setScaleC(+s + 1);
                                            }
                                        }} />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <AddScaleButton onClick={async () => {
                                        if (scaleName.trim() === '' || scaleA === '' || scaleB === '' || scaleC === '') {
                                            toast.error('Заполните все поля');
                                        } else if (scaleA < scaleB || scaleB < scaleC || scaleA < scaleC) {
                                            toast.error('Шкала введена неверно');
                                        } else {
                                            let scale = {
                                                name: scaleName,
                                                a: scaleA,
                                                b: scaleB,
                                                c: scaleC
                                            };
                                            await dispatch(scaleActions.updateTreadmillScale({
                                                userId: currentUserId,
                                                scale: scale
                                            }));
                                            setAddScaleModalVisible(false);
                                        }
                                    }}>
                                        {loading == true ?
                                            <Spin visible={true} />
                                            :
                                            <div>{translate('save')}</div>
                                        }
                                    </AddScaleButton>

                                    <RedButton onClick={() => setAddScaleModalVisible(false)}>
                                        Отмена
                                    </RedButton>
                                </div>
                            </AddScaleModalPlaceholder>

                        </NiceModal>
                    }

                    {textToSpeechModal == false ? null : 
                        <NiceModal onClose={() => setTextToSpeechModal(false)}>
                            <TextToSpeechPlaceholder>
                                <TextToSpeechTool
                                    canSave={true}
                                    onSave={data => {
                                        let url = data.url;
                                        setAudioSrc(url);
                                        onSave({
                                            ...currData,
                                            audioSrc: url,
                                            hasAudio: url == undefined || url == '' ? false : true
                                        });
                                        setTextToSpeechModal(false);
                                    }}
                                />
                            </TextToSpeechPlaceholder>
                        </NiceModal>
                    }

                    <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                        setCitationModalVisible(false);
                    }}>

                        {citationModalVisible == false ? null :
                            <InnerPlaceholder>
                                <SimpleCitationTool
                                    onQuote={s => {
                                        setCopyrightDescription(`${copyrightDescription == '' ? '' : `${copyrightDescription}\n`}${s}`);
                                        setCitationModalVisible(false);
                                    }}
                                />
                            </InnerPlaceholder>
                        }

                    </Sidebar>
                </>
            }
        </Wrapper>
    );
}

const TypePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 42px;
  border: 1px solid lightgrey;
  border-radius: 1000px;
`;

const TypeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${props => (props.selected == true ? 'white' : 'black')};
  background: ${props => (props.selected == true ? '#5A5C63' : 'white')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};

  :hover {
    opacity: 0.75;
  }

  :first-of-type {
    border-bottom-left-radius: 1000px;
    border-top-left-radius: 1000px;
  }

  :last-of-type {
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px;
  }
`;


const AddVideoDiv = styled.div`
  padding: 5px;
  text-align: center;
`;

const AddSpan = styled.span`
  cursor: pointer;
`;

const SmallVideo = styled.video`
  width: 100%;
  height: 300px;
  background: black;
`;

const SaveButton = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background: blue;
  border-radius: 4px;
  color: white;
  box-sizing: border-box;

  :hover {
    opacity: 0.75;
  }
`;

const ButtonPlaceholder = styled.div`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Textarea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  min-height: 6em;
  resize: vertical;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
`;

const Field = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
`;

const Label = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;

const AutoFitTextButton = styled.div`
  margin: auto;
  border-radius: 50px;
  border: 1px solid lightgrey;
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  
  :hover {
    opacity: 0.8;
  }
`;

const ColorPicker = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 27px;
  height: 30px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  :active, :focus, :hover {
    outline: none;
  }

  ::-webkit-color-swatch {
    border-radius: 5px;
    border: 1px solid #e1e1e1;
  }

  ::-moz-color-swatch {
    border-radius: 5px;
    border: 1px solid #e1e1e1;
  }
`;

const ToggleInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  user-select: none;
  :checked + label {
    background: #ff951c;
  }

  :checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
`;

const ToggleLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  user-select: none;
  :after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  :active:after {
    width: 26px;
  }
`;

const LangPlaceholder = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Lang = styled.div`
  
`;

const Arrow = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  transform: rotate(225deg);
  margin-right: 5px;
`;

const Help = styled.img`
  cursor: pointer;
  scale: 1.1;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const HelpModalPlaceholder = styled.div`
  width: 550px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AddScaleModalPlaceholder = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AddScaleButton = styled.div`
  height: 44px;
  border: 1px solid grey;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 26px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const ChangeScale = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const ScaleInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  box-sizing: border-box;
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
  z-index: 100;
`;

const ColorPickersPlaceholder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PickerPlaceholder = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const VideoPickerPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploadPlaceholder = styled.div`
  
`;

const VideoPlaceholder = styled.video`
  width: 100%;
`;

const VolumePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

const VideoReplayPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 8px;
`;

const VideoReplay = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const VideoReplayInput = styled.input`
  width: 50px;
`;

const TextBackgroundColorCheckbox = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TextBackgroundColorPicker = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RemoveBlockPlaceholder = styled.div`
  display: flex;
  cursor: pointer;
`;

const CreatePlug = styled.button`
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  cursor: pointer;
  outline: none;

  :hover {
    background-color: #5A5C63;
    color: white;
  }

  :active, :focus {
    outline: none;
  }
`;

const CoverBackground = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const GenerateAudioButton = styled.button`
    background-color: transparent;
    border: 1px solid lightgrey;
    border-radius: 100px;
    padding: 7px 15px;
    cursor: pointer;

    :focus, :active {
        outline: none;
    }

    :hover {
        opacity: 0.7;
    }
`;

const TextToSpeechPlaceholder = styled.div`
    width: 400px;
`;