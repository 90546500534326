import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import styled from 'styled-components';
import ItemForm from "./ItemForm";
import TextSlide from "../slides/TextSlide";
import VideoSlide from "../slides/VideoSlide";
import add_svg from "../../../../assets/img/add_treadmill.svg";
import NiceModal from "../../../modals/NiceModal";
import {translate} from "../../../../utils/Translate";
import mine_svg from "../../../../assets/img/mine.svg";
import duplicate_all from "../../../../assets/img/duplicate_all.svg"
import duplicate_selected from "../../../../assets/img/duplicate_selected.svg"
import preview from "../../../../assets/img/play_preview.svg"
import new_slide from "../../../../assets/img/new_slide.svg"
import plug_svg from "../../../../assets/img/plug_treadmill.svg"
import ExerciseAnswerInputTool from "../../../exercises/tools/ExerciseAnswerInputTool";
import UserTextInputTool from "../tools/UserTextInputTool";
import axios from "axios";
import {API_ENDPOINT} from "../../../../constants/config";
import {useDispatch, useMappedState} from "redux-react-hook";
import {Code} from "react-content-loader";
import RoundCountDown from "../tools/RoundCountDown";
import ls from 'local-storage';
import * as scaleActions from '../../../../redux/actions/TreadmillScaleActions';
import keyboard_svg from '../../../../assets/img/keyboard.svg';
import mic_gif from '../../../../assets/img/microphone.gif';

const fixWithDurations = items => {
    let enhItems = JSON.parse(JSON.stringify(items));
    if (items.length == 0) {
        return [];
    }
    enhItems[0].from = 0;
    for (let i in enhItems) {
        if (i == 0) {
            enhItems[0].to = enhItems[0].from + +enhItems[0].duration;
            continue;
        }
        enhItems[i].from = enhItems[i - 1].to;
        enhItems[i].to = enhItems[i].from + enhItems[i].duration;
    }
    return enhItems;
}

const getNewItemsAfterMovingUp = (items, i, value) => {
    if (i == 0) {
        return items;
    }
    let enhItems = items.map(x => ({...x, duration: +x.to - +x.from}));

    if (i - +value <= 0) {
        enhItems = [enhItems[i]].concat(enhItems.slice(0, i)).concat(enhItems.slice(i + 1));
    } else {
        enhItems = enhItems.slice(0, i - +value).concat(enhItems[i]).concat(enhItems.slice(i - +value, i)).concat(enhItems.slice(i + 1));
    }
    return fixWithDurations(enhItems);
}

const getNewItemsAfterMovingDown = (items, i, value) => {
    if (i == items.length - 1) {
        return items;
    }
    let enhItems = items.map(x => ({...x, duration: +x.to - +x.from}));

    if (i + +value >= items.length - 1) {
        enhItems = enhItems.slice(0, i).concat(enhItems.slice(i + 1)).concat(enhItems[i]);
    } else {
        enhItems = enhItems.slice(0, i).concat(enhItems.slice(i + 1, i + +value + 1)).concat([enhItems[i]]).concat(enhItems.slice(i + +value + 1));
    }

    return fixWithDurations(enhItems);
}

const duplicateSlides = (items, ids, end) => {
    let newItems = items.map(x => ({...x, duration: +x.to - +x.from}));
    ids = ids.filter(x => x !== null)
    for (let id of ids) {
        let index = 0;
        while (id !== newItems[index]?.id && index < newItems.length) {
            index++;
        }
        let previousItem = newItems[index];
        let newFrom = previousItem.to;
        let newBlock = {
            id: `t-${+new Date()}-${Math.floor(Math.random() * 1000000)}`,
            from: newFrom,
            to: newFrom + +previousItem.duration,
            duration: previousItem?.duration,
            text: previousItem?.text,
            textPosition: previousItem?.textPosition,
            textSize: previousItem?.textSize,
            textColor: previousItem?.textColor,
            backgroundColor: previousItem?.backgroundColor,
            hasBackgroundColor: previousItem?.hasBackgroundColor,
            textBackgroundColor: previousItem?.textBackgroundColor,
            shouldRecord: false,
            inputType: previousItem?.inputType,
            correctAnswer: previousItem?.inputType === 'text' ? previousItem?.correctAnswer : '',
            copyrightDescription: previousItem?.copyrightDescription,
            imageSrc: previousItem?.imageSrc,
            audioSrc: previousItem?.audioSrc,
            audioVolume: previousItem?.audioVolume,
            audioReplay: previousItem?.audioReplay,
            audioPlaybackRate: previousItem?.audioPlaybackRate,
            videoSrc: previousItem?.videoSrc,
            videoVolume: previousItem?.videoVolume,
            videoReplay: previousItem?.videoReplay,
            videoPlaybackRate: previousItem?.videoPlaybackRate,
            scale: previousItem?.scale,
            hasCountDown: previousItem?.hasCountDown,
            isPlug: previousItem.isPlug === undefined ? false : previousItem.isPlug,
            removeBlock: previousItem?.removeBlock,
            coverBackground: previousItem?.coverBackground,
        }
        if (end === false) {
            if (index === newItems.length - 1) {
                newItems = newItems.concat([newBlock]);
            } else {
                newItems = addNewItemAfterSlide(newItems, index, newBlock);
            }
        } else {
            newItems = newItems.concat([newBlock]);
        }
    }
    let dItems = JSON.parse(JSON.stringify(newItems));
    for (let i in dItems) {
        dItems[i].id = `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`;
    }
    return fixWithDurations(dItems);
}

const addNewItemAfterSlide = (items, i, newBlock) => {
    let enhItems = items.map(x => ({...x, duration: +x.to - +x.from}));
    enhItems = enhItems.slice(0, i + 1).concat(newBlock).concat(enhItems.slice(i + 1));
    return fixWithDurations(enhItems);
}

const RemoveMineSlide = (items, minedSlides, id) => {
    let remove = items.filter(x => (x.id === id))[0];
    let temp = [];
    for (let item of minedSlides) {
        if (item.id !== remove.id) {
            temp = temp.concat(item);
        }
    }
    return temp;
}

const AddMineSlide = (items, minedSlides, id) => {
    if (minedSlides.length < 2) {
        let add = items.filter(x => (x.id === id))[0];
        return minedSlides.concat(add);
    }
}

function indexInItems(items, id) {
    let index = 0;
    for (let item of items) {
        if (item.id === id) {
            return index;
        }
        index++;
    }
    return index;
}

function plugSettings(items) {
    for (let item of items) {
        if (item?.isPlug === true) {
            return item;
        }
    }
    return undefined;
}

function changeAllPlugs(items, plug) {
    let newItems = items;

    for (let item of newItems) {
        if (item?.isPlug === true) {
            item.duration = +plug.duration;
            item.text = plug.text;
            item.textPosition = plug.textPosition;
            item.textSize = plug.textSize;
            item.textColor = plug.textColor;
            item.backgroundColor = plug.backgroundColor;
            item.hasBackgroundColor = plug.hasBackgroundColor;
            item.shouldRecord = plug.shouldRecord;
            item.inputType = plug.inputType;
            item.copyrightDescription = plug.copyrightDescription;
            item.imageSrc = plug.imageSrc;
            item.hasCountDown = plug.hasCountDown;
        }
    }
    return fixWithDurations(newItems);
}
export default function TreadmillEditor(props) {
    const {
        onSave = (arr, saved) => {

        },
        openPreview = (time) => {

        }
    } = props;

    const dispatch = useDispatch();

    const {
        currentUser,
        currentUserId,
        scale,
        loading
    } = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        return {
            currentUser: state.users.usersMap.get(uId),
            currentUserId: uId,
            scale: state.treadmillScale.scale,
            loading: state.treadmillScale.loading
        }
    }));

    const [saved, setSaved] = useState(true);
    const [newSize, setNewSize] = useState(0);
    const [isNewSize, setIsNewSize] = useState(false);

    const [items, setItems] = useState(props.items == undefined ? [] : props.items);
    const [selectedId, setSelectedId] = useState(undefined);

    const selectedItem = (selectedId == undefined) ? undefined : items.filter(x => (x.id == selectedId))[0];
    const [multipleSelect, setMultipleSelect] = useState(selectedId === undefined ? [] : [items.filter(x => (x.id == selectedId))[0]]);
    const [moveValue, setMoveValue] = useState(1);
    const [copyrightText, setCopyrightText] = useState(undefined);

    const [duplicateModal, setDuplicateModal] = useState(false);
    const [minedSlides, setMinedSlides] = useState([]);

    const [emptyAnswer, setEmptyAnswer] = useState([]);
    const [userScale, setUserScale] = useState(undefined);

    const [oldPlugData, setOldPlugData] = useState(undefined);
    const [oldItems, setOldItems] = useState(props.items == undefined ? [] : props.items);

    useEffect(() => {
        dispatch(scaleActions.loadTreadmillScale(currentUserId));

        let slide = ls(`treadmill_slide_${props.id}`);
        if (slide !== null) {
            let element = document.getElementById(`${slide}`);
            if (element) {
                element.scrollIntoView({block: 'center', behavior: 'smooth'});
            }
            setSelectedId(slide);
            setMultipleSelect([slide]);
            ls.remove(`treadmill_slide_${props.id}`);
        }
    }, [])

    useEffect(() => {
        setUserScale(scale.data)
    }, [scale])

    useEffect(() => {
        if (selectedItem !== undefined) {
            let audio = document.getElementsByClassName('treadmill_editor_audio')[0];
            if (audio !== undefined) {
                if (selectedItem.audioVolume !== undefined) {
                    audio.volume = selectedItem.audioVolume / 10;
                }
                if (selectedItem.audioPlaybackRate !== undefined) {
                    audio.playbackRate = selectedItem.audioPlaybackRate / 10;
                }
            }
        }
    }, [selectedItem])

    useEffect(() => {
        if (oldPlugData != undefined) {
            setItems(changeAllPlugs(items, oldPlugData));
            setOldPlugData(undefined);
        } 
    }, [selectedId])

    useEffect(() => {
        setMinedSlides(items.filter(x => x?.hasMine === true));
    }, [items])

    const createBlockBeforeInput = (items, id, type) => {
        let newItems = [];

        for (let item of items) {
            if (item.id == id) {
                let newBlock = {
                    id: `t-${+new Date()}-${Math.floor(Math.random() * 1000000)}`,
                    backgroundColor: '#ffffff',
                    textColor: '#ffffff',
                    textSize: '14',
                    textPosition: 'center',
                    imageSrc: type == 'mic' ? mic_gif : keyboard_svg,
                    from: +newItems[newItems.length - 1].to,
                    to: +newItems[newItems.length - 1].to + 2,
                    duration: 2,
                };
                newItems.push(newBlock);
            }
            newItems.push(item);
        }
        return fixWithDurations(newItems);
    }

    return (
        <Wrapper>

            <LeftPlaceholder>
                {minedSlides.length === 0 ? null :
                    <TopMinesPlaceholder>
                        {minedSlides.map((a, i) => {
                            return (
                                <TopMine onClick={() => {
                                        let mineSlide = document.getElementById(`${a.id}`);
                                        mineSlide.scrollIntoView({block: 'center', behavior: 'smooth'});
                                        setSelectedId(a.id);
                                        setMultipleSelect([a.id]);
                                    }}>
                                    {i + 1} мина
                                </TopMine>
                            )
                        })}
                    </TopMinesPlaceholder>
                }

                <LeftInnerPlaceholder mines={minedSlides}>
                    <ItemsPlaceholder>
                        {items.map((a, i) => {
                            let {from, to, videoSrc, audioSrc, imageSrc, text} = a;
                            let h = +to + -+from;
                            let noContent = (videoSrc == undefined && imageSrc == undefined && audioSrc == undefined && (text == undefined || text == ''));
                            let trimmedText = (text == undefined || text == '') ? '' : `${text}`.slice(0, 15);
                            return (
                                <Item
                                    id={`${a.id}`}
                                    key={`${a.id}-${i}`}
                                    emptyAnswer={(emptyAnswer.length > 0 && emptyAnswer.indexOf(a.id) !== -1)}
                                    selected={(multipleSelect !== undefined && multipleSelect.indexOf(a.id) !== -1)}
                                    style={{
                                        minHeight: a?.isPlug === true ? 32 : 103.5,
                                        backgroundColor: a?.videoSrc != undefined && a?.isPlug === false && a?.coverBackground == false ? 'black' : a.backgroundColor,
                                    }}
                                    onClick={(evt) => {
                                        if (evt.ctrlKey) {
                                            if (multipleSelect.indexOf(a.id) === -1) {
                                                for (let i = 0; i <= multipleSelect.length; i++) {
                                                    if (i === multipleSelect.length) {
                                                        let newSelected = multipleSelect.concat(a.id);
                                                        setMultipleSelect(newSelected);
                                                        break;
                                                    }
                                                    if (items.indexOf(a) < indexInItems(items, multipleSelect[i])) {
                                                        let newSelected = multipleSelect.slice(0, i).concat(a.id).concat(multipleSelect.slice(i));
                                                        setMultipleSelect(newSelected);
                                                        break;
                                                    }
                                                }
                                            } else {
                                                let newSelected = multipleSelect.slice(0, multipleSelect.indexOf(a.id));
                                                newSelected = newSelected.concat(multipleSelect.slice(multipleSelect.indexOf(a.id) + 1));
                                                setMultipleSelect(newSelected);
                                            }
                                        } else {
                                            if (emptyAnswer.indexOf(a.id) !== -1) {
                                                let index = emptyAnswer.indexOf(a.id);
                                                setEmptyAnswer((emptyAnswer.slice(0, index)).concat(emptyAnswer.slice(index + 1)));
                                            }
                                            setMultipleSelect([a.id]);
                                        }
                                        setSelectedId(a.id);
                                    }}>

                                    {videoSrc === undefined || a?.isPlug === true || a?.coverBackground ? null :
                                        <ItemVideo src={`${videoSrc + `#t=${+a.duration / 2}`}`} style={{maxHeight: '103.5px'}}/>
                                    }

                                    {a?.isPlug === true || text === undefined || text === '' ? null :
                                        <TextPlaceholder style={{
                                            minHeight: '103.5px',
                                            maxHeight: '103.5px',
                                            justifyContent: `${a.textPosition === 'top' ? 'flex-start' :
                                                a.textPosition === 'bottom' ? 'flex-end' : 'center'}`,
                                        }}>
                                            <Text style={{
                                                fontSize: `${a.textSize * 103.5 * (a.inputType === 'text' ? '0.8' : '1') / 100.0}px`,
                                                color: `${a.textColor}`,
                                                backgroundColor: a?.hasBackgroundColor ? a?.textBackgroundColor : 'transparent'
                                            }}
                                                  dangerouslySetInnerHTML={{__html: `${text}`}}></Text>
                                        </TextPlaceholder>
                                    }

                                    {imageSrc == undefined || imageSrc == '' || a?.coverBackground ? null :
                                        <ItemImage src={imageSrc}/>
                                    }

                                    <NumberPlaceholder>
                                        {`${+i + 1}) ${h.toFixed(0)}s (${from} - ${to})`}
                                    </NumberPlaceholder>

                                    {a.hasMine === true ?
                                        <MinePlaceholder>
                                            <img src={mine_svg} style={{transform: 'scale(0.7)'}}/>
                                        </MinePlaceholder> : null
                                    }

                                    <ArrowsPlaceholder>
                                        <SpanArrow onClick={() => {
                                            setItems(JSON.parse(JSON.stringify(getNewItemsAfterMovingUp(items, i, moveValue))));
                                            setSaved(false);
                                        }}>
                                            {'^'}
                                        </SpanArrow>
                                        <MoveValue value={moveValue} onChange={(evt) => {
                                            let value = evt.target.value;
                                            if (value > 0 || value === '') {
                                                setMoveValue(value);
                                            }
                                        }}></MoveValue>
                                        <SpanArrow onClick={() => {
                                            setItems(JSON.parse(JSON.stringify(getNewItemsAfterMovingDown(items, i, moveValue))));
                                            setSaved(false);
                                        }}>
                                            {'v'}
                                        </SpanArrow>
                                    </ArrowsPlaceholder>

                                    {a?.isPlug === true ? null :
                                        <AddSlide src={add_svg} title='Добавить блок' onClick={(evt) => {
                                            evt.stopPropagation();
                                            let previousItem = (items == undefined || items.length == 0) ? undefined : items[i];
                                            let newFrom = (items.length == 0) ? 0 : items[i].to;
                                            let newBlock = {
                                                id: `t-${+new Date()}-${Math.floor(Math.random() * 1000000)}`,
                                                from: newFrom,
                                                to: newFrom + ((previousItem == undefined) ? 4 : +previousItem.duration),
                                                duration: (previousItem == undefined) ? 4 : +previousItem.duration,
                                                textPosition: (previousItem == undefined) ? 'center' : previousItem.textPosition,
                                                textSize: (previousItem == undefined) ? 14 : previousItem.textSize,
                                                textColor: (previousItem == undefined) ? 'white' : previousItem.textColor,
                                                textBackgroundColor: (previousItem == undefined) ? 'black' : previousItem.textBackgroundColor,
                                                backgroundColor: (previousItem == undefined) ? 'black' : previousItem.backgroundColor,
                                                hasBackgroundColor: (previousItem == undefined) ? false : previousItem.hasBackgroundColor,
                                                hasCountDown: (previousItem == undefined) ? false : previousItem.hasCountDown,
                                                shouldRecord: false
                                            }
                                            let newItems;
                                            if (i === items.length - 1) {
                                                newItems = items.concat([newBlock]);
                                            } else {
                                                newItems = addNewItemAfterSlide(items, i, newBlock);
                                            }
                                            let dItems = JSON.parse(JSON.stringify(newItems));
                                            for (let i in dItems) {
                                                dItems[i].id = `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`;
                                            }
                                            setItems(dItems);
                                            setSaved(false);
                                            setSelectedId(dItems[i + 1].id);
                                            setMultipleSelect([dItems[i + 1].id]);
                                        }}/>
                                    }
                                </Item>
                            )
                        })}
                    </ItemsPlaceholder>

                </LeftInnerPlaceholder>

                <LeftBottomPlaceholder>
                    <ButtonPlaceholder title='Добавить блок' onClick={() => {
                        let itemsWithoutPlugs = items.filter(x => x?.isPlug != true);
                        let itemsLength = itemsWithoutPlugs.length;
                        let latestItem = (itemsWithoutPlugs == undefined || itemsLength == 0) ? undefined : itemsWithoutPlugs[itemsLength - 1];
                        if (selectedItem != undefined && selectedItem?.isPlug != true) {
                            latestItem = selectedItem;
                        }
                        let newFrom = (items.length == 0) ? 0 : items[items.length - 1].to;
                        let newBlock = {
                            id: `t-${+new Date()}-${Math.floor(Math.random() * 1000000)}`,
                            from: newFrom,
                            to: newFrom + ((latestItem == undefined) ? 4 : +latestItem.duration),
                            duration: (latestItem == undefined) ? 4 : +latestItem.duration,
                            textPosition: (latestItem == undefined) ? 'center' : latestItem.textPosition,
                            textSize: (latestItem == undefined) ? 14 : latestItem.textSize,
                            textColor: (latestItem == undefined) ? '#ffffff' : latestItem.textColor,
                            backgroundColor: (latestItem == undefined) ? '#000000' : latestItem.backgroundColor,
                            textBackgroundColor: (latestItem == undefined) ? '#000000' : latestItem.textBackgroundColor,
                            hasBackgroundColor: (latestItem == undefined) ? false : latestItem.hasBackgroundColor,
                            hasCountDown: (latestItem == undefined) ? false : latestItem.hasCountDown,
                            shouldRecord: false
                        }
                        let newItems = items.concat([newBlock]);
                        let dItems = JSON.parse(JSON.stringify(newItems));
                        for (let i in dItems) {
                            dItems[i].id = `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`;
                        }
                        setItems(dItems);
                        setSaved(false);
                        setSelectedId(dItems[dItems.length - 1].id);
                        setMultipleSelect([dItems[dItems.length - 1].id]);
                    }}>
                        <img src={new_slide}/>
                    </ButtonPlaceholder>

                    <ButtonPlaceholder title='Добавить заглушку' onClick={() => {
                        let plug = plugSettings(items);
                        let newFrom = (items.length == 0) ? 0 : items[items.length - 1].to;
                        let newBlock = {
                            id: `t-${+new Date()}-${Math.floor(Math.random() * 1000000)}`,
                            from: newFrom,
                            to: newFrom + ((plug === undefined) ? 1 : +plug.duration),
                            duration: (plug === undefined) ? 1 : +plug.duration,
                            text: (plug === undefined) ? '' : plug.text,
                            textPosition: (plug === undefined) ? 'center' : plug.textPosition,
                            textSize: (plug === undefined) ? 14 : plug.textSize,
                            textColor: (plug === undefined) ? '#ffffff' : plug.textColor,
                            backgroundColor: (plug === undefined) ? '#000000' : plug.backgroundColor,
                            copyrightDescription: (plug === undefined) ? '' : plug.copyrightDescription,
                            imageSrc: (plug === undefined) ? undefined : plug.imageSrc,
                            hasCountDown: (plug === undefined) ? false : plug.hasCountDown,
                            isPlug: true,
                            hasBackgroundColor: false,
                            shouldRecord: false
                        }
                        let newItems = items, index = [];
                        for (let item of items) {
                            if (multipleSelect.indexOf(item.id) !== -1) {
                                index = index.concat(items.indexOf(item));
                            }
                        }
                        let count = 0;
                        let newIndex;
                        if (multipleSelect === undefined || multipleSelect.length === 0) {
                            index = [items.length - 1];
                        }
                        for (let i of index) {
                            newItems = addNewItemAfterSlide(newItems, i + count, newBlock);
                            count += 1;
                            newIndex = i + count;
                        }
                        let dItems = JSON.parse(JSON.stringify(newItems));
                        for (let i in dItems) {
                            dItems[i].id = `t-${+new Date()}-${Math.round(Math.random() * 1000000)}`;
                        }
                        setItems(dItems);
                        setSaved(false);
                        setSelectedId(dItems[newIndex].id);
                        setMultipleSelect([dItems[newIndex].id]);
                    }}>
                        <img src={plug_svg}/>
                    </ButtonPlaceholder>

                    <ButtonPlaceholder title='Дублировать все' onClick={() => {
                        if (window.confirm('Уверены?') == false) {
                            return;
                        }
                        let newItems = JSON.parse(JSON.stringify(items));
                        let tempItems = JSON.parse(JSON.stringify(items));
                        for (let i in tempItems) {
                            tempItems[i] = {...tempItems[i], hasMine: false};
                        }
                        newItems = newItems.concat(tempItems);
                        let tt = +new Date();
                        for (let i in newItems) {
                            let newId = `tt-${tt}-${newItems[i].id}-${i}-${Math.floor(Math.random() * 10000000)}`;
                            newItems[i] = {...newItems[i], id: newId}
                        }
                        newItems = fixWithDurations(newItems);
                        setItems(newItems);
                        setSaved(false);
                        setSelectedId(undefined);
                    }}>
                        <img style={{transform: 'scale(0.8)'}} src={duplicate_all}/>
                    </ButtonPlaceholder>

                    <ButtonPlaceholder title='Дублировать выделенные' onClick={() => {
                        if (multipleSelect[0] === undefined) {
                            toast('Выделите хотя бы 1 слайд')
                        } else {
                            setDuplicateModal(true)
                        }
                    }}>
                        <img style={{transform: 'scale(0.8)'}} src={duplicate_selected}/>
                    </ButtonPlaceholder>
                    <ButtonPlaceholder title="Посмотреть в превью" onClick={() => {
                        openPreview(selectedItem == undefined ? 0 : selectedItem.from);
                    }}>
                        <img src={preview}/>
                    </ButtonPlaceholder>
                </LeftBottomPlaceholder>
            </LeftPlaceholder>

            <CenterPlaceholder className={'editor_center_placeholder'} key={`${selectedId}+center`}>
                <CenterInnerPlaceholder>
                    {selectedItem == undefined ? null :
                        <React.Fragment>
                            <ContentPlaceholder>
                                <SlidePlaceholder style={{height: selectedItem.inputType === 'text' ? `calc(100% - 120px)` : '100%'}}>
                                    {/*<SlidePlaceholder style={{width: '720px', height: '448px'}}>*/}
                                    {selectedItem.videoSrc == undefined || selectedItem?.isPlug === true ?
                                        <React.Fragment>
                                            <TextSlide
                                                {...selectedItem}
                                                id={selectedItem?.id}
                                                timeBeforeSlideEnd={selectedItem.duration}
                                                isPreview={true}
                                                changeSize={(s) => {
                                                    if (s > 0) {
                                                        setNewSize(s);
                                                        setIsNewSize(true);
                                                    }
                                                }}
                                            />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <VideoSlide
                                                id={selectedItem?.id}
                                                timeBeforeSlideEnd={selectedItem.duration}
                                                isPreview={true}
                                                {...selectedItem}
                                            />
                                        </React.Fragment>
                                    }
                                </SlidePlaceholder>
                                {selectedItem.inputType !== 'text' ? null :
                                    <TextAnswerPlaceholder>
                                        <UserTextInputTool isPreview={true}/>
                                        {selectedItem.hasCountDown === false ? null :
                                            <CountDownPlaceholder>
                                                <RoundCountDown timeLeft={selectedItem.duration}
                                                                timeTotal={selectedItem.duration}/>
                                            </CountDownPlaceholder>
                                        }
                                    </TextAnswerPlaceholder>
                                }
                            </ContentPlaceholder>
                            {selectedItem.copyrightDescription === undefined || selectedItem.copyrightDescription.trim() === '' ? null :
                                <CopyrightPlaceholder>
                                    <CopyrightSpan onClick={() => {
                                        setCopyrightText(selectedItem.copyrightDescription);
                                    }}>
                                        источник
                                    </CopyrightSpan>
                                </CopyrightPlaceholder>
                            }
                            {selectedItem.audioSrc === undefined || selectedItem.audioSrc === '' ? null :
                                <AudioPlaceholder>
                                    <audio key={selectedItem?.id} className={'treadmill_editor_audio'} src={selectedItem?.audioSrc} controls={true}/>
                                </AudioPlaceholder>
                            }
                            <BottomControlsRow style={{marginTop: 10}}>
                                <span>
                                    {`Продолжительность: ${(+selectedItem.to - +selectedItem.from).toFixed()} сек.`}
                                </span>
                                <span
                                    style={{cursor: 'pointer', color: 'red'}}
                                    onClick={() => {
                                        if (selectedItem?.hasMine === true) {
                                            setMinedSlides(RemoveMineSlide(items, minedSlides, selectedItem.id));
                                        }
                                        let items_ = fixWithDurations(items.filter(xx => (xx.id != selectedItem.id)));
                                        setItems(items_);
                                        setSelectedId(undefined);
                                    }}>
                                    удалить
                                </span>
                            </BottomControlsRow>
                        </React.Fragment>
                    }

                </CenterInnerPlaceholder>
            </CenterPlaceholder>

            <RightPlaceholder key={`${selectedId}+right`}>
                <div>
                    {selectedItem == undefined ? null :

                        <div>
                            <ItemForm {...selectedItem}
                                      removeBlocksAfter={props?.removeBlocksAfter}
                                      newSize={newSize}
                                      isNewSize={isNewSize}
                                      newSizeSet={() => {
                                          setNewSize(0);
                                          setIsNewSize(false);
                                      }}
                                      createPlugBeforeInput={(id, type) => {
                                          let newItems = createBlockBeforeInput(items, id, type);
                                          setItems(newItems);
                                      }}
                                      setOldPlugData={(data) => {
                                          setOldPlugData(data);    
                                      }}
                                      loading={loading}
                                      currentUserId={currentUserId}
                                      userScale={userScale}
                                      removeMine={(id) => {
                                          setMinedSlides(RemoveMineSlide(items, minedSlides, id));
                                      }}
                                      addMine={(id) => {
                                          if (minedSlides.length === 2) {
                                              toast.error('Число мин в тредмиле не может превышать 2');
                                              return false;
                                          } else {
                                              setMinedSlides(AddMineSlide(items, minedSlides, id));
                                              return true;
                                          }
                                      }}
                                      onPlugChange={(plug) => {
                                          let oldItemsNewPlugs = changeAllPlugs(oldItems, plug);
                                          setItems(changeAllPlugs(items, plug));
                                          onSave(oldItemsNewPlugs, false);
                                      }}
                                      onSave={d => {
                                          // console.log('on save d = ', d);
                                          let dd = {
                                              id: selectedItem.id,
                                              from: selectedItem.from,
                                              to: selectedItem.to, ...d
                                          };
                                          let newItems = items.map(x => (x.id == selectedItem.id ? dd : x));
                                          setItems(fixWithDurations(newItems));
                                          setSaved(false);
                                      }}/>
                        </div>

                    }
                </div>
                {selectedItem?.isPlug == true ? null :
                    <div style={{ marginTop: 10 }}>
                        <SaveButton saved={saved} onClick={() => {
                            let emptyMine = false;
                            let emptyText = false;
                            let countMines = 0;
                            let empty = [];
                            for (let item of items) {
                                if (item?.hasMine === true) {
                                    countMines++;
                                }
                                if (item?.hasMine === true && item.mineAnswer !== undefined && item?.mineAnswer.trim() === '') {
                                    emptyMine = true;
                                    empty = empty.concat(item.id);
                                }
                                if (item?.inputType === 'text' && item.correctAnswer !== undefined && item?.correctAnswer.trim() === '') {
                                    console.log('text translate: ', item.textTranslate)
                                    if (item.textTranslate == undefined || item.textTranslate != true) {
                                        emptyText = true;
                                        empty = empty.concat(item.id);
                                    }
                                }
                            }
                            setEmptyAnswer(empty);
                            console.log('mines: ', countMines);
                            if (countMines !== 0 && countMines !== 2) {
                                toast.error('Мин в тредмиле должно быть 0 или 2');
                            } else if (emptyMine && !emptyText) {
                                toast.error('Введите правильный ответ для мины');
                            } else if (!emptyMine && emptyText) {
                                toast.error('Введите правильный ответ для текстового ответа');
                            } else if (emptyMine && emptyText) {
                                toast.error('Введите правильный ответ для мины и текстового ответа');
                            } else {
                                onSave(items, saved);
                                setSaved(true);
                            }
                        }}>
                            Сохранить
                        </SaveButton>
                    </div>
                }
            </RightPlaceholder>

            {copyrightText == undefined ? null :
                <NiceModal onClose={() => {
                    setCopyrightText(undefined);
                }}>
                    <CpInnerPlaceholder>
                        {copyrightText}
                    </CpInnerPlaceholder>
                </NiceModal>
            }

            {duplicateModal === false ? null :
                <NiceModal onClose={() => {
                    setDuplicateModal(false);
                }}>
                    <DuplicateModal>
                        <DuplicateModalItem onClick={(evt) => {
                            evt.stopPropagation();
                            let newItems = duplicateSlides(items, multipleSelect, false);
                            setItems(newItems);
                            setSaved(false);
                            setSelectedId(undefined);
                            setMultipleSelect([]);
                            setDuplicateModal(false);
                        }}>Дублировать после выделенных блоков</DuplicateModalItem>

                        <DuplicateModalItem onClick={(evt) => {
                            evt.stopPropagation();
                            let newItems = duplicateSlides(items, multipleSelect, true);
                            setItems(newItems);
                            setSaved(false);
                            setSelectedId(undefined);
                            setMultipleSelect([]);
                            setDuplicateModal(false);
                        }}>Дублировать в конец</DuplicateModalItem>

                        <DuplicateModalItem style={{color: 'red'}} onClick={() => setDuplicateModal(false)}>Отмена</DuplicateModalItem>
                    </DuplicateModal>
                </NiceModal>
            }

        </Wrapper>
    );
}

const BottomControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SaveButton = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.saved === true ? 'default' : 'pointer')};
  width: 100%;
  background: ${props => (props.saved === true ? 'grey' : 'blue')};
  border-radius: 4px;
  color: white;
  box-sizing: border-box;

  :hover {
    opacity: ${props => (props.saved === true ? '1' : '0.75')};
  }
`;


const SpanArrow = styled.span`
  color: black;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  cursor: pointer;
  box-sizing: border-box;
  background: white;
  height: 20px;
  width: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MoveValue = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  color: #838383;

  :hover, :focus, :active {
    outline: none;
  }
`;

const NumberPlaceholder = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  background: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  padding: 2px;
  border-radius: 4px;
  z-index: 4;
`;

const SlidePlaceholder = styled.div`
  flex: 4 0 80%;
`;

const TextAnswerPlaceholder = styled.div`
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  justify-self: flex-end;
  max-height: 20%;
  flex: 0 1 20%;
  display: flex;
`;

const CountDownPlaceholder = styled.div`
  aspect-ratio: 1 / 1;
  padding: 2px;
`;

const ContentPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 16/9;
  flex: 1;
  overflow: hidden;
`;

const AddPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

const DuplicatePlaceholder = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 10px;
`;

const AddPlug = styled.div`
  text-align: center;
`;

const Span = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

const ItemsPlaceholder = styled.div`

`;

const ItemTime = styled.div`
  background: white;
  color: black;
  padding: 2px;
  border-radius: 8px;
  margin: 5px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  z-index: 10;
`;

const Item = styled.div`
  width: calc(100% - 3px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  outline-offset: 2px;
  outline: ${props => (props.selected === true ? '2px solid #FFA71B' : props.emptyAnswer === true ? '2px solid red' : 'none')};
  color: white;
  margin: 3px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${props => (props.imageSrc == undefined ? 'none' : `url(${props.imageSrc})`)};
  z-index: 10;

    //cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  cursor: pointer;

  :hover {
    opacity: ${props => (props.selected == true ? 1 : 0.75)};
  }

  //:after {
  //  content: '\\A';
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  background: rgba(0, 0, 0, 0.5);
  //  opacity: 1;
  //  transition: all 0.5s;
  //  -webkit-transition: all 0.5s;
  //  -moz-transition: all 0.5s;
  //}

`;

const ArrowsPlaceholder = styled.div`
  position: absolute;
  bottom: 6px;
  right: 4px;
  z-index: 5;
`;


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid lightgrey;
  
`;

const LeftPlaceholder = styled.div`
  height: 100%;
  width: 200px;
`;

const LeftInnerPlaceholder = styled.div`
  //width: calc(100% - 5px);
  box-sizing: border-box;
  padding: 5px;
  border-right: 1px solid lightgrey;
  height: ${props => props.mines.length === 0 ? 'calc(100% - 50px)' : 'calc(100% - 85px)'};
  max-height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 4px;
    background-color: #c5c5c5;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #eeeeee;
    width: 8px;
  }

`;

const LeftBottomPlaceholder = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-right: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
`;

const ButtonPlaceholder = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid black;
  cursor: pointer;
  opacity: 1;

  :hover {
    opacity: 0.6;
  }
`;

const CenterPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 5px;
  width: 730px;
  height: 100%;
  display: block;
`;

const CenterInnerPlaceholder = styled.div`
  height: 100%;
  width: 100%;
`;

const RightPlaceholder = styled.div`
  width: 300px - 15px;
  flex: 1;
  box-sizing: border-box;
  padding: 5px;
  border-left: 1px solid lightgrey;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  ::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 4px;
    background-color: #c5c5c5;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #eeeeee;
    width: 8px;
  }
`;

const AddSlide = styled.img`
  position: absolute;
  top: 93.5px;
  height: 20px;
  cursor: pointer;
  z-index: 3;
`;

const CpInnerPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 420px;
`;

const CopyrightPlaceholder = styled.div`
  text-align: right;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CopyrightSpan = styled.span`
  font-size: 9px;
  opacity: 0.4;
  font-style: italic;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const DuplicateModal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
  font-size: 16px;
  text-align: center;
  padding: 5px;
`;

const DuplicateModalItem = styled.div`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  cursor: pointer;

  :hover {
    border: 1px solid grey;
  }
`;

const MinePlaceholder = styled.div`
  position: absolute;
  bottom: 6px;
  left: 4px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  border: 1px solid lightgrey;
  width: 24px;
  height: 24px;
  z-index: 100;
`;

const ItemVideo = styled.video`
  max-height: 103.5px;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 6px;
  position: absolute;
  z-index: 0;
`;

const ItemImage = styled.img`
  width: 100%;
  max-height: 103.5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
`;

const Text = styled.div`
  text-align: center;
  line-height: 120%;
  user-select: none;
  
  p {
    margin-bottom: 2px;
  }
`;

const TextPlaceholder = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 3;
  pointer-events: none;
`;

const AudioPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

const TopMinesPlaceholder = styled.div`
  width: 100%;
  height: 36px;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const TopMine = styled.div`
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 2px 10px;
  cursor: pointer;
`;

const TopMineIcon = styled.img`
  width: 30px;
  height: 30px;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  cursor: pointer;
`;