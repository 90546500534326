import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import * as postsActions from "../../../redux/actions/PostsActions";
import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import SimpleImageUploader from "../../uploader/tools/SimpleImageUploader";
import Sidebar from "arui-feather/sidebar";
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";

export default function AllPostsPanel(props) {
    const dispatch = useDispatch();

    const [newText, setNewText] = useState('');
    const [newName, setNewName] = useState('');
    const [url, setUrl] = useState();
    const [videoUrl, setVideoUrl] = useState();

    const [videoModalVisible, setVideoModalVisible] = useState(false);

    const {
        currentUser,
        posts = [],
        isAdmin,
        loading
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);

        let isAdmin = (currentUser?.isModerator == true);

        let posts = state.posts.postsMap.toArray();
        posts = posts.sort((a, b) => (+b.timestamp - +a.timestamp));

        return {
            currentUser: currentUser,
            posts: posts,
            loading: state.posts.loading,
            isAdmin: isAdmin
        }
    }, []));

    useEffect(() => {
        dispatch(postsActions.loadPosts()).then(d => {

        });
    }, []);

    console.log('posts', posts);
    console.log('currentUser', currentUser);

    return (
        <Wrapper>

            {posts.map((a, i) => {
                return (
                    <PostItem key={a.id}>
                        <PostName>
                            <XName>
                                {a.name}
                            </XName>
                            <span style={{fontSize: '12px', opacity: 0.5}}>
                                {moment(a.timestamp).format('DD.MM.YYYY HH:mm')}
                            </span>
                        </PostName>
                        <PostContent>
                            {a.url == undefined || a.url == '' ? null :
                                <div style={{paddingTop: 10, paddingBottom: 10, height: 380}} >
                                    <ImagePlaceholder src={a.url} ></ImagePlaceholder>
                                </div>
                            }
                            {a.videoUrl == undefined || a.videoUrl == '' ? null :
                                <div>
                                    <video style={{height: '380px', width: '100%'}} controls={true} >
                                        <source src={a.videoUrl} />
                                    </video>
                                </div>
                            }

                            <div dangerouslySetInnerHTML={{__html: `${a.text}`.replace(/\n/g, '<br/>')}}></div>
                        </PostContent>

                        {isAdmin == false ? null :
                            <PostBottomPlaceholder>
                                <DelSpan onClick={async () => {
                                    if (loading == true) {
                                        return;
                                    }
                                    await dispatch(postsActions.deletePost(a.id));
                                }}>
                                    удалить
                                </DelSpan>
                            </PostBottomPlaceholder>
                        }

                    </PostItem>
                )
            })}

            {isAdmin == false ? null :
                <BottomPlaceholder>

                    <Row>
                        <Label>
                            Название поста
                        </Label>
                        <Input
                            value={newName}
                            onChange={evt => {
                                setNewName(evt.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Содержание поста
                        </Label>
                        <Textarea
                            value={newText}
                            onChange={evt => {
                                setNewText(evt.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Картинка
                        </Label>

                        {url == undefined ? null :
                            <div>
                                <div>
                                    <img src={url} style={{height: 120}}/>
                                </div>
                                <div>
                                    <span style={{cursor: 'pointer', color: 'red', fontStyle: 'italic'}}
                                          onClick={() => {
                                              setUrl(undefined);
                                          }}>
                                        удалить
                                    </span>
                                </div>
                            </div>
                        }

                        <SimpleImageUploader
                            onSave={x => {
                                setUrl(x);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>
                            Видео
                        </Label>

                        {videoUrl == undefined ? null :
                            <div>
                                <div>
                                    <video style={{height: '380px', width: '100%'}} controls={true} >
                                        <source src={videoUrl} />
                                    </video>
                                </div>
                                <div>
                                    <span style={{cursor: 'pointer', color: 'red', fontStyle: 'italic'}}
                                          onClick={() => {
                                              setVideoUrl(undefined);
                                          }}>
                                        удалить
                                    </span>
                                </div>
                            </div>
                        }

                        <div style={{cursor: 'pointer'}} onClick={() => {
                            setVideoModalVisible(true);
                        }} >
                            выбрать видео
                        </div>

                    </Row>


                    <Row>
                        <BlueButton onClick={async () => {
                            if (loading == true) {
                                return;
                            }
                            await dispatch(postsActions.createPost({
                                text: newText,
                                name: newName,
                                url: url,
                                videoUrl: videoUrl
                            }));
                            setNewText('');
                            setNewName('');
                            setUrl(undefined);
                            setVideoUrl(undefined);
                        }}>
                            {loading == true ? 'Создание...' : 'Создать пост'}
                        </BlueButton>
                    </Row>


                </BottomPlaceholder>
            }

            <Sidebar visible={videoModalVisible} onCloserClick={() => {
                setVideoModalVisible(false);
            }} >
                {videoModalVisible == false ? null :
                    <div style={{height: 'calc(90vh - 200px)', maxHeight: 'calc(90vh - 200px)', overflowY: 'hidden'}} >
                        <SearchOrCreateMaterialsPanel
                            onSelect={x => {
                                console.log('select x = ', x);
                                setVideoUrl(x?.url);
                                setVideoModalVisible(false);
                            }}
                        />
                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const ImagePlaceholder = styled.div`
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
`;

const XName = styled.div`
  font-weight: bold;
`;

const DelSpan = styled.span`
  cursor: pointer;
  color: red;

  :hover {
    opacity: 0.7;
  }
`;

const BottomPlaceholder = styled.div`

`;

const PostBottomPlaceholder = styled.div`
  text-align: right;
`;

const Row = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const PostName = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PostContent = styled.div`

`;

const PostItem = styled.div`
  border-radius: 10px;
  padding: 15px;
  background: white;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  padding: 20px;
  width: 720px;
  margin: 0 auto;
`;