import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import {Map, Set} from 'immutable'

import * as exercisesActions from '../../../redux/actions/ExercisesActions'
import NiceModal from "../../modals/NiceModal";
import Tabs, {TabItem} from "../../ui/Tabs";
import {Code} from "react-content-loader";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import ExercisePanel from "./ExercisePanel";
import DrochkaInfoForm from "../../drochka_exercise/forms/DrochkaInfoForm";
import DrochkaItemsForm from "../../drochka_exercise/forms/DrochkaItemsForm";

import ava from '../../../assets/img/online-learning-using-tablet.png'
import ExerciseInfoForm from "../forms/ExerciseInfoForm";
import ExerciseCardsEditorPanel from "./ExerciseCardsEditorPanel";
import CommonHelper from "../../../helpers/CommonHelper";
import share_img from "../../../assets/img/share_mini.svg";
import {translate} from "../../../utils/Translate";
import {Input} from "../../ira/ui/Inputs";

import tick_img from '../../../assets/img/green_check.svg'
import toast from "react-hot-toast";
import UIHelper from "../../../helpers/UIHelper";
import {getFixedUrl} from "../tools/ExerciseCardItemView";
import PrivateContainerFolderSelectorPanel from "../../containers/panels/PrivateContainerFolderSelectorPanel";
import SlidesAPI from "../../../api/SlidesAPI";
import PrivatePatientFolderSelectorPanel from "../../folders/panels/PrivatePatientFolderSelectorPanel";
import axios from "axios";
import {API_ENDPOINT} from "../../../constants/config";

export const hasIntersection = (arr1 = [], arr2 = []) => {
    let f = false;
    for (let i in arr1) {
        let a = arr1[i];
        if (arr2.indexOf(a) > -1) {
            f = true;
        }
    }
    return f;
}

export default function AllExercisesPanel(props) {
    const {
        mode = 'my',
        pageSize = 100,
        onCreated = x => {

        },
        selectMode = false,
        onSelect = x => {

        },
        hasSearch = true,
        selectedSet = Set(),
        creatorId
    } = props;

    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(props.selectedId);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [localLoading, setLocalLoading] = useState(false);

    const [cloningName, setCloningName] = useState('');

    const [selectedMoveExerciseId, setSelectedMoveExerciseId] = useState(undefined);
    const [selectedCloneExerciseId, setSelectedCloneExerciseId] = useState(undefined);

    console.log('AllExercisesPanel: render: selectedSet = ', selectedSet);
    console.log('AllExercisesPanel: render: props.exerciseId = ', props.exerciseId);

    const [cloning, setCloning] = useState(false);

    const {
        currentUser,
        exercises = [],
        loading,
        accessGroups = [],
        isModerator
    } = useMappedState(useCallback(state => {
        let {currentUserId} = state.users;
        let currentUser = state.users.usersMap.get(currentUserId);
        let allExercises = state.exercises.exercisesMap.toArray();
        let aGroups = state.accessGroups.accessGroupsMap.toArray().filter(x => ((x?.emails || []).indexOf(currentUser.email) == -1 && x?.creatorId != currentUserId));
        let aGroupsIds = aGroups.map(xx => xx?.id).filter(x => (x != undefined));
        let exercises = (mode == 'my') ? allExercises.filter(x => (x.creatorId == currentUserId)) : allExercises;
        if (mode == 'shared') {
            exercises = exercises.filter(x => hasIntersection((x?.accessGroupsIds || []), aGroupsIds));
        }
        if (mode == 'public') {
            exercises = exercises.filter(x => (x?.access == 'public'));
            // exercises = exercises;
        }
        exercises = exercises.sort((a, b) => (+b.timestamp - +a.timestamp));
        if (creatorId != undefined) {
            exercises = exercises.filter(x => (x?.creatorId == creatorId));
        }
        return {
            currentUser: currentUser,
            exercises: exercises,
            loading: state.exercises.loading,
            accessGroups: aGroups,
            isModerator: currentUser != undefined && currentUser.isModerator,
        }
    }, [selectedId]));

    useEffect(() => {
        dispatch(exercisesActions.getAllExercises());
    }, []);

    let selectedExercise = (selectedId == undefined) ? undefined : exercises.filter(x => (x.id == selectedId))[0];
    let filteredExercises = exercises.filter(x => ((searchText.length < 3) || (`${x?.name}`.toLowerCase().indexOf(searchText.toLowerCase()) > -1)));
    let visExercises = filteredExercises.slice(0, (+page + 1) * pageSize);
    let reallyHasSearch = (hasSearch && exercises.length > 2);

    console.log('render: accessGroups = ', accessGroups);

    let selectedMoveExercise = (selectedMoveExerciseId == undefined) ? undefined : exercises.find(x => (x.id == selectedMoveExerciseId));
    let selectedCloneExercise = (selectedCloneExerciseId == undefined) ? undefined : exercises.find(x => (x.id == selectedCloneExerciseId));

    console.log('render: mode, exercises = ', mode, exercises);

    return (
        <Wrapper>

            {!(exercises.length == 0 && loading == true) ? null :
                <Code/>
            }


            <TopPlacehodler>
                {mode != 'my' ? null :
                    <GreenButton onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        {translate('create_exercise')}
                    </GreenButton>
                }
                {reallyHasSearch == false ? null :
                    <TopSearchPlaceholder>
                        <Input
                            placeholder={'Search'}
                            value={searchText}
                            onChange={evt => {
                                setSearchText(evt.target.value);
                                setPage(0);
                            }}
                        />
                    </TopSearchPlaceholder>
                }
            </TopPlacehodler>

            <ItemsPlaceholder>
                {visExercises.map((a, i) => {
                    let isSelected = selectedSet.has(a.id);
                    return (
                        <ExItem key={`ex_${a.id}_${i}`} onClick={() => {

                        }}>
                            <AvatarImage src={getFixedUrl(a.avatar || a.imageUrl || ava)} onClick={() => {
                                setSelectedId(a.id);
                            }}/>
                            <Name onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                {a.name}
                            </Name>
                            {UIHelper.isProUniMode() == true ? null :
                                <Description onClick={() => {
                                    setSelectedId(a.id);
                                }}>
                                    <div dangerouslySetInnerHTML={{__html: a.description}}></div>
                                </Description>
                            }

                            {selectMode == false ? null :
                                <div style={{
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {isSelected == false ? null :
                                        <img src={tick_img} style={{height: 18, marginRight: 5}}/>
                                    }
                                    {isSelected == true ? null :
                                        <SelectSpan onClick={() => {
                                            onSelect(a);
                                        }}>
                                            {translate('select')}
                                        </SelectSpan>
                                    }
                                </div>
                            }
                        </ExItem>
                    )
                })}
            </ItemsPlaceholder>

            {loading == true || filteredExercises.length < (pageSize * (+page + 1)) ? null :
                <BottomPlaceholder>
                    <BlueButton onClick={() => {
                        setPage(+page + 1);
                    }}>
                        Загрузить еще
                    </BlueButton>
                </BottomPlaceholder>
            }

            {selectedExercise == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <InnerPlaceholder>
                        <Tabs
                            tabs={[
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Информация',
                                        content: (
                                            <TabItem>
                                                <ExerciseInfoForm
                                                    {...selectedExercise}
                                                    loading={loading}
                                                    onSave={async d => {
                                                        await dispatch(exercisesActions.updateExercise({
                                                            ...d,
                                                            id: selectedExercise?.id
                                                        }));
                                                        toast('Изменения сохранены');
                                                    }}
                                                />
                                            </TabItem>
                                        )
                                    },
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Карточки',
                                        content: (
                                            <TabItem>
                                                <ExerciseCardsEditorPanel
                                                    exerciseId={selectedExercise?.id}
                                                />
                                                {/*<DrochkaItemsForm*/}
                                                {/*    loading={loading}*/}
                                                {/*    {...selectedExercise}*/}
                                                {/*    onSave={async arr => {*/}
                                                {/*        await dispatch(exercisesActions.updateExercise({*/}
                                                {/*            items: arr,*/}
                                                {/*            id: selectedExercise?.id*/}
                                                {/*        }))*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                            </TabItem>
                                        )
                                    },
                                {
                                    label: 'Превью',
                                    content: (
                                        <TabItem>
                                            <ExPreviewPlaceholder>
                                                <ExercisePanel
                                                    id={selectedExercise?.id}
                                                />
                                                
                                                <ShareExecisePlaceholder>
                                                    <ShareLinkSpan onClick={() => {
                                                        CommonHelper.linkTo(`/share/exercises/${selectedExercise?.id}`, true);
                                                    }}>
                                                        <ShareImg src={share_img}/>
                                                        {translate('share_exercise')}
                                                    </ShareLinkSpan>
                                                </ShareExecisePlaceholder>
                                            </ExPreviewPlaceholder>

                                        </TabItem>
                                    )
                                },
                                (mode != 'my' && window.location.href.indexOf('petr') == -1 && isModerator == false) ? undefined :
                                    {
                                        label: 'Дополнительные настройки',
                                        content: (
                                            <TabItem>
                                                <ExPreviewPlaceholder>
                                                <span style={{cursor: 'pointer'}} onClick={() => {
                                                    setSelectedMoveExerciseId(selectedExercise?.id);
                                                }}>
                                                    Перенести в другую папку
                                                </span>
                                                    <br/>
                                                    <span style={{cursor: 'pointer'}} onClick={async () => {
                                                        if (cloning == true) {
                                                            return;
                                                        }
                                                        setCloning(true);
                                                        await axios.post(`${API_ENDPOINT}/data/exercise/${selectedExercise?.id}/clone/${currentUser?.id}`)
                                                        // setSelectedCloneExerciseId(selectedExercise?.id);
                                                        setCloning(false);
                                                        toast.success('Упражнение склонировано!');
                                                    }}>
                                                        {cloning == true ? 'Клонирование...' : 'Клонировать упражнение'}
                                                </span>
                                                </ExPreviewPlaceholder>

                                            </TabItem>
                                        )
                                    }


                            ].filter(x => (x != undefined))}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <InnerPlaceholder>
                        <ExerciseInfoForm
                            loading={loading}
                            onSave={async d => {
                                let pld = await dispatch(exercisesActions.createExercise({
                                    ...d
                                }));
                                setAddModalVisible(false);
                                setSelectedId(pld?.exercise?.id);
                                onCreated(pld?.exercise);
                            }}
                        />
                    </InnerPlaceholder>

                </NiceModal>
            }

            {selectedMoveExercise == undefined ? null :
                <NiceModal onClose={() => {
                    console.log('-->> onClose');
                    setSelectedMoveExerciseId(undefined);
                }}>
                    <FolderSelectorInnerPlaceholder>

                        <InnerHeading>
                            Укажите название и выберите в какую папку вы хотите сохранить упражнение.
                        </InnerHeading>

                        <PrivatePatientFolderSelectorPanel
                            onFolderSelect={async cId => {
                                if (localLoading == true) {
                                    return;
                                }
                                setLocalLoading(true);
                                // await SlidesAPI.createSlideFromTaskAndAddItToContainer(selectedCloneTask?.id, cId, cloningName);
                                // onTaskCopiedToModule();
                                setLocalLoading(false);
                                toast('Сохранено');
                                // setCloneTaskId(undefined);

                            }}
                        />

                    </FolderSelectorInnerPlaceholder>

                </NiceModal>
            }

            {/*{selectedCloneExercise == undefined ? null :*/}
            {/*    <NiceModal onClose={() => {*/}
            {/*        setSelectedCloneExerciseId(undefined);*/}
            {/*    }}>*/}
            {/*        <FolderSelectorInnerPlaceholder>*/}

            {/*            <InnerHeading>*/}
            {/*                Укажите название клонируемого упражнения.*/}
            {/*            </InnerHeading>*/}

            {/*            <Input placeholer={'Название клонируемого упражнения'} value={cloningName}*/}
            {/*                   onChange={evt => {*/}
            {/*                       setCloningName(evt.target.value);*/}
            {/*                   }}/>*/}


            {/*        </FolderSelectorInnerPlaceholder>*/}

            {/*    </NiceModal>*/}
            {/*}*/}


        </Wrapper>
    );
}

const CloningNamePlaceholder = styled.div`
  padding: 2px;
  box-sizing: border-box;
  margin-top: 5px;
`;

const InnerHeading = styled.div`
  @media (max-width: 720px) {
    margin-top: 30px;
    margin-left: 10px;
  }
`;

const FolderSelectorInnerPlaceholder = styled.div`
  width: 420px;
  max-height: calc(80vh - 20px);
  overflow-y: auto;
`;

const TopSearchPlaceholder = styled.div`
  width: 240px;
`;

const SelectSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const ShareLinkSpan = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ShareImg = styled.img`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const ShareExecisePlaceholder = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
`;


const ExPreviewPlaceholder = styled.div`
  width: 720px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`

`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const TopPlacehodler = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InnerPlaceholder = styled.div`
  width: 1080px;
  box-sizing: border-box;
  padding: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  @media (max-width: 1200px) {
    width: calc(100vw - 60px);
  }

  @media (max-width: 720px) {
    width: 75vw;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const Description = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const Name = styled.div`
  font-weight: bold;
`;

const AvatarImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: whitesmoke;
  height: 240px;
  width: 100%;
  margin-bottom: 5px;
  @media (max-width: 1100px) {
    height: 180px;
  }
`;

const ExItem = styled.div`
  box-sizing: border-box;
  margin: 15px;
  background: white;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.75;

  background: ${props => (props.selected == true ? 'blue' : 'white')};

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const ItemsPlaceholder = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;