import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import moment from 'moment'
import ls from 'local-storage'

import woman_waiting_json from '../../../assets/img/woman_waiting.json'
import dots from '../../../assets/img/dots_circle.svg'
import warning from '../../../assets/img/warning.svg'

import * as usersActions from '../../../redux/actions/UsersActions'
import * as drochkaExercisesActions from '../../../redux/actions/DrochkaExercisesActions'
import * as exercisesActions from '../../../redux/actions/ExercisesActions'
import * as testsActions from '../../../redux/actions/TestsActions'
import * as treadmillsActions from '../../../redux/actions/TreadmillsActions'
import * as groupsActions from '../../../redux/actions/GroupsActions'
import * as lessonsActions from '../../../redux/actions/LessonsActions'
import * as tasksActions from '../../../redux/actions/TasksActions'
import GroupStatsAPI from "../../../api/GroupStatsAPI";
import downChevron from "../../../assets/img/downArrow.svg";
import NiceModal from "../../modals/NiceModal";
import ExercisePanel from "../../exercises/panels/ExercisePanel";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import SimpleUserViewTool from "../tools/SimpleUserViewTool";
import TasksHelper from "../../../helpers/TasksHelper";
import LottieLoader from "../../loaders/LottieLoader";
import {translate} from "../../../utils/Translate";
import sound_img from "../../../assets/img/sound.svg";
import CommonHelper from "../../../helpers/CommonHelper";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import TeacherGroupStudentPanel from "../../groups/panels/TeacherGroupStudentPanel";
import StudentGroupLessonsPanel from "../../lessons/panels/StudentGroupLessonsPanel";
import * as actions from "../../../redux/actions/LessonsActions";
import LessonsList from "../../lessons/tools/LessonsList";
import GroupDiaryPanel from "../../diary/panels/GroupDiaryPanel";
import UpdateMarkForm from "../forms/UpdateMarkForm";
import settings_svg from '../../../assets/img/settings.svg';
import stats_svg from '../../../assets/img/stats.svg';
import export_svg from '../../../assets/img/export.svg';
import SettingsModalForm from "../forms/SettingsModalForm";
import * as XLSX from "xlsx";
import pen from "../../../assets/img/write.svg";
import toast from "react-hot-toast";
import {Popover2} from '@blueprintjs/popover2';

let gn = (x, fld) => (x == undefined || x[fld] == undefined ? [] : x[fld]);

function getFieldBorderColor(task, id, data) {
    let info = data[id];
    if (info !== undefined) {
        for (let inf of info) {
            if (inf?.taskId === task) {
                return inf?.data?.goodWork ? '#8CE77E' : inf?.data?.missed ? '#56CCFF' : 'transparent';
            }
        }
    }
}

async function updateStudentTasksInfo(currInfo, studentId) {
    currInfo[studentId] = await GroupStatsAPI.getAllStudentMarks(studentId);
    return currInfo;
}

function getTeacherMark(info, studentId, taskId) {
    let currInfo = info[studentId];
    if (currInfo === undefined) {
        return 0;
    }

    currInfo = currInfo.filter(x => x?.taskId === taskId)[0];

    if (currInfo === undefined) {
        return -1;
    } else {
        return currInfo?.data?.mark === undefined || currInfo?.data?.mark === '' ? -1 : currInfo?.data?.mark;
    }
}

function getAvgLessonMark(data, studentId, tasks, ansMap) {
    if (data === undefined || studentId === undefined || tasks === undefined) {
        return -1;
    }

    let teacherMarks = [], autoMarks = [];

    for (let task of tasks) {
        let autoMark = TasksHelper.getTaskAverageMark(ansMap, task, studentId);
        let teacherMark = getTeacherMark(data, studentId, task.id);

        if (teacherMark > -1 || autoMark !== 0) {
            teacherMarks = teacherMarks.concat(teacherMark !== -1 ? teacherMark : autoMark);
        }
        if (autoMark !== 0) {
            autoMarks = autoMarks.concat(autoMark);
        }
    }

    let avgTeacherMark = teacherMarks.reduce((sum, x) => sum + +x, 0) / teacherMarks.length;
    let avgAutoMark = autoMarks.reduce((sum, x) => sum + +x, 0) / autoMarks.length;

    if (isNaN(avgAutoMark)) {
        avgAutoMark = 0;
    }
    if (isNaN(avgTeacherMark)) {
        avgTeacherMark = 0;
    }

    return {
        avgTeacherMark: avgTeacherMark.toFixed(2),
        avgAutoMark: avgAutoMark.toFixed(2)
    }
}

function getTaskExercises(task) {
    if (task == undefined) {
        return [];
    }
    let exercises = [];
    exercises = exercises.concat(task?.treadmillsIds);
    exercises = exercises.concat(task?.exercisesIds);
    exercises = exercises.concat(task?.testsIds);
    exercises = exercises.concat(task?.drochkaExercisesIds);
    exercises = exercises.filter(x => x != undefined);

    return exercises;
}

function getTaskMarks(answers, taskExercises) {
    if (answers == undefined || taskExercises == undefined) {
        return [];
    }
    let marks = answers.filter(x => taskExercises.indexOf(x.exerciseId) != -1);

    return marks;
}

let getStudentName = (student) => {
    if (student.lastName == undefined || student.firstName == undefined || student.lastName.trim() == '' || student.firstName.trim() == '') {
        return student.email;
    } else {
        return `${student.lastName} ${student.firstName}`
    }
}

function checkAttention(marks, tasks, studentId) {
    if (marks == undefined || tasks == undefined || studentId == undefined) {
        return false;
    }

    marks = marks[studentId];
    if (marks == undefined) {
        return false;
    }

    for (let task of tasks) {
        let taskInfo = marks.filter(x => x.exerciseId == task);

        if (taskInfo.length > 0) {
            taskInfo = taskInfo[0];
            if (taskInfo?.mark == -1 || taskInfo?.needsAttention == true) {
                return true;
            }
        }
    }
    return false;
}

function isObjectEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

const ledgerSet = ['Урок и задание', 'Категория', 'Срок назначения', 'Срок исполнения', 'Количество выполненных'];
const tasksSet = ['Все', 'Проверенные', 'Непроверенные', 'Пропущенные'];

async function getStudentsMapWithAnswers(students = []) {
    let obj = {};
    for (let student of students) {
        let x = await GroupStatsAPI.getAllStudentMarks(student.id);
        if (x !== undefined) {
            obj[student.id] = x;
        }
    }
    return obj;
}

export default function NewGroupLedgerPanel(props) {
    const {
        id,
        openStats = () => {

        }
    } = props;
    const dispatch = useDispatch();


    const [expandedLessonsSet, setExpandedLessonsSet] = useState((ls(`ledger_${id}_lessons_expanded`) ? ls(`ledger_${id}_lessons_expanded`) : []).reduce((st, exId) => st.add(exId), Set()));
    const [expandedTasksSet, setExpandedTasksSet] = useState((ls(`ledger_${id}_tasks_expanded`) ? ls(`ledger_${id}_tasks_expanded`) : []).reduce((st, exId) => st.add(exId), Set()));

    const [data, setData] = useState(undefined);
    const [selectedUserId, setSelectedUserId] = useState(undefined);
    const [selectedExerciseId, setSelectedExerciseId] = useState();
    const [selectedDrochkaExerciseId, setSelectedDrochkaExerciseId] = useState();
    const [selectedModalData, setSelectedModalData] = useState(undefined);
    const [diaryModal, setDiaryModal] = useState(undefined);
    const [updateMarkModal, setUpdateMarkModal] = useState(undefined);
    const [settingsModal, setSettingsModal] = useState(false);
    const [ledgerSettings, setLedgerSettings] = useState(ledgerSet);
    const [studentTasksInfo, setStudentTasksInfo] = useState({});

    const {
        group,
        loading,
        lessons,
        getLessonTasks,
        exercisesMap,
        drochkaExercisesMap,
        testsMap,
        treadmillsMap,
        allTasks,
        students
    } = useMappedState(useCallback(state => {
        let group = state.groups.groupsMap.get(id);
        let students = (group?.users || []).map(xx => state.users.usersMap.get(xx?.id)).filter(xx => (xx != undefined));
        students = students.sort(function (a, b) {
            let x, y;

            if ((a.lastName == undefined || a.lastName.trim() == '') && (b.lastName == undefined || b.lastName.trim() == '')) {
                x = a.email.toLowerCase();
                y = b.email.toLowerCase();
            } else {
                if (a.lastName == undefined || a.lastName.trim() == '') {
                    return 1;
                }
                if (b.lastName == undefined || b.lastName.trim() == '') {
                    return -1;
                }
                if (a.lastName != undefined && b.lastName != undefined) {
                    x = a.lastName.trim() + a.firstName.trim();
                    y = b.lastName.trim() + b.firstName.trim();
                }
            }

            if (x > y) return 1;
            if (x < y) return -1;
            return 0;
        })
        let getLessonTasks = lId => {
            let arr = state.tasks.tasksMap.toArray().filter(xx => (xx.lessonId == lId)).sort((a, b) => (+a.timestamp - +b.timestamp));
            // arr = arr.concat(arr).concat(arr);
            return arr;
        };
        return {
            group: state.groups.groupsMap.get(id),
            loading: state.groups.loading || state.users.loading || state.lessons.loading || state.tasks.loading || state.users.loading,
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x.classId == id)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            getLessonTasks: getLessonTasks,
            exercisesMap: state.exercises.exercisesMap,
            drochkaExercisesMap: state.drochkaExercises.drochkaExercisesMap,
            testsMap: state.tests.testsMap,
            treadmillsMap: state.treadmills.treadmillsMap,
            allTasks: state.tasks.tasksMap.toArray().filter(xx => (xx.classId == id)),
            students: students
        }
    }));

    function getAvgTotalMark(data, ansMap, studentId, lessons) {
        if (data === undefined || ansMap === undefined) {
            return 0;
        }

        let teacherMarks = [], autoMarks = [];
        let allTasks = [];
        for (let lesson of lessons) {
            allTasks = allTasks.concat(getLessonTasks(lesson?.id));
        }
        for (let task of allTasks) {
            let teacherMark = getTeacherMark(data, studentId, task?.id);
            let autoMark = TasksHelper.getTaskAverageMark(ansMap, task, studentId);
            if (teacherMark === -1) {
                if (autoMark > 0) {
                    teacherMarks = teacherMarks.concat(autoMark);
                }
            } else {
                teacherMarks = teacherMarks.concat(teacherMark);
            }

            if (autoMark > 0) {
                autoMarks = autoMarks.concat(autoMark);
            }
        }

        let avgTeacherMark = teacherMarks.reduce((sum, x) => sum + +x, 0) / teacherMarks.length;
        let avgAutoMark = autoMarks.reduce((sum, x) => sum + +x, 0) / autoMarks.length;

        return {
            avgTeacherMark,
            avgAutoMark
        }
    }

    const [displayAvgMark, setDisplayAvgMark] = useState({});
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        if (exporting) {
            exportLedger();
            setExporting(false);
        }
    }, [exporting])

    useEffect(() => {
        let obj = {};
        for (let lesson of lessons) {
            obj[lesson.id] = false;
        }
        setDisplayAvgMark(obj);
    }, [lessons.length])

    useEffect(() => {
        dispatch(groupsActions.loadGroupById(id)).then(pld => {
            console.log('-->> loadGroupById: pld = ', pld);
            if (pld?.groups != undefined && pld?.groups[0] != undefined) {
                let studentsIds = (pld?.groups[0].users || []).map(xx => xx.id);
                console.log('-->>> studentsIds = ', studentsIds);
                dispatch(usersActions.loadUsersByIds(studentsIds)).then(() => {

                });
            }
        });
        dispatch(lessonsActions.getGroupLessons(id)).then(pld => {
            console.log('getGroupLessons: pld = ', pld);
            setExpandedLessonsSet((pld?.lessons || []).reduce((st, xx) => st.add(xx.id), Set()))
        });
        dispatch(tasksActions.getGroupTasks(id)).then(pld => {
            console.log('getGroupTasks: tasks = ', pld);
            let {tasks = []} = pld;
            let allExercisesIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.exercisesIds || tsk?.exercisesIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allDrochkaExercisesIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.drochkaExercisesIds || tsk?.drochkaExercisesIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allTestsIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.testsIds || tsk?.testsIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray();
            let allTreadmillsIds = tasks
                .reduce((arr, tsk) => arr.concat(tsk?.treadmillsIds || tsk?.treadmillsIds), [])
                .reduce((st, exId) => st.add(exId), Set()).toArray().filter(x => x !== undefined);
            dispatch(drochkaExercisesActions.getExercisesByIds(allDrochkaExercisesIds)).then(() => {

            });
            dispatch(exercisesActions.getExercisesByIds(allExercisesIds)).then(() => {

            });
            dispatch(testsActions.getTestsByIds(allTestsIds)).then(() => {

            });
            dispatch(treadmillsActions.loadTreadmillsByIds(allTreadmillsIds)).then(() => {

            });
        }).catch(exc => {

        });
    }, [id]);

    useEffect(() => {
        ls(`ledger_${id}_tasks_expanded`, expandedTasksSet.toArray());
    }, [expandedTasksSet]);

    useEffect(() => {
        ls(`ledger_${id}_lessons_expanded`, expandedLessonsSet.toArray());
    }, [expandedLessonsSet]);

    useEffect(() => {
        if (id != undefined) {
            GroupStatsAPI.getGroupAnswersMap(id).then(x => {
                setData(x);
            })
        }
    }, [id, selectedModalData]);

    useEffect(() => {
        // let obj = {};
        // for (let student of students) {
        //     GroupStatsAPI.getAllStudentMarks(student.id).then(x => {
        //         if (x !== undefined) {
        //             obj[student.id] = x;
        //         }
        //     })
        // }
        // setStudentTasksInfo(obj);
        getStudentsMapWithAnswers(students).then(obj => {
            setStudentTasksInfo(obj);
        });
    }, [students.length])

    if (group == undefined) {
        return (
            <Code/>
        )
    }

    function exportLedger() {
        let table = document.getElementById('ledger_table');
        let workbook = XLSX.utils.table_to_book(table);
        let date = new Date();
        date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        XLSX.writeFile(workbook, `${group?.name}_${date}.xlsx`);
    }

    // let students = (group?.users == undefined) ? [] : group?.users;
    let selectedExercise = exercisesMap.get(selectedExerciseId);
    let selectedDrochkaExercise = drochkaExercisesMap.get(selectedDrochkaExerciseId);
    let selectedUser = (selectedUserId == undefined) ? undefined : (group?.users || []).filter(a => (a.id == selectedUserId))[0];

    // if (loading == true && (data == undefined || students.length == 0) || isObjectEmpty(studentTasksInfo)) {
    console.log('12 --->>> loading = ', loading);
    console.log('12 --->>> data = ', data);
    console.log('12 --->>> isObjectEmpty(studentTasksInfo) = ', isObjectEmpty(studentTasksInfo));
    if (loading == true && (data == undefined) || isObjectEmpty(studentTasksInfo)) {
        return (
            <div style={{width: '100%', height: `calc(100vh - 120px)`, backgroundColor: 'white'}}>
                <LottieLoader json={woman_waiting_json}/>
            </div>
        )
    }

    return (
        <Wrapper>
            <TablePlaceholder>
                <Table id='ledger_table'>

                    <Thead>
                        <Tr>
                            <ZeroTh rowSpan={6}></ZeroTh>
                        </Tr>

                        {ledgerSettings.indexOf('Урок и задание') === -1 ? null :
                            <Tr>
                                <FirstTh style={{minWidth: 120}}>
                                    Lesson
                                </FirstTh>

                                {lessons.map((lesson, i) => {
                                    let tasks = getLessonTasks(lesson?.id);
                                    return (
                                        <React.Fragment key={lesson.id + i}>
                                            <Th colSpan={tasks.length > 0 ? tasks.length : 1}>
                                                <LessonName onClick={() => {
                                                    setDisplayAvgMark({
                                                        ...displayAvgMark,
                                                        [lesson.id]: !displayAvgMark[lesson.id]
                                                    })
                                                }}>
                                                    {lesson?.name || translate('no_name')}
                                                </LessonName>
                                            </Th>
                                        </React.Fragment>
                                    )
                                })}

                            </Tr>
                        }

                        {ledgerSettings.indexOf('Урок и задание') === -1 ? null :
                            <Tr>

                                <FirstTh>
                                    Task
                                </FirstTh>

                                {lessons.map((lesson, i) => {
                                    let tasks = getLessonTasks(lesson?.id);
                                    if (tasks.length === 0) {
                                        return (
                                            <Th/>
                                        )
                                    }
                                    return (
                                        <React.Fragment key={lesson.id + i}>
                                            {tasks.map((task, j) => {
                                                return (
                                                    <Th key={task.id} onClick={() => {
                                                        setSelectedModalData({
                                                            userId: undefined,
                                                            task: task,
                                                            selectedTaskId: task.id,
                                                            selectedLessonId: lesson.id
                                                        })
                                                    }}>
                                                        <LessonName>
                                                            {task?.name || translate('no_name')}
                                                        </LessonName>
                                                    </Th>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}

                            </Tr>
                        }

                        {ledgerSettings.indexOf('Срок назначения') === -1 ? null :
                            <Tr>
                                <FirstTh>
                                    Date
                                </FirstTh>

                                {lessons.map((lesson, i) => {
                                    let tasks = getLessonTasks(lesson?.id);
                                    if (tasks.length === 0) {
                                        return (
                                            <Th/>
                                        )
                                    }
                                    return (
                                        <React.Fragment key={lesson.id + i}>
                                            {tasks.map((task, j) => {
                                                return (
                                                    <Th key={task.id}>
                                                        {moment(task?.createdTimestamp).format('DD.MM.YYYY HH:mm')}
                                                    </Th>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}

                            </Tr>
                        }

                        {ledgerSettings.indexOf('Срок исполнения') === -1 ? null :
                            <Tr>

                                <FirstTh>
                                    Due
                                </FirstTh>

                                {lessons.map((lesson, i) => {
                                    let tasks = getLessonTasks(lesson?.id);
                                    if (tasks.length === 0) {
                                        return (
                                            <Th/>
                                        )
                                    }
                                    return (
                                        <React.Fragment key={lesson.id + i}>
                                            {tasks.map((task, j) => {
                                                return (
                                                    <Th key={task.id}>
                                                        {moment(task.deadlineTimestamp).format('DD.MM.YYYY HH:mm')}
                                                    </Th>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}

                            </Tr>
                        }

                        {ledgerSettings.indexOf('Количество выполненных') === -1 ? null :
                            <Tr>

                                <FirstTh>
                                    Общая оценка
                                </FirstTh>

                                {lessons.map((lesson, i) => {
                                    let tasks = getLessonTasks(lesson?.id);
                                    if (tasks.length === 0) {
                                        return (
                                            <Th/>
                                        )
                                    }
                                    return (
                                        <React.Fragment key={lesson.id + i}>
                                            {tasks.map((task, j) => {
                                                return (
                                                    <Th key={task.id}>
                                                        <LessonName>
                                                            {''}
                                                        </LessonName>
                                                    </Th>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}

                            </Tr>
                        }

                    </Thead>

                    <Tbody>
                        {students.map((u, i) => {
                            let {avgTeacherMark, avgAutoMark} = getAvgTotalMark(studentTasksInfo, data, u.id, lessons);
                            return (
                                <UTr key={u.id}>
                                    <Td>
                                        <StudentName onClick={() => {
                                            setDiaryModal(u);
                                        }}>
                                            {`${+i + 1}. ${getStudentName(u)}`}
                                        </StudentName>
                                    </Td>
                                    <Td style={{width: 30, textAlign: 'center'}}>
                                        {avgTeacherMark === undefined || avgAutoMark === undefined ? null :
                                            <span style={{fontWeight: 'bold', fontSize: '16px'}}>
                                                {exporting === false ?
                                                    isNaN(avgAutoMark) && isNaN(avgTeacherMark) ? null :
                                                        `${isNaN(avgAutoMark) ? '-' : avgAutoMark.toFixed(2)} ${isNaN(avgTeacherMark) ? '' : `(${avgTeacherMark.toFixed(2)})`}`
                                                    :
                                                    `${isNaN(avgTeacherMark) ? '' : avgTeacherMark.toFixed(2)}`
                                                }
                                            </span>
                                        }

                                    </Td>

                                    {lessons.map((lesson, i) => {
                                        let showAvg = displayAvgMark[lesson.id];
                                        let tasks = getLessonTasks(lesson?.id);
                                        if (tasks.length === 0) {
                                            return (
                                                <Td onClick={() => {
                                                    toast("Добавьте задание");
                                                }}/>
                                            )
                                        } else if (showAvg === true) {
                                            let avgMark = getAvgLessonMark(studentTasksInfo, u.id, tasks, data);
                                            return (
                                                <Td style={{textAlign: 'center'}} colSpan={tasks.length}>
                                                    {avgMark?.avgAutoMark} ({avgMark?.avgTeacherMark})
                                                </Td>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment key={lesson.id + i}>
                                                    {tasks.map((task, j) => {
                                                        let avr = TasksHelper.getTaskAverageMark(data, task, u.id);
                                                        let teacherMark = getTeacherMark(studentTasksInfo, u.id, task.id);
                                                        let isZero = (avr == 0);
                                                        let exercises = getTaskExercises(task);
                                                        let allMarks = data == undefined ? [] : getTaskMarks(data[u.id], exercises);
                                                        let displayMark = allMarks.filter(x => x?.needsAttention == false).length > 0 && allMarks.length > 0;
                                                        let needsAttention = checkAttention(data, task?.exercisesIds, u.id); //только упражнения
                                                        // let needsAttention = checkAttention(data, exercises, u.id); //все тренажеры

                                                        return (
                                                            <MarkTd key={task.id} onClick={() => {
                                                                setSelectedModalData({
                                                                    userId: u.id,
                                                                    task: task,
                                                                    selectedTaskId: task.id,
                                                                    selectedLessonId: lesson.id
                                                                });
                                                            }}>

                                                                <InnerMarkPlaceholder
                                                                    style={{border: `2px solid ${getFieldBorderColor(task.id, u.id, studentTasksInfo)}`}}>
                                                                    {exporting === false ?
                                                                        <MarkPlaceholder>
                                                                            {isZero && displayMark == false ? null :
                                                                                avr < 0 ? <span style={{ fontSize: 10 }}>{'✔️'}</span> : `${(+avr).toFixed(2)}`}
                                                                            {teacherMark < 0 ? null : `  (${teacherMark})`}

                                                                        </MarkPlaceholder>
                                                                        :
                                                                        <MarkPlaceholder>
                                                                            {teacherMark < 0 ? isZero === true ? false :
                                                                                avr < 0 ? <span
                                                                                        style={{fontSize: 10}}>{true}</span>
                                                                                    :
                                                                                    `${(+avr).toFixed(2)}` : teacherMark
                                                                            }
                                                                        </MarkPlaceholder>
                                                                    }
                                                                    {allMarks?.length == exercises?.length || allMarks.length == 0 ? null :
                                                                        <Popover2
                                                                            content={
                                                                                <div style={{padding: 15}}>
                                                                                    Студент выполнил не все тренажеры
                                                                                </div>
                                                                            }
                                                                            interactionKind='hover'
                                                                            placement='auto'
                                                                            fill={true}
                                                                        >
                                                                            <Dots src={dots}/>
                                                                        </Popover2>
                                                                    }

                                                                    {needsAttention == false ? null :
                                                                        <Popover2
                                                                            content={
                                                                                <div style={{padding: 15}}>
                                                                                    Требуется ручная проверка
                                                                                </div>
                                                                            }
                                                                            interactionKind='hover'
                                                                            placement='auto'
                                                                            fill={true}
                                                                        >
                                                                            <Dots src={warning}/>
                                                                        </Popover2>
                                                                    }
                                                                    <EditMark src={pen}
                                                                              onClick={(evt) => {
                                                                                  evt.stopPropagation();
                                                                                  setUpdateMarkModal({
                                                                                      studentId: u.id,
                                                                                      taskId: task.id
                                                                                  });
                                                                              }}/>
                                                                </InnerMarkPlaceholder>
                                                            </MarkTd>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        }
                                    })}

                                </UTr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TablePlaceholder>

            <SettingsButtons>
                <Button src={settings_svg} title="Настройки" onClick={() => {
                    setSettingsModal(true);
                }}/>
                <Button src={stats_svg} title="Статистика" onClick={() => {
                    openStats();
                }}/>
                <Button src={export_svg} title="Экспорт в Excel" onClick={() => {
                    setExporting(true);
                }}/>
            </SettingsButtons>

            {diaryModal == undefined ? null :
                <NiceModal onClose={() => {
                    setDiaryModal(undefined);
                }}>
                    <SelectedModalInnerPlaceholder>

                        <GroupDiaryPanel
                            studentId={diaryModal}
                            userRole={'teacher'}
                            id={group.id}

                        />

                    </SelectedModalInnerPlaceholder>
                </NiceModal>
            }

            {selectedModalData == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedModalData(undefined);
                }}>
                    <SelectedModalInnerPlaceholder>

                        <LessonsList
                            canEditLessons={false}
                            userId={selectedModalData?.userId}
                            lessons={lessons}
                            selectedTaskId={selectedModalData?.selectedTaskId}
                            selectedLessonId={selectedModalData?.selectedLessonId}
                            tasks={[selectedModalData?.task]}
                        />

                        {/*<TeacherGroupStudentPanel*/}
                        {/*    id={id}*/}
                        {/*    studentId={selectedModalData?.userId}*/}
                        {/*/>*/}

                    </SelectedModalInnerPlaceholder>
                </NiceModal>
            }

            {updateMarkModal === undefined ? null :
                <NiceModal onClose={() => {
                    setUpdateMarkModal(undefined);
                }}>
                    <UpdateMarkModalPlaceholder>
                        <UpdateMarkForm
                            userId={updateMarkModal?.studentId}
                            taskId={updateMarkModal?.taskId}
                            close={async () => {
                                let newInfo = await updateStudentTasksInfo(studentTasksInfo, updateMarkModal?.studentId);
                                setStudentTasksInfo(newInfo);
                                setUpdateMarkModal(undefined);
                            }}
                        />
                    </UpdateMarkModalPlaceholder>
                </NiceModal>
            }

            {settingsModal === false ? null :
                <NiceModal onClose={() => {
                    setSettingsModal(false);
                }}>
                    <SettingsModalPlaceholder>
                        <SettingsModalForm
                            ledgerSettings={ledgerSet}
                            chosenLedgerSettings={ledgerSettings}
                            tasksSettings={tasksSet}
                            onSaveSettings={settings => {
                                setLedgerSettings(settings.ledgerSettings);
                                setSettingsModal(false);
                            }}/>
                    </SettingsModalPlaceholder>
                </NiceModal>
            }

        </Wrapper>
    );
}

const SelHeading = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: 1px solid whitesmoke;
`;

const SelectedModalInnerPlaceholder = styled.div`
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  width: 750px;
  @media (max-width: 940px) {
    width: 90vw;
  }
`;

const StudentName = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
`;

const LessonName = styled.div`
  font-weight: bold;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TablePlaceholder = styled.div`
  width: 100%;
  //height: 120px;
  max-height: calc(100% - 130px);
  overflow: auto;
  //overflow-y: auto;
  //padding: 5px;
  border: 1px solid lightgrey;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ECECEC;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #B4B4B4;
    border-radius: 50px;
  }
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  //border-collapse: collapse;
  color: #767676;

  td:first-child, td:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
    border-right: 1px solid lightgrey;
    background-color: white;

    @media (max-width: 1024px) {
      position: unset;
    }
  }

  td:first-child {
    left: 0;
  }

  td:last-child {
    border-right: none;
  }

  td:nth-child(2) {
    left: 240px;
  }
`;


const Tr = styled.tr`

`;

const UTr = styled(Tr)`
  height: 38px;

  :hover {
    background: floralwhite;
  }
`;

const Th = styled.th`
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 135px;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  background-color: white;
  z-index: 1;

  :last-child {
    border-right: none;
  }
`;

const FirstTh = styled(Th)`
  text-align: left;
  padding-left: 5px;
  position: sticky;
  position: -webkit-sticky;
  left: 240px;

  @media (max-width: 1024px) {
    position: unset;
  }
`;

const ZeroTh = styled(Th)`
  text-align: left;
  padding-left: 5px;
  min-width: 240px;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  border-right: 1px solid lightgrey !important;
  z-index: 2;

  @media (max-width: 1024px) {
    position: unset;
  }
`;

const Td = styled.td`
  box-sizing: border-box;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: 3px;
`;

const MarkTd = styled(Td)`
  min-width: 170px;
  padding: 0px !important;
  height: inherit !important;
`;

const InnerMarkPlaceholder = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 3px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 14px;
  cursor: pointer;

  :hover {
    border: 2px solid #6081e3;
  }
`;

const MarkPlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const EditMark = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 10px;
  opacity: 0.5;
  position: absolute;
  top: 2px;
  right: 2px;

  :hover {
    opacity: 1;
  }
`;

const Dots = styled.img`
  width: 16px;
  height: 16px;
`;

const Thead = styled.thead`
  tr:first-child {
    height: 0;
    background-color: transparent;
  }

  tr:nth-child(2) {
    border-top: 1px solid lightgrey;
    z-index: 2;
  }
`;

const Tbody = styled.tbody`

`;


const Wrapper = styled.div`
  background: white;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
`;

const SettingsButtons = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Button = styled.img`
  cursor: pointer;
`;

const SettingsModalPlaceholder = styled.div`
  width: 300px;
  @media (max-width: 720px) {
    margin: 10px;
  }
`;

const UpdateMarkModalPlaceholder = styled.div`
  @media (max-width: 720px) {
    width: 300px;
    margin: 10px;
  }
`;