import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import ls from 'local-storage'
import BrutalOverlayModal from "../modals/BrutalOverlayModal";
import {BlueButton} from "../ira/ui/Buttons";
import UsersAPI from "../../api/UsersAPI";
import ConsentText from "./ConsentText";
import * as actions from "../../redux/actions/UsersActions";

export default function ConsentTool(props) {
    const {
        currentUser
    } = useMappedState(useCallback(state => {
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId)
        }
    }));

    const dispatch = useDispatch();

    let isGranted = currentUser != undefined && currentUser?.consentGranted;
    if (isGranted == true){
        return null;
    }

    return (
        <BrutalOverlayModal hasClose={false} onClose={() => {
            window.alert('Использовать платформу можно только после согласия с пользовательским соглашением.');
        }}>
            <Wrapper>
                <ContentPlaceholder>
                    <ConsentText/>
                </ContentPlaceholder>

                <ButtonsPlaceholder>

                    <BlueButton onClick={async () => {
                        await dispatch(actions.updateUser({
                            ...currentUser,
                            consentGranted: true
                        }))
                    }}>
                        Я прочитал (-ла) условия соглашения и принимаю их
                    </BlueButton>

                    <ButtonSpan onClick={async () => {
                        await UsersAPI.logout();
                        window.alert('К сожалению, Вы не можете использовать платформу.');
                        window.location.reload();
                    }}>
                        Я не принимаю условия соглашения
                    </ButtonSpan>


                </ButtonsPlaceholder>
            </Wrapper>
        </BrutalOverlayModal>

    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentPlaceholder = styled.div`
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  width: 1020px;
  box-sizing: border-box;
  text-align: justify;
  margin-bottom: 20px;
  @media(max-width: 1020px){
    width: 100%;
  }
`;

const ButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonSpan = styled.span`
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: underline;
  margin-top: 20px;
`;