import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Input} from "../../ui/Input";
import {BlueButton} from "../../ira/ui/Buttons";
import {translate} from "../../../utils/Translate";

export default function BackupForm(props) {
    const {
        onSave = d => {

        },
        loading = false,
        buttonName = 'Сохранить',
        canDelete = false,
        onDelete = () => {

        }
    } = props;
    const [name, setName] = useState(props.name == undefined ? '' : props.name);

    return (
        <Wrapper>

            <Field>
                <Label>
                    {translate('title')}
                </Label>
                <Input placeholder={translate('title')} value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <div>
                <BlueButton onClick={() => {
                    onSave({
                        name: name
                    });
                }}>
                    <Spin visible={loading}/>
                    {buttonName}
                </BlueButton>
            </div>

            {canDelete == false ? null :
                <div style={{color: 'red', marginTop: 20}}>
                    <span style={{cursor: 'pointer'}} onClick={() => {
                        if (window.confirm('Вы уверены?') == false) {
                            return;
                        }
                        onDelete();
                    }}>
                        Удалить
                    </span>
                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;