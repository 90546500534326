import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from "arui-feather/spin";
import {Button} from "../../../ui/Button";

import {Input, Textarea} from '../../../ira/ui/Inputs';
import {translate} from "../../../../utils/Translate";
import AccessGroupsDropdownPanel from "../../../access_groups/panels/AccessGroupsDropdownPanel";
import Select from "react-select";
import {ACCESS_OPTIONS} from "../../../exercises/forms/ExerciseInfoForm";
import {GreenButton} from '../../../ira/ui/Buttons';
import AccountingForm from "../../../accounting/tools/AccountingForm";
import SimpleFileUploader from "../../../uploader/tools/SimpleFileUploader";

export default function UpdateTreadmillForm(props) {

    const {
        canUpdate = true,
        loading = false,
        onUpdate = d => {

        }
    } = props;

    const [access, setAccess] = useState(props.access ? props.access : 'private');

    const [accessGroupsIds, setAccessGroupsIds] = useState(props?.accessGroupsIds ? props.accessGroupsIds : []);
    const [name, setName] = useState(props?.name || '');
    const [studentTask, setStudentTask] = useState(props?.studentTask || '');
    const [description, setDescription] = useState(props?.description || '');
    const [sourcesText, setSourcesText] = useState(props?.sourcesText || '');
    const [treadmillTime, setTreadmillTime] = useState(props?.treadmillTime || '');
    const [totalAudioTime, setTotalAudioTime] = useState(props?.totalAudioTime || '');
    const [avatar, setAvatar] = useState(props.avatar ? props.avatar : '');

    const [accountingWords, setAccountingWords] = useState(props.accountingWords || []);
    const [accountingGrammarItems, setAccountingGrammarItems] = useState(props.accountingGrammarItems || []);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название тредмила
                </Label>
                <Input placeholder={'Название'} value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Описание тредмила
                </Label>
                <Textarea placeholder={'Описание'} value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}></Textarea>
            </Field>

            <Field>
                <Label>
                    Формулировка задания для обучающегося
                </Label>
                <Textarea placeholder={'Формулировка задания для обучающегося'} value={studentTask} onChange={evt => {
                    setStudentTask(evt.target.value);
                }}></Textarea>
            </Field>

            <Field>
                <Label>
                    Ссылки на источники
                </Label>
                <Textarea placeholder={'Ссылки на источники'} value={sourcesText} onChange={evt => {
                    setSourcesText(evt.target.value);
                }}></Textarea>
            </Field>

            <Field>
                <Label>
                    Время наговора студента
                </Label>
                <Input placeholder={'В секундах'} value={treadmillTime} onChange={evt => {
                    if (evt.target.value >= 0 || evt.target.value === '') {
                        setTreadmillTime(evt.target.value);
                    }
                }}/>
            </Field>

            <Field>
                <Label>
                    Общее время аудирования
                </Label>
                <Input placeholder={'В секундах'} value={totalAudioTime} onChange={evt => {
                    if (evt.target.value >= 0 || evt.target.value === '') {
                        setTotalAudioTime(evt.target.value);
                    }
                }}/>
            </Field>

            <Field className="field">
                <Label>
                        <span>
                            Доступ
                        </span>
                </Label>
                <Select options={ACCESS_OPTIONS}
                        value={ACCESS_OPTIONS.filter(x => (x.value == access))[0]}
                        onChange={a => {
                            setAccess(a.value);
                        }}/>
            </Field>

            <Field>
                <Label>
                        <span>
                            Группы доступа
                        </span>
                </Label>
                <AccessGroupsDropdownPanel
                    selectedIds={accessGroupsIds}
                    onChange={x => {
                        setAccessGroupsIds(x);
                    }}
                />
            </Field>

            <Field>
                <Label>
                    Аватар
                </Label>
                <div>
                    {avatar == undefined || avatar == '' ? null :
                        <AvatarImg src={avatar}/>
                    }
                </div>
                {canUpdate === false ? null :
                    <AvatarUploader>
                        <SimpleFileUploader
                            onSave={url => {
                                setAvatar(url);
                            }}
                        />
                        {avatar == undefined || avatar == '' ? null :
                            <DeleteAvatar onClick={() => setAvatar("")}>{translate('delete')}</DeleteAvatar>
                        }
                    </AvatarUploader>
                }
            </Field>

            <AccountingForm
                grammaraItems={accountingGrammarItems}
                words={accountingWords}
                onChange={(words_, grammarItems_) => {
                    setAccountingWords(words_);
                    setAccountingGrammarItems(grammarItems_);
                }}
            />

            {canUpdate == false ? null :
                <Field>
                    {loading == true ? <Spin visible={true}/> :
                        <GreenButton appearance={'primary'} onClick={() => {
                            onUpdate({
                                name,
                                description,
                                sourcesText,
                                studentTask,
                                accessGroupsIds: accessGroupsIds,
                                access,
                                treadmillTime,
                                totalAudioTime,
                                avatar: avatar,
                                accountingWords: accountingWords,
                                accountingGrammarItems: accountingGrammarItems
                            });
                        }}>
                            {translate('save')}
                        </GreenButton>
                    }
                </Field>
            }

        </Wrapper>
    );
}

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  height: calc(100vh - 180px);
  overflow-y: auto;
`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const AvatarImg = styled.img`
  //aspect-ratio: 16 / 9;
  max-width: 200px;
  max-height: 140px;
`;

const AvatarUploader = styled.div`
  display: flex;
  gap: 40px;
`;

const DeleteAvatar = styled.div`
  cursor: pointer;
  color: red;
  opacity: 0.8;
  
  :hover {
    opacity: 0.6;
  }
`;