import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {API_ENDPOINT} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";
import {Input} from "../../ira/ui/Inputs";
import Tabs, {TabItem} from "../../ui/Tabs";
import ViewAuthorTool from "../../author/tools/ViewAuthorTool";
import AllExercisesPanel from "../../exercises/panels/AllExercisesPanel";
import AllDrochkaExercisesPanel from "../../drochka_exercise/panels/AllDrochkaExercisesPanel";
import AllDialogsExercisesPanel from "../../dialogs/panels/AllDialogsExercisesPanel";
import AllQuestionnairesPanel from "../../questionnaires/panels/AllQuestionnairesPanel";
import AllVideoMaterialsPanel from "../../materials/panels/AllVideoMaterialsPanel";
import SimpleAnalyticsAPI from "../../../api/SimpleAnalyticsAPI";
import GroupsPanel from "../../groups/panels/GroupsPanel";

export default function TeacherAnalyticsPanel(props) {
    const [teachers, setTeachers] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedId, setSelectedId] = useState(undefined);
    const [searchText, setSearchText] = useState('');
    const [selectedTeacherData, setSelectedTeacherData] = useState(undefined);
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [selectedClassId, setSelectedClassId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        setSelectedTeacherData(undefined);
        axios.get(`${API_ENDPOINT}/teachers/all`).then(d => d.data.result).then(arr => {
            setTeachers(arr);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (selectedId == undefined) {
            return;
        }
        setSelectedSessions([]);
        setSelectedTeacherData(undefined);
        axios.get(`${API_ENDPOINT}/v3/classes`, {
            params: {
                userId: selectedId
            }
        }).then(d => d.data).then(arr => {
            let classes = arr;
            let d = {}
            d.classes = classes;
            setSelectedTeacherData(d);
        });
        // axios.get(`${API_ENDPOINT}/user/${selectedId}/sessions`).then(d => d.data).then(arr => {
        //     setSelectedSessions(arr);
        // });
        SimpleAnalyticsAPI.getAllTrackedSessions(selectedId).then(arr => {
            setSelectedSessions(arr);
        })
    }, [selectedId]);

    const shouldShowResults = (searchText.length > 4);
    const filteredTeachers = (shouldShowResults == false) ? [] : teachers.filter(x => {
        let s = `${x.email}${CommonHelper.getUserName(x)}`.toLowerCase();
        let s2 = searchText.toLowerCase().trim();
        return (s.indexOf(s2) > -1);
    });

    const selectedTeacher = (selectedId == undefined) ? undefined : teachers.filter(x => (x?.id == selectedId))[0];
    let selectedClass = (selectedTeacherData == undefined || selectedTeacherData?.classes == undefined || selectedClassId == undefined) ? undefined : selectedTeacherData.classes.filter(x => (x.id == selectedClassId))[0];


    return (
        <Wrapper>

            <TopPlaceholder>
                <Input
                    value={searchText}
                    placeholder={'Введите почту или имя преподавателя'}
                    onChange={evt => {
                        setSearchText(evt.target.value);
                        setSelectedTeacherData(undefined);
                        setSelectedId(undefined);
                    }}
                />
            </TopPlaceholder>

            {shouldShowResults == true ? null :
                <div>
                    Введите как минимум 4 символа в поисковую строчку
                </div>
            }

            {shouldShowResults == false ? null :
                <ResultsPlaceholder>
                    {filteredTeachers.length > 0 ? null :
                        <div>
                            Ничего не найдено
                        </div>
                    }

                    {filteredTeachers.map((a, i) => {
                        return (
                            <TeacherItem
                                selected={(selectedTeacher?.id == a?.id)}
                                key={a.id} onClick={() => {
                                setSelectedId(a.id);
                            }}>
                                <ItemName>
                                    {CommonHelper.getUserName(a)}
                                </ItemName>
                                <div>
                                    {a.email}
                                </div>
                            </TeacherItem>
                        )
                    })}

                </ResultsPlaceholder>
            }

            <Sidebar
                width={Math.min(window.innerWidth, 1280)}
                visible={(selectedTeacher != undefined)}
                onCloserClick={() => {
                    setSelectedId(undefined);
                }}>

                {selectedTeacher == undefined ? null :
                    <div>
                        <Tabs
                            tabs={[
                                {
                                    label: 'Группы',
                                    content: (
                                        <TabItem>
                                            <div style={{marginBottom: 10}}>
                                                {`Количество групп: ${(selectedTeacherData?.classes || []).length}`}
                                            </div>

                                            <GroupsPanel
                                                userId={selectedTeacher?.id}
                                            />


                                            {/*{(selectedTeacherData?.classes || []).map((a, i) => {*/}
                                            {/*    return (*/}
                                            {/*        <TeacherItem key={a.id}>*/}
                                            {/*            <ItemName*/}
                                            {/*                onClick={() => {*/}
                                            {/*                    setSelectedClassId(a.id);*/}
                                            {/*                }}*/}
                                            {/*                style={{*/}
                                            {/*                    display: 'flex',*/}
                                            {/*                    flexDirection: 'row',*/}
                                            {/*                    alignItems: 'center',*/}
                                            {/*                    justifyContent: 'space-between'*/}
                                            {/*                }}>*/}
                                            {/*                <span>*/}
                                            {/*                    {`${a.name}`}*/}
                                            {/*                </span>*/}
                                            {/*                <span>*/}
                                            {/*                    {(a?.users || []).length}*/}
                                            {/*                </span>*/}
                                            {/*            </ItemName>*/}
                                            {/*        </TeacherItem>*/}
                                            {/*    )*/}
                                            {/*})}*/}
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Сессии',
                                    content: (
                                        <TabItem>
                                            <div style={{marginBottom: 10}}>
                                                {`Количество сессиий авторизации: ${(selectedSessions || []).length}`}
                                            </div>
                                            {(selectedSessions || []).map((a, i) => {
                                                return (
                                                    <TeacherItem key={a.id}>
                                                        <ItemName>
                                                            {moment(a.t).format('DD.MM.YYYY HH:mm')}
                                                        </ItemName>
                                                    </TeacherItem>
                                                )
                                            })}
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Авторство',
                                    content: (
                                        <TabItem>
                                            <Tabs tabs={[
                                                {
                                                    label: 'Упражнения',
                                                    content: (
                                                        <TabItem>
                                                            <AllExercisesPanel
                                                                mode={'all'}
                                                                creatorId={selectedId}
                                                            />
                                                        </TabItem>
                                                    )
                                                },
                                                {
                                                    label: 'Карточки',
                                                    content: (
                                                        <TabItem>
                                                            <AllDrochkaExercisesPanel
                                                                mode={'all'}
                                                                creatorId={selectedId}
                                                            />
                                                        </TabItem>
                                                    )
                                                },
                                                {
                                                    label: 'Диалоги',
                                                    content: (
                                                        <TabItem>
                                                            <AllDialogsExercisesPanel
                                                                mode={'all'}
                                                                creatorId={selectedId}
                                                            />
                                                        </TabItem>
                                                    )
                                                },
                                                {
                                                    label: 'Опросники',
                                                    content: (
                                                        <TabItem>
                                                            <AllQuestionnairesPanel
                                                                mode={'all'}
                                                                creatorId={selectedId}
                                                            />
                                                        </TabItem>
                                                    )
                                                },
                                                {
                                                    label: 'Видео материалы',
                                                    content: (
                                                        <TabItem>
                                                            <AllVideoMaterialsPanel
                                                                mode={'all'}
                                                                creatorId={selectedId}
                                                            />
                                                        </TabItem>
                                                    )
                                                }
                                            ]}/>
                                        </TabItem>
                                    )
                                },

                            ]}
                        />
                    </div>
                }

            </Sidebar>

            {/*<Sidebar*/}
            {/*    width={Math.min(1280, window.innerWidth - 20)}*/}
            {/*    visible={(selectedClass != undefined)} onCloserClick={() => {*/}
            {/*    setSelectedClassId(undefined);*/}
            {/*}}>*/}
            {/*    {selectedClass == undefined ? null :*/}
            {/*        <div>*/}
            {/*            <h3>*/}
            {/*                {selectedClass?.name}*/}
            {/*            </h3>*/}
            {/*            <p>*/}
            {/*                {`Студенты: ${(selectedClass?.users || []).length}`}*/}
            {/*            </p>*/}

            {/*            <GroupsPanel*/}
            {/*                userId={selectedClass?.ownerId}*/}
            {/*            />*/}

            {/*        </div>*/}
            {/*    }*/}

            {/*</Sidebar>*/}

        </Wrapper>
    );
}

const TeacherItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid whitesmoke;
  background: ${props => (props.selected == true ? 'blue' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const ResultsPlaceholder = styled.div`

`;

const Wrapper = styled.div`

`;

const ItemName = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 5px;
  padding-bottom: 10px;
`;