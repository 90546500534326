import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import RecordComponent from "../../record/RecordComponent";
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";

const renderRole = role => {
    return (
        <RoleInfoPlaceholder>

            <AvaPlaceholder>
                <Avatar src={role?.avatar}/>
            </AvaPlaceholder>
            <RoleName>
                {role?.name}
            </RoleName>

        </RoleInfoPlaceholder>
    )
}

const renderRecorder = (currentMessageNumber, answersMap, messages, onAnswersMapChange = () => {

}) => {

    let currentMessage = (currentMessageNumber > messages.length - 1) ? undefined : messages[currentMessageNumber % messages.length];
    if (currentMessage == undefined) {
        return null;
    }
    let willFinishOnNext = (+currentMessageNumber + 2 > messages.length - 1);
    let curAnsUrl = (currentMessage == undefined) ? undefined : answersMap[currentMessage.id];

    return (
        <RecorderPlaceholder key={`rec_${currentMessageNumber}`}>
            <RecordComponent recordedUrl={getFixedUrl(curAnsUrl)}
                             onSave={url => {
                                 let newMap = {
                                     ...answersMap,
                                     [currentMessage.id]: url
                                 }
                                 onAnswersMapChange(newMap)
                                 // this.setState({
                                 //     answersMap: newMap
                                 // }, () => {
                                 //     this.props.onAnswersMapChange(this.state.answersMap);
                                 //     this.goNext();
                                 // });
                             }}/>
        </RecorderPlaceholder>
    )
}

export default function DialogAnswerTool(props) {
    const {
        autoPlay = true,
        videoUrl,
        firstRole,
        secondRole,
        messages = [],
        selectedRoleNumber = 0,
        onBack = () => {

        },
        onAnswersMapChange = newMap => {

        }
    } = props;
    let selectedRole = (selectedRoleNumber == 0) ? firstRole : secondRole;
    let opponentRoleNumber = (+selectedRoleNumber + 1) % 2;
    let opponentRole = (opponentRoleNumber == 0) ? firstRole : secondRole;


    const [answersMap, setAnswersMap] = useState(props.answersMap || {});
    const [currentMessageNumber, setCurrentMessageNumber] = useState(props.selectedRoleNumber == undefined ? 0 : props.selectedRoleNumber);
    const [hintVisible, setHintVisible] = useState(false);
    const [finished, setFinished] = useState(false);

    let rRoleNumber = currentMessageNumber % 2;
    let isAnswering = (rRoleNumber == selectedRoleNumber);

    let shouldShowBePreparedMessage = (selectedRoleNumber == 0 && currentMessageNumber == 0);
    let currentMessage = (currentMessageNumber > messages.length - 1) ? undefined : messages[currentMessageNumber % messages.length];
    let prevOpponentMessage = (currentMessageNumber == 0) ? undefined : messages[+currentMessageNumber - 1];

    console.log('render: shouldShowBePreparedMessage = ', shouldShowBePreparedMessage);

    return (
        <Wrapper className={''}>

            <TopPlaceholder>
                <Half style={{height: '32px', lineHeight: '32px'}}>
                    <BackSpan style={{cursor: 'pointer'}} onClick={() => {
                        onBack();
                    }}>
                        {`< Назад`}
                    </BackSpan>
                </Half>
                <Half style={{textAlign: 'right'}}>

                    <InlinePlaceholder>
                        {'Выбранная роль  '}
                        <br/>
                        <b>
                            {selectedRole?.name}
                        </b>
                    </InlinePlaceholder>
                    <InlinePlaceholder>
                        <Avatar src={getFixedUrl(selectedRole?.avatar)} size={40}/>
                    </InlinePlaceholder>
                </Half>

            </TopPlaceholder>

            <SquaresList>
                {messages.map((m, k) => {
                    let hidden = ((selectedRoleNumber % 2) != (k % 2));
                    return (
                        <Square hidden={hidden}
                                selected={currentMessageNumber == k}
                                answered={(m != undefined) && answersMap[m.id] != undefined}
                                key={`${m.id}_${k}`}
                                onClick={() => {
                                    setCurrentMessageNumber(k);
                                    setHintVisible(false);
                                    setFinished(false);
                                }}>
                            {`${1 + +Math.floor(k / 2)}`}
                        </Square>
                    )
                })}
            </SquaresList>

            {finished == true ?
                <FinishedPlaceholder>
                    Упражнение выполнено!
                </FinishedPlaceholder>
                :
                <div>
                    {shouldShowBePreparedMessage == false ? null :
                        <CenterD>

                            <RoleInfoPlaceholder>

                                {renderRole(firstRole)}

                            </RoleInfoPlaceholder>

                            Начинайте говорить

                        </CenterD>
                    }

                    {prevOpponentMessage == undefined ? null :
                        <OpponentPlaceholder>

                            <RoleInfoPlaceholder>
                                {renderRole(opponentRole)}
                            </RoleInfoPlaceholder>

                            <OpponentRoleAudioPlaceholder>
                                <OpponentAudio controls={true} autoPlay={autoPlay}
                                               key={`aud_op_${currentMessageNumber}`}>
                                    <source src={getFixedUrl(prevOpponentMessage?.audioUrl)}/>
                                </OpponentAudio>
                            </OpponentRoleAudioPlaceholder>
                            <OpponentTextPlaceholder>
                                {prevOpponentMessage?.text}
                            </OpponentTextPlaceholder>

                        </OpponentPlaceholder>
                    }

                    {shouldShowBePreparedMessage == true ? null :
                        <MyAnswerLabel>
                            {`${selectedRole.name}:`}
                        </MyAnswerLabel>
                    }

                    <RecorderPlaceholder>
                        {renderRecorder(currentMessageNumber, answersMap, messages, newMap => {
                            onAnswersMapChange(newMap);
                            setAnswersMap(newMap);
                            let willFinishOnNext = (+currentMessageNumber + 2 > messages.length - 1);
                            setFinished(willFinishOnNext);
                            setCurrentMessageNumber(+currentMessageNumber + 2);
                            setHintVisible(false);
                        })}
                    </RecorderPlaceholder>

                    {currentMessage == undefined ? null :
                        <HintPlaceholder>
                            {hintVisible == false ?
                                <HintSpan onClick={() => {
                                    setHintVisible(true);
                                }}>
                                    Показать текст реплики
                                </HintSpan>
                                :
                                <div>
                                    {currentMessage?.text}
                                </div>
                            }
                        </HintPlaceholder>
                    }
                </div>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`
  width: 650px;
  margin: 0 auto;
  background: white;
  padding: 5px;
  @media (max-width: 640px) {
    width: 75vw;
  }
`;

const MyAnswerLabel = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
`;

const RecorderPlaceholder = styled.div`
  margin-top: 10px;
`;


const HintPlaceholder = styled.div`
  text-align: center;
`;

const HintSpan = styled.span`
  display: inline-block;
  font-size: 14px;
  opacity: 0.5;
  cursor: pointer;
  font-style: italic;;

  :hover {
    opacity: 1;
  }
`;

const TopPlaceholder = styled.div`

`;

const OpponentRoleAudioPlaceholder = styled.div`
  padding: 5px;
  text-align: center;
`;

const OpponentTextPlaceholder = styled.div`
  text-align: center;
`;

const OpponentAudio = styled.audio`
  display: inline-block;
  width: 50%;
`;

const InlinePlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
`;

const CenterD = styled.div`
  text-align: center;
`;

const OpponentPlaceholder = styled.div`

`;

const FinishedPlaceholder = styled.div`
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`;

const Half = styled.div`
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: 50%;
`;


const SquaresList = styled.div`
  text-align: center;
`;

const BackSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const squareSize = 32;

const Square = styled.div`
  display: ${props => (props.hidden == true ? 'none' : 'inline-block')};
  vertical-align: top;
  box-sizing: border-box;
  width: ${squareSize}px;
  height: ${squareSize}px;
  border: 1px solid whitesmoke;
  line-height: ${squareSize}px;
  background: ${props => (props.selected == true ? 'blue' : (props.answered == true ? 'green' : 'transparent'))};
  color: ${props => ((props.selected == true || props.answered == true) ? 'white' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
`;

const RoleInfoPlaceholder = styled.div`
  text-align: center;
  padding: 10px;
`;

const AvaPlaceholder = styled.div`

`;

const RoleName = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

const avaSize = 90;

const Avatar = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${props => (props.size == undefined ? avaSize : props.size)}px;
  height: ${props => (props.size == undefined ? avaSize : props.size)}px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
  border-radius: 1000px;
  background-color: lightgrey;
  cursor: pointer;
  border: 2px solid wheat;

  :hover {
    opacity: 0.8;
  }
`;