import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import chevron_icon from "../../../assets/img/chevron.svg";
import delete_icon from "../../../assets/img/delete_icon.svg";

import edit_icon_black from '../../../assets/img/edit_icon.svg'
import edit_icon_white from '../../../assets/img/edit_white.svg'

import box_icon from '../../../assets/img/box.svg'
import save_icon from '../../../assets/img/save.svg'

import NiceConfirm from "../../modals/NiceConfirm";

import no_tasks_lottie from '../../../assets/lottie/no_tasks_lottie.json'
import LottieLoader from "../../loaders/LottieLoader";
import {BlueButton, PurpleButton, YellowButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import UpdateTaskForm from "../forms/UpdateTaskForm";
import TaskEditor from "./TaskEditor";
import PrivateContainerFolderSelectorPanel from "../../containers/panels/PrivateContainerFolderSelectorPanel";
import SlidesAPI from "../../../api/SlidesAPI";
import toast from "react-hot-toast";
import SimpleStudentLessonTaskFileUploaderPanel from "../panels/SimpleStudentLessonTaskFileUploaderPanel";
import {Input} from "../../ira/ui/Inputs";
import {translate} from "../../../utils/Translate";
import UIHelper from "../../../helpers/UIHelper";

export default function LessonTasksEditor(props) {
    const {
        isOnlineMode = false,
        buffer,
        groupId,
        onlineHiddenItemsIds = [],

        onOnlineEyeToggle = eId => {

        },

        onTranscriptOnlineEyeToggle = eId => {

        },

        onSyncVideoSelect = videoUrl => {

        },

        isForceTab = false,
        forceTab = undefined,

        canEdit = true,
        tasks = [],
        onTaskChange = task => {

        },

        loading = false,

        onTaskSelect = taskId => {

        },
        onTaskDelete = taskId => {

        },

        onTaskAdd = d => {

        },

        selectedTaskId,
        onTaskToggle = lId => {

        },
        onSelectedTaskTabChange = tb => {

        },
        showMoveToMeButton = false,
        onMoveToBlockClick = divId => {

        },
        onTaskCopiedToModule = () => {

        },
        currentUserId
    } = props;

    console.log('LessonTasksEditor: currentUserId = ', currentUserId);

    const onToggle = useCallback((a) => {
        if (a.id == selectedTaskId) {
            onTaskSelect(undefined);
            onTaskToggle(null);
        } else {
            onTaskSelect(a.id);
            onTaskToggle(a?.id);
        }
    });

    const [editTaskId, setEditTaskId] = useState(undefined);
    const [deleteTaskId, setDeleteTaskId] = useState(undefined);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);

    const [cloningName, setCloningName] = useState('');

    const [cloneTaskId, setCloneTaskId] = useState();

    const selectedEditTask = (editTaskId == undefined) ? undefined : tasks.filter(xx => (xx.id == editTaskId))[0];
    const selectedCloneTask = (cloneTaskId == undefined) ? undefined : tasks.filter(xx => (xx.id == cloneTaskId))[0];
    let sortedTasks = tasks.sort((a, b) => (+a.timestamp - +b.timestamp));

    // console.log('LessonTasksEditor: render: props = ', props);
    let useTranslatableTranscript = (props?.user?.userRole == 'student');

    // console.log('render: sortedTasks = ', sortedTasks);


    return (
        <Wrapper>

            {(tasks.length > 0 || canEdit == false) ? null :
                <div style={{paddingBottom: 20}}>
                    <NoTasksLottiePlaceholder>
                        <LottieLoader
                            json={no_tasks_lottie}
                        />
                    </NoTasksLottiePlaceholder>
                    <AddButtonWrapper>
                        <div style={{marginBottom: 20, marginTop: -20}}>
                            В этом уроке еще нет заданий
                        </div>
                        <YellowButton onClick={() => {
                            setAddModalVisible(true);
                        }}>
                            Создать первое задание
                        </YellowButton>
                    </AddButtonWrapper>
                </div>
            }

            <TasksListPlaceholder>
                {sortedTasks.map((a, i) => {
                    let isSelected = (selectedTaskId == a.id);
                    let {description = '', requestsAttachment = false} = a;
                    let deadlineClassName = UIHelper.getDeadlineClassName(a.deadlineTimestamp);
                    return (
                        <TaskItem key={a.id}>
                            <TaskItemTopPlaceholder
                                selected={isSelected}
                                style={{borderBottom: (isSelected) ? `1px solid whitesmoke` : 'none'}}
                            >
                                <TaskItemInfo
                                    onClick={() => {
                                        onToggle(a);
                                    }}
                                >
                                    <div>
                                        {`${i + 1}) `}
                                        {(a?.name == undefined || a?.name == '') ? 'Без названия' : a.name}
                                    </div>
                                    {a.deadlineTimestamp == undefined ? null :
                                        <div style={{textAlign: 'left'}}>
                                             <span className={deadlineClassName}
                                                   style={{marginLeft: 7, fontSize: '12px', fontStyle: 'italic'}}>
                                                {`deadline ${moment(a.deadlineTimestamp).format('DD.MM.YYYY HH:mm')}`}
                                            </span>
                                        </div>
                                    }
                                </TaskItemInfo>

                                <TaskItemControlsPlaceholder>

                                    <span style={{marginRight: 25}}>
                                        <Spin visible={loading && isSelected}/>
                                    </span>

                                    {canEdit == false ? null :
                                        <ChevronIcon
                                            src={save_icon}
                                            style={{
                                                marginRight: 20,
                                                width: 22,
                                                height: 22
                                            }}
                                            onClick={() => {
                                                setCloneTaskId(a.id);
                                                let tsk = tasks.filter(xx => (xx.id == a.id))[0];
                                                setCloningName(tsk?.name || '');
                                            }}
                                        />
                                    }

                                    {canEdit == false ? null :
                                        <ChevronIcon
                                            src={edit_icon_black}
                                            style={{
                                                marginRight: 20
                                            }}
                                            onClick={() => {
                                                setEditTaskId(a.id);
                                            }}
                                        />
                                    }


                                    <ChevronIcon
                                        src={chevron_icon}
                                        style={{
                                            transform: `rotate(${isSelected == true ? 180 : 0}deg)`
                                        }}
                                        onClick={() => {
                                            onToggle(a);
                                        }}
                                    />

                                </TaskItemControlsPlaceholder>
                            </TaskItemTopPlaceholder>

                            {isSelected == false ? null :
                                <TaskItemContentPlaceholder>

                                    {description == '' ? null :
                                        <TaskItemHtml>
                                            <div
                                                dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}>
                                            </div>
                                        </TaskItemHtml>
                                    }

                                    {requestsAttachment != true ? null :
                                        <div>
                                            <SimpleStudentLessonTaskFileUploaderPanel
                                                editingMode={canEdit}
                                                taskId={a.id}
                                                studentId={props?.userId}
                                                fileNamePrefix={props?.user?.email == undefined ? `task_${props?.userId}` : props?.user?.email.split('@')[0]}
                                            />
                                        </div>
                                    }

                                    <TaskEditor
                                        isOnlineMode={isOnlineMode}
                                        groupId={groupId}
                                        onlineHiddenItemsIds={onlineHiddenItemsIds}
                                        buffer={buffer}
                                        selectedTaskId={selectedTaskId}
                                        isForceTab={isForceTab}
                                        forceTab={forceTab}
                                        canEdit={canEdit}
                                        canPasteIntoItem={true}
                                        {...props}
                                        {...a}
                                        onChange={d => {
                                            onTaskChange({
                                                ...d,
                                                id: a.id
                                            })
                                        }}
                                        onTabChange={tb => {
                                            if (isForceTab) {
                                                onSelectedTaskTabChange(tb);
                                            }
                                        }}
                                        onOnlineEyeToggle={eId => {
                                            onOnlineEyeToggle(eId);
                                        }}
                                        onTranscriptOnlineEyeToggle={eId => {
                                            onTranscriptOnlineEyeToggle(eId);
                                        }}
                                        onSyncVideoSelect={videoUrl => {
                                            onSyncVideoSelect(videoUrl);
                                        }}
                                        showMoveToMeButton={showMoveToMeButton}
                                        onMoveToBlockClick={divId => {
                                            onMoveToBlockClick(divId);
                                        }}
                                    />
                                </TaskItemContentPlaceholder>
                            }
                        </TaskItem>
                    )
                })}
            </TasksListPlaceholder>

            {(tasks.length == 0 || canEdit == false) ? null :
                <BottomAddButtonPlaceholder>
                    <YellowButton onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        {`Создать задание №${tasks.length + 1}`}
                    </YellowButton>
                </BottomAddButtonPlaceholder>
            }


            {deleteTaskId == undefined ? null :
                <NiceConfirm
                    confirmText={'Подтвердить удаление'}
                    cancelText={'Отмена'}
                    heading={'Удаление задания'}
                    subHeading={'Подтвердите, что вы хотите удалить задание'}
                    onCancel={() => {
                        setDeleteTaskId(undefined);
                    }}
                    onConfirm={() => {
                        setDeleteTaskId(undefined);
                        onTaskDelete(deleteTaskId);
                    }}

                >
                </NiceConfirm>
            }

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>
                    <AddModalInnerPlaceholder>
                        <UpdateTaskForm
                            loading={loading}
                            onSave={d => {
                                onTaskAdd(d);
                                setAddModalVisible(false);
                            }}
                        />
                    </AddModalInnerPlaceholder>
                </NiceModal>
            }

            {selectedEditTask == undefined ? null :
                <NiceModal onClose={() => {
                    console.log('-->> onClose');
                    setEditTaskId(undefined);
                }}>
                    <AddModalInnerPlaceholder mode={'edit'}>

                        <UpdateTaskForm
                            {...selectedEditTask}
                            hasExtraInfo={true}
                            loading={loading}
                            canDelete={true}
                            onDelete={() => {
                                onTaskDelete(selectedEditTask.id);
                                setEditTaskId(undefined);
                            }}
                            onSave={d => {
                                console.log('onSave: selectedEditTask = ', selectedEditTask);
                                onTaskChange({
                                    ...d,
                                    id: selectedEditTask?.id
                                });
                                setEditTaskId(undefined);
                            }}
                        />
                    </AddModalInnerPlaceholder>
                </NiceModal>
            }

            {selectedCloneTask == undefined ? null :
                <NiceModal onClose={() => {
                    console.log('-->> onClose');
                    setCloneTaskId(undefined);
                }}>
                    <FolderSelectorInnerPlaceholder>

                        <InnerHeading>
                            Укажите название и выберите в какую папку вы хотите сохранить модуль.
                        </InnerHeading>

                        <CloningNamePlaceholder>
                            <Input value={cloningName} placeholder={translate('title')} onChange={evt => {
                                setCloningName(evt.target.value);
                            }}/>
                        </CloningNamePlaceholder>

                        <PrivateContainerFolderSelectorPanel
                            onFolderSelect={async cId => {
                                if (localLoading == true) {
                                    return;
                                }
                                setLocalLoading(true);
                                await SlidesAPI.createSlideFromTaskAndAddItToContainer(selectedCloneTask?.id, cId, cloningName);
                                onTaskCopiedToModule();
                                setLocalLoading(false);
                                toast('Модуль добавлен в "Моё"');
                                setCloneTaskId(undefined);

                            }}
                        />

                    </FolderSelectorInnerPlaceholder>

                </NiceModal>
            }


        </Wrapper>
    );
}

const CloningNamePlaceholder = styled.div`
  padding: 2px;
  box-sizing: border-box;
  margin-top: 5px;
`;

const TaskItemHtml = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px dashed whitesmoke;
  margin-bottom: 10px;
`;

const FolderSelectorInnerPlaceholder = styled.div`
  width: 420px;
  max-height: calc(80vh - 20px);
  overflow-y: auto;
`;

const InnerHeading = styled.div`

`;

const Wrapper = styled.div`

`;

const AddModalInnerPlaceholder = styled.div`
  width: ${props => (props.mode == 'edit' ? 520 : 420)}px;
  max-height: calc(90vh - 40px);
  overflow-y: auto;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

const BottomAddButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoTasksLottiePlaceholder = styled.div`
  width: 100%;
  height: 340px;
`;

const DeleteIcon = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.3;

  :hover {
    opacity: 0.85;
  }
`;

const ChevronIcon = styled.img`
  cursor: pointer;
  width: 28px;
  height: 28px;
  opacity: 0.3;

  :hover {
    opacity: 0.85;
  }
`;


const TasksListPlaceholder = styled.div`

`;

const TaskItem = styled.div`
  background: white;
  border-bottom: 1px solid whitesmoke;
`;

const TaskItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: ${props => (props.selected == true ? '#fcfcfc' : 'white')};
`;

const TaskItemInfo = styled.div`
  cursor: pointer;
  flex: 1;
  margin-right: 20px;

  :hover {
    opacity: 0.8;
  }
`;


const TaskItemControlsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const TaskItemContentPlaceholder = styled.div`
  padding: 15px;
`;