import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from "redux-react-hook";

import * as actions from '../../../redux/actions/LessonsActions'
import * as tasksActions from '../../../redux/actions/TasksActions'
import * as containersActions from '../../../redux/actions/ContainersActions'

import NiceModal from "../../modals/NiceModal";
import UpdateLessonForm from "../forms/UpdateLessonForm";
import LottieLoader from "../../loaders/LottieLoader";

import LessonsList from "../tools/LessonsList";

import team_json from '../../../assets/lottie/team-discussion.json'
import * as slidesActions from "../../../redux/actions/SlidesActions";

export default function StudentGroupLessonsPanel(props) {
    const {
        id,
        studentId
    } = props;

    const {
        lessons,
        loading,
        selectedSlideId,

        selectedLessonId,
        selectedTaskId,
        selectedLessonTasks,
        tasksLoading,
        lessonsLoading,
        selectedTask,
        currentUser
    } = useMappedState(useCallback(state => {
        let uId = studentId || state.users.currentUserId;
        return {
            currentUser: state.users.usersMap.get(uId),
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x?.classId == id)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            loading: state.lessons.loading,
            selectedSlideId: state.slides.selectedSlideId,

            tasksLoading: state.tasks.loading,
            lessonsLoading: state.lessons.loading,

            selectedLessonId: state.lessons.selectedLessonId,
            selectedTaskId: state.tasks.selectedTaskId,
            selectedTask: state.tasks.tasksMap.get(state.tasks.selectedTaskId),
            selectedLessonTasks: state.tasks.tasksMap.toArray().filter(x => (x.lessonId == state.lessons.selectedLessonId))
        }
    }));

    const dispatch = useDispatch();
    const [addModalVisible, setAddModalVisible] = useState(false);

    useEffect(() => {
        dispatch(actions.getGroupLessons(id));

    }, [id]);

    useEffect(() => {
        if (selectedLessonId != undefined) {
            dispatch(tasksActions.getLessonTasks(selectedLessonId));
        }
    }, [selectedLessonId]);

    let isTeacher = (currentUser?.userRole == 'teacher');

    let xLessons = lessons;
    if (isTeacher == false && window.location.href.indexOf('ru-tsu') > -1) {
        xLessons = xLessons.filter(x => (+x.startTimestamp < +new Date()));
    }

    return (
        <Wrapper>

            {!(lessons.length == 0 && loading == false) ? null :
                <LottiePlaceholder>
                    <LottieLoader
                        json={team_json}
                    />
                    <div style={{textAlign: 'center', opacity: 0.5}}>
                        В этой группе ещё нет уроков.
                        <br/>
                        Преподаватель создаст их в ближайшее время.
                    </div>
                </LottiePlaceholder>
            }


            <RightPlaceholder style={{overflowY: isTeacher == false ? 'auto' : 'hidden'}}>

                <LessonsListPlaceholder
                    width={'min(760px, calc(100vw - 300px))'}
                >
                    <LessonsList
                        canEditLessons={false}
                        userId={currentUser?.id}
                        user={currentUser}
                        loading={(lessonsLoading || tasksLoading)}
                        lessonsLoading={lessonsLoading}
                        tasksLoading={tasksLoading}
                        lessons={xLessons}
                        selectedTaskId={selectedTaskId}
                        selectedLessonId={selectedLessonId}
                        tasks={selectedLessonTasks}
                        onTaskSelect={id => {
                            dispatch(tasksActions.selectTask(id));
                        }}
                        onLessonSelect={lId => {
                            dispatch(actions.selectLesson(lId));
                        }}
                    />
                </LessonsListPlaceholder>

                {addModalVisible == false ? null :
                    <NiceModal onClose={() => {
                        setAddModalVisible(false);
                    }}>

                        <CreateInnerModal>
                            <UpdateLessonForm
                                loading={loading}
                                onSave={async d => {
                                    await dispatch(actions.createLesson(id, d));
                                    setAddModalVisible(false);
                                }}
                            />
                        </CreateInnerModal>

                    </NiceModal>
                }
            </RightPlaceholder>


        </Wrapper>
    );
}

const LottiePlaceholder = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectedSlideTopContent = styled.div`
  background: whitesmoke;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
`;

const LessonsListPlaceholder = styled.div`
  width: ${props => props.width};
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const NoLessonsMessagePlaceholder = styled.div`
  text-align: center;
  margin-bottom: 25px;
  margin-top: -60px;
`;

const NoLessonsPlaceholder = styled.div`
  width: 100%;
  height: calc(80vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CreateInnerModal = styled.div`
  width: 420px;
  padding: 10px;
`;

const Wrapper = styled.div`

`;

const LeftPlaceholder = styled.div`
  height: calc(100vh - 70px);
  box-sizing: border-box;
  background: white;
  border-top: 1px solid lightgrey;
  width: ${props => props.width};
`;

const RightPlaceholder = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-top: 20px;
  max-height: calc(100vh - 70px);

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const LessonItem = styled.div`
  margin-bottom: 10px;
`;