import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {GreenButton} from "../ira/ui/Buttons";
import MosesPlayer from "./MosesPlayer";

import closeImg from '../../assets/img/close_x.svg'
import {Textarea} from "../ira/ui/Inputs";

export default function MosesEditorTool(props) {
    const {
        url,
        dt = 0.1,
        hasText = false,
        onChange = newIntervals => {

        }
    } = props;

    const [duration, setDuration] = useState(undefined);
    const [intervals, setIntervals] = useState(props.intervals || []);

    return (
        <Wrapper>

            <MosesPanelPlaceholder>
                <MosesPlayer url={url}
                             onLoaded={d => {
                                 console.log('onDurationLoaded: d = ', d);
                                 setDuration(d);
                             }}
                             fragments={intervals.map(x => ({from: +x.from, to: +x.to}))}/>
            </MosesPanelPlaceholder>

            {duration == undefined ? null :
                <ControlsPlaceholder>

                    <ItemsList>
                        {intervals.map((interval, k) => {
                            let {from, to, text = ''} = interval;
                            let seconds = +to - +from;
                            let secString = `${Math.round(seconds * 10.0) / 10.0}`;
                            let gs = f => `${Math.round(f * 10.0) / 10.0}`;
                            return (
                                <Item key={`${k}_${from}_${to}`}>
                                    <Half>
                                        <ArrowButtonPlaceholder onClick={() => {
                                            let newFrom = Math.max(+from - dt, 0);
                                            let newInts = JSON.parse(JSON.stringify(intervals));
                                            newInts[k].from = newFrom;
                                            setIntervals(newInts);
                                            onChange(newInts);
                                        }}>
                                            {'<<<'}
                                        </ArrowButtonPlaceholder>
                                        <IntervalInputPlaceholder>
                                            <IntervalInput value={`${gs(from)}`} onChange={evt => {
                                                let newInts = JSON.parse(JSON.stringify(intervals));
                                                newInts[k].from = evt.target.value;
                                                setIntervals(newInts);
                                                onChange(newInts);
                                            }}/>
                                        </IntervalInputPlaceholder>
                                        <ArrowButtonPlaceholder onClick={() => {
                                            let newFrom = Math.min(+from + dt, +to - dt);
                                            let newInts = JSON.parse(JSON.stringify(intervals));
                                            newInts[k].from = newFrom;
                                            setIntervals(newInts);
                                            onChange(newInts);
                                        }}>
                                            {'>>>'}
                                        </ArrowButtonPlaceholder>
                                    </Half>
                                    <Half>
                                        <ArrowButtonPlaceholder onClick={() => {
                                            let newTo = Math.max(+to - dt, 0);
                                            let newInts = JSON.parse(JSON.stringify(intervals));
                                            newInts[k].to = newTo;
                                            setIntervals(newInts);
                                            onChange(newInts);
                                        }}>
                                            {'<<<'}
                                        </ArrowButtonPlaceholder>
                                        <IntervalInputPlaceholder>
                                            <IntervalInput value={`${gs(to)}`} onChange={evt => {
                                                let newInts = JSON.parse(JSON.stringify(intervals));
                                                newInts[k].to = evt.target.value;
                                                setIntervals(newInts);
                                                onChange(newInts);
                                            }}/>
                                        </IntervalInputPlaceholder>
                                        <ArrowButtonPlaceholder onClick={() => {
                                            let newTo = Math.min(+to + dt, +duration);
                                            let newInts = JSON.parse(JSON.stringify(intervals));
                                            newInts[k].to = newTo;
                                            setIntervals(newInts);
                                            onChange(newInts);
                                        }}>
                                            {'>>>'}
                                        </ArrowButtonPlaceholder>
                                    </Half>

                                    {hasText == false ? null :
                                        <Half >
                                            <Textarea value={text} onChange={evt => {
                                                let s = evt.target.value;
                                                let newInts = JSON.parse(JSON.stringify(intervals));
                                                newInts[k].text = s;
                                                setIntervals(newInts);
                                                onChange(newInts);
                                            }}/>
                                        </Half>
                                    }

                                    <RightItemPlaceholder>

                                        <DeleteImage src={closeImg}
                                                     onClick={() => {
                                                         if (window.confirm('Вы уверены?') == false) {
                                                             return;
                                                         }
                                                         setIntervals(intervals.filter((a, i) => (i != k)));
                                                         onChange(intervals.filter((a, i) => (i != k)));
                                                     }}/>
                                    </RightItemPlaceholder>
                                </Item>
                            )
                        })}
                    </ItemsList>

                    <AddButtonPlaceholder>
                        <GreenButton shouldFitContainer={true} onClick={() => {
                            if (intervals.length == 0) {
                                setIntervals([{from: 0, to: duration, id: `t${+new Date()}`}]);
                            } else {
                                if (Math.abs(+intervals[intervals.length - 1].to - +duration) < 0.1) {
                                    return;
                                }
                                let newIntervals = intervals.concat({
                                    from: intervals[intervals.length - 1].to,
                                    to: duration,
                                    id: `t${+new Date()}`
                                });
                                setIntervals(newIntervals);
                                onChange(newIntervals);
                            }
                        }}>
                            Добавить интервал
                        </GreenButton>
                    </AddButtonPlaceholder>

                </ControlsPlaceholder>
            }

        </Wrapper>
    );
}


const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
`;

const MosesPanelPlaceholder = styled.div`
  height: 420px;
  width: 100%;
  background-color: black;
`;

const ControlsPlaceholder = styled.div`

`;

const AddButtonPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

const ItemsList = styled.div`

`;

const Item = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const arrowButtonWidth = 80;

const ArrowButtonPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${arrowButtonWidth}px;
  box-sizing: border-box;
  text-align: center;
  background-color: whitesmoke;
  cursor: pointer;
  line-height: 32px;

  :hover {
    background-color: floralwhite;
  }
`;

const IntervalInputPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - ${2 * arrowButtonWidth}px);
  box-sizing: border-box;
  text-align: center;
`;

const IntervalInput = styled.input`
  box-sizing: border-box;
  outline: none;
  border: 1px solid whitesmoke;
  border-radius: 3px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 18px;

  :focus {
    border-color: blue;
  }
`;

const Half = styled.div`
  display: inline-block;
  vertical-align: top;
  flex: 1;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
`;

const rightWidth = 50;

const LeftItemPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - ${rightWidth}px);
  box-sizing: border-box;
`;

const RightItemPlaceholder = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${rightWidth}px;
  box-sizing: border-box;
  text-align: center;
  line-height: 32px;
`;


const DeleteImage = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  vertical-align: middle;
  opacity: 0.5;

  :hover {
    opacity: 0.9;
  }
`;