import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useMappedState} from "redux-react-hook";

import Spin from 'arui-feather/spin'

import * as actions from '../../../redux/actions/LessonsActions'
import * as tasksActions from '../../../redux/actions/TasksActions'
import * as selectorActions from '../../../redux/actions/CoursesLessonSelectorActions'

import {BlueButton, YellowButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import UpdateLessonForm from "../forms/UpdateLessonForm";
import CoursesViewPanel from "../../courses/panels/CoursesViewPanel";
import LottieLoader from "../../loaders/LottieLoader";

import no_lessons from '../../../assets/lottie/no_lessons.json'
import LessonsList from "../tools/LessonsList";
import * as slidesActions from "../../../redux/actions/SlidesActions";
import * as containersActions from "../../../redux/actions/ContainersActions";
import {translate} from "../../../utils/Translate";
import {isMobile} from "polotno/utils/screen";
import select_lottie_json from "../../../assets/lottie/select_slide_lottie.json";

export default function TeacherGroupLessonsPanel(props) {
    const {
        id
    } = props;

    const {
        lessons,
        loading,
        selectedSlideId,

        selectedLessonId,
        selectedTaskId,
        selectedLessonTasks,
        tasksLoading,
        lessonsLoading,
        selectedTask,
        currentUser,
        buffer,
        selectedSlide,
        currentUserId,
        lessonsPanelState,
        leftPanelState,
        middlePanelState,
        rightPanelState
    } = useMappedState(useCallback(state => {
        return {
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            lessons: state.lessons.lessonsMap.toArray().filter(x => (x?.classId == id)).sort((a, b) => (+b.timestamp - +a.timestamp)),
            loading: state.lessons.loading,
            selectedSlideId: state.slides.selectedSlideId,
            selectedSlide: state.slides.slidesMap.get(state.slides.selectedSlideId),

            tasksLoading: state.tasks.loading,
            lessonsLoading: state.lessons.loading,

            selectedLessonId: state.lessons.selectedLessonId,
            selectedTaskId: state.tasks.selectedTaskId,
            selectedTask: state.tasks.tasksMap.get(state.tasks.selectedTaskId),
            selectedLessonTasks: state.tasks.tasksMap.toArray().filter(x => (x.lessonId == state.lessons.selectedLessonId)),

            buffer: state.buffer,
            lessonsPanelState: state.pageSelector.lessonsPanelState,
            leftPanelState: state.pageSelector.leftPanelState,
            middlePanelState: state.pageSelector.middlePanelState,
            rightPanelState: state.pageSelector.rightPanelState
        }
    }));

    const dispatch = useDispatch();
    const [addModalVisible, setAddModalVisible] = useState(false);

    useEffect(() => {
        dispatch(actions.getGroupLessons(id));
    }, [id]);

    useEffect(() => {
        dispatch(selectorActions.showPanelSelector('lessons'));
        dispatch(selectorActions.showPanel('left'));
    }, []);

    useEffect(() => {
        if (selectedLessonId != undefined) {
            dispatch(tasksActions.getLessonTasks(selectedLessonId));
        }
    }, [selectedLessonId]);

    let canSelectSlide = !((selectedSlide?.isLimbo == true) && (selectedSlide?.allowCloning != true) && (selectedSlide?.userId != currentUserId));
    console.log('render: selectedSlide = ', selectedSlide);
    console.log('render: canSelectSlide = ', canSelectSlide);


    return (
        <Wrapper>

                <PanelSelector style={{display: (lessonsPanelState == false) || window.innerWidth >= 720 ? 'none' : 'flex'}}>

                    <PanelVisibleButton
                        style={{/*position: 'absolute',*/
                                    marginRight: '10px',
                               /*     paddingTop: '0%',*/
                                    display: window.innerWidth >= 720 ? 'none' : 'block',
                                    flexDirection: 'row',
                                    transform: leftPanelState == true ? 'scale(1.25)' : 'scale(0.8)'
                                }}
                        onClick={() => {
                                    if (middlePanelState == true || rightPanelState == true) {
                                        dispatch(selectorActions.showPanel('left'));
                                    }
                                }}>
                                {leftPanelState == true ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="10" fill="#FFA71B"/>
                                    </svg> :
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="9" stroke="#FFA71B" stroke-width="2"/>
                                    </svg>
                                }

                            </PanelVisibleButton>

                    <PanelVisibleButton
                        style = {{/*position: 'absolute',*/
                                    marginRight: '10px',
                                    //paddingTop: '1%',
                                    display: window.innerWidth <= 720 ? 'block' : 'none',
                                    transform: middlePanelState == true ? 'scale(1.25)' : 'scale(0.8)',
                                    pointer: 'none'}}
                        onClick={() => {
                                    if (leftPanelState == true || rightPanelState == true) {
                                        dispatch(selectorActions.showPanel('middle'));
                                    }
                                }}>

                                {middlePanelState == true ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="10" fill="#FFA71B"/>
                                    </svg> :
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="9" stroke="#FFA71B" stroke-width="2"/>
                                    </svg>
                                }
                            </PanelVisibleButton>

                    <PanelVisibleButton
                        style = {{/*position: 'absolute',*/
                                    /*marginLeft: '20%',*/
                                    //paddingTop: '1%',
                                    display: window.innerWidth <= 720 ? 'block' : 'none',
                                    transform: rightPanelState == true ? 'scale(1.25)' : 'scale(0.8)',
                                    pointer: 'none'}}
                        onClick={() => {
                                    if (leftPanelState == true || middlePanelState == true) {
                                        dispatch(selectorActions.showPanel('right'));
                                    }
                                }}>

                                {rightPanelState == true ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="10" fill="#FFA71B"/>
                                    </svg> :
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10" r="9" stroke="#FFA71B" stroke-width="2"/>
                                    </svg>
                                }

                            </PanelVisibleButton>

                </PanelSelector>

                <LeftPlaceholder
                    width={(selectedSlideId == undefined) && window.innerWidth >= 720 ? '300px' :
                        (selectedSlideId != undefined) && window.innerWidth >= 720 ? 'calc((100vw - 300px) * 0.45 + 300px)' :
                            '100%'
                    }
                    style={{display: (rightPanelState == false) || window.innerWidth >= 720 ? 'block' : 'none'}}>

                    <NotSelectedPlaceholder style={{display: selectedSlideId == undefined && middlePanelState && isMobile() ? 'block' : 'none'}}>
                        <LottieLoader
                            json={select_lottie_json}
                        />
                        <div>
                            {translate('select_module_from_the_left_list')}
                        </div>
                    </NotSelectedPlaceholder>

                    <CoursesViewPanel
                        canCopyToBuffer={true}
                        hasTopInfoBar={true}
                        showRightIfNotSelected={false}
                        leftWidth={window.innerWidth >= 720 ? '300px' : '100%'}
                        height={`calc(100vh - 70px)`}
                        selectedSlideTopContent={(
                            <>

                                {selectedTask == undefined || (selectedTask?.items != undefined && selectedTask?.items.length > 0) || (canSelectSlide == false) ? null :
                                    <SelectedSlideTopContent>

                                        <YellowButton onClick={async () => {
                                            if (tasksLoading || lessonsLoading) {
                                                return;
                                            }
                                            await dispatch(tasksActions.copyDataToTaskFromSlide(selectedTaskId, selectedSlideId));
                                        }}>
                                            <Spin
                                                theme={'alfa-on-color'}
                                                visible={tasksLoading || lessonsLoading}/>
                                            {tasksLoading || lessonsLoading ? null : 'Выбрать'}
                                        </YellowButton>
                                    </SelectedSlideTopContent>
                                }
                            </>
                        )}
                    />
                </LeftPlaceholder>

                <RightPlaceholder style={{display: (rightPanelState == true) || window.innerWidth >= 720 ? 'block' : 'none'}}>

                {loading == true ? null :
                    <React.Fragment>

                        {lessons.length > 0 ? null :
                            <NoLessonsPlaceholder>
                                <div style={{flex: 1, width: '100%'}}>
                                    <LottieLoader
                                        json={no_lessons}
                                    />
                                </div>
                                <NoLessonsMessagePlaceholder>
                                    В той группе еще не было уроков.
                                </NoLessonsMessagePlaceholder>
                            </NoLessonsPlaceholder>
                        }

                        <AddPlaceholder>
                            <BlueButton onClick={() => {
                                setAddModalVisible(true);
                            }}>
                                {lessons.length == 0 ? translate('add_first_lesson') : translate('add_lesson')}
                            </BlueButton>
                        </AddPlaceholder>
                    </React.Fragment>
                }

                <LessonsListPlaceholder
                    width={(selectedSlideId != undefined && window.innerWidth > 720 ? `calc(100vw - 300px) * 0.55` :
                        (selectedSlideId == undefined) && window.innerWidth > 720 ? `min(760px, calc(100vw - 300px))` :
                            '95%' )}
                >
                    <LessonsList
                        buffer={buffer}
                        groupId={id}
                        userId={currentUser?.id}
                        loading={(lessonsLoading || tasksLoading)}
                        lessonsLoading={lessonsLoading}
                        tasksLoading={tasksLoading}
                        lessons={lessons}
                        selectedTaskId={selectedTaskId}
                        selectedLessonId={selectedLessonId}
                        tasks={selectedLessonTasks}
                        onTaskAdd={async d => {
                            let data = {...d};
                            await dispatch(tasksActions.createTask(selectedLessonId, data));
                        }}
                        onTaskChange={async d => {
                            await dispatch(tasksActions.updateTask(d));
                        }}
                        onTaskDelete={async id => {
                            await dispatch(tasksActions.deleteTask(id));
                        }}
                        onTaskSelect={id => {
                            dispatch(tasksActions.selectTask(id));
                        }}
                        onLessonSelect={lId => {
                            dispatch(actions.selectLesson(lId));
                        }}
                        onLessonDelete={lId => {
                            dispatch(actions.deleteLesson(lId));
                        }}
                        onLessonChange={d => {
                            dispatch(actions.updateLesson(d));
                        }}
                        onTaskCopiedToModule={async d => {
                            console.log('xxx onTaskCopiedToModule');
                            await dispatch(slidesActions.loadSlides());
                            await dispatch(containersActions.getMyAllCompactContainers());
                        }}

                    />
                </LessonsListPlaceholder>

                {addModalVisible == false ? null :
                    <NiceModal onClose={() => {
                        setAddModalVisible(false);
                    }}>

                        <CreateInnerModal>
                            <UpdateLessonForm
                                loading={loading}
                                onSave={async d => {
                                    await dispatch(actions.createLesson(id, d));
                                    setAddModalVisible(false);
                                }}
                            />
                        </CreateInnerModal>

                    </NiceModal>
                }
            </RightPlaceholder>

        </Wrapper>
    );
}

const SelectedSlideTopContent = styled.div`
  background: whitesmoke;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;

  @media (max-width: 720px){
    padding-top: 45px;
  }
`;

const LessonsListPlaceholder = styled.div`
  width: ${props => props.width};
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  box-sizing: border-box;
`;

const NoLessonsMessagePlaceholder = styled.div`
  text-align: center;
  margin-bottom: 25px;
  margin-top: -60px;
`;

const NoLessonsPlaceholder = styled.div`
  width: 100%;
  height: calc(80vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CreateInnerModal = styled.div`
  width: 420px;
  padding: 10px;
  
  @media (max-width: 720px){
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  @media (max-width: 720px){
    flex-direction: column;
  }
`;

const PanelSelector = styled.div`
  padding-top: 4px;
  background: whitesmoke;
  height: 28px;
  width: 100%;
  //display: flex;
  justify-content: center;

/*  @media (min-width: 720px){
    display: none;
  }*/
`;

const LeftPlaceholder = styled.div`
  height: calc(100vh - 70px);
  box-sizing: border-box;
  //background: white;
  border-top: 1px solid lightgrey;
  width: ${props => props.width};
`;

const RightPlaceholder = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-top: 20px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  
  @media (max-width: 720px){
    width: 100%;
  }
`;

const LessonItem = styled.div`
  margin-bottom: 10px;
`;

const PanelVisibleButton = styled.div`
  z-index: 9;
`;

const NotSelectedPlaceholder = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 400px;
  text-align: center;
  padding-bottom: 20px;
`;

const MobileContains = styled.div`
    
`;