import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";
import './style.css'
import UsersAPI from "../../../api/UsersAPI";
import Histogram from "./Histogram";
import Sidebar from "arui-feather/sidebar";
import DateTimePicker from "../../ui/DateTimePicker";
import { translate } from '../../../utils/Translate';
// import { mean, median, mode, std, variance } from 'mathjs';
// Mean
const calculateMean = (data) => {
    if (data.length === 0) return 0;
    const sum = data.reduce((acc, value) => acc + value, 0);
    return sum / data.length;
};

// Median
const calculateMedian = (data) => {
    if (data.length === 0) return 0;
    const sortedData = [...data].sort((a, b) => a - b);
    const mid = Math.floor(sortedData.length / 2);
    return sortedData.length % 2 !== 0 ? sortedData[mid] : (sortedData[mid - 1] + sortedData[mid]) / 2;
};

// Mode
const calculateMode = (data) => {
    if (data.length === 0) return [];
    const modeMap = {};
    let maxCount = 1, modes = [data[0]];

    data.forEach(num => {
        if (!modeMap[num]) modeMap[num] = 1;
        else modeMap[num]++;

        if (modeMap[num] > maxCount) {
            modes = [num];
            maxCount = modeMap[num];
        } else if (modeMap[num] === maxCount) {
            modes.push(num);
            maxCount = modeMap[num];
        }
    });

    return modes;
};

// Range
const calculateRange = (data) => {
    if (data.length === 0) return 0;
    const sortedData = [...data].sort((a, b) => a - b);
    return sortedData[sortedData.length - 1] - sortedData[0];
};

// Interquartile Range
const calculateInterquartileRange = (data) => {
    if (data.length === 0) return 0;
    const sortedData = [...data].sort((a, b) => a - b);
    const q1 = sortedData[Math.floor((sortedData.length / 4))];
    const q3 = sortedData[Math.ceil((sortedData.length * (3 / 4)))];
    return q3 - q1;
};

// Standard Deviation
const calculateStandardDeviation = (data) => {
    if (data.length === 0) return 0;
    const mean = calculateMean(data);
    const squareDiffs = data.map(value => {
        const diff = value - mean;
        return diff * diff;
    });
    const avgSquareDiff = calculateMean(squareDiffs);
    return Math.sqrt(avgSquareDiff);
};

// Variance
const calculateVariance = (data) => {
    if (data.length === 0) return 0;
    const mean = calculateMean(data);
    const squareDiffs = data.map(value => {
        const diff = value - mean;
        return diff * diff;
    });
    return calculateMean(squareDiffs);
};

function getFilteredTeachers(teachers, from, to) {
    return teachers.map(teacher => {
        let filteredTeacher = {...teacher};
        filteredTeacher.teacherStats = {...teacher.teacherStats};
        Object.keys(filteredTeacher.teacherStats).forEach(key => {
            filteredTeacher.teacherStats[key].byDay = Object.keys(filteredTeacher.teacherStats[key].byDay)
                .filter(day => {
                    const dayTimestamp = +moment(day).startOf('day');
                    return (dayTimestamp >= from && dayTimestamp <= to);
                })
                .reduce((acc, day) => {
                    acc[day] = filteredTeacher.teacherStats[key].byDay[day];
                    return acc;
                }, {});
        });
        // update .total value
        Object.keys(filteredTeacher.teacherStats).forEach(key => {
            filteredTeacher.teacherStats[key].total = Object.values(filteredTeacher.teacherStats[key].byDay).reduce((acc, value) => {
                return acc + value;
            }, 0);
        });
        return filteredTeacher;
    }).filter(x => {
        // filter if total value for all is 0
        let total = 0;
        Object.keys(x.teacherStats).forEach(key => {
            total += x.teacherStats[key].total;
        });
        if (total == 0) {
            return false;
        }
        return true;
    });
}


export default function TeachersStatsTool(props) {

    const [loading, setLoading] = useState(false);
    const [teachers, setTeachers] = useState([]);

    const [selectedId, setSelectedId] = useState(undefined);
    const [from, setFrom] = useState(+moment().add(-1, 'years').startOf('day'));
    const [to, setTo] = useState(+moment().add(1, 'days').startOf('day'));

    useEffect(() => {
        setLoading(true);
        UsersAPI.getAllTeachers().then(data => {
            setTeachers(data);
            setLoading(false);
        });
    }, [])

    if (teachers.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    let fTeachers = getFilteredTeachers(JSON.parse(JSON.stringify(teachers)), from, to);
    console.log('fTeachers = ', fTeachers);

    let teachersWithTotalItemsCreated = fTeachers.map(teacher => {
        let totalItemsCreated = 0;
        totalItemsCreated += +teacher?.teacherStats?.slides?.total;
        totalItemsCreated += +teacher?.teacherStats?.groups?.total;
        totalItemsCreated += +teacher?.teacherStats?.students?.total;
        totalItemsCreated += +teacher?.teacherStats?.exercises?.total;
        totalItemsCreated += +teacher?.teacherStats?.dialogs?.total;
        totalItemsCreated += +teacher?.teacherStats?.questionnaires?.total;
        totalItemsCreated += +teacher?.teacherStats?.treadmills?.total;
        totalItemsCreated += +teacher?.teacherStats?.drochka?.total;
        totalItemsCreated += +teacher?.teacherStats?.materials?.total;
        totalItemsCreated += +teacher?.teacherStats?.tests?.total;
        totalItemsCreated += +teacher?.teacherStats?.tasks?.total;
        return {
            ...teacher,
            totalItemsCreated
        }
    });

    teachersWithTotalItemsCreated = teachersWithTotalItemsCreated.sort((a, b) => {
        return b.totalItemsCreated - a.totalItemsCreated;
    });

    const prepareData = (key) => {
        return teachersWithTotalItemsCreated.map(teacher => ({
            id: teacher.id,
            number: (teacher?.teacherStats || {})[key]?.total || 0,
            byDay: (teacher?.teacherStats || {})[key]?.byDay || {}
        }));
    };

    const histograms = [
        {key: 'slides', title: 'Модули'},
        {key: 'groups', title: 'Группы'},
        {key: 'students', title: 'Студенты'},
        {key: 'exercises', title: 'Упражнения'},
        {key: 'dialogs', title: 'Диалоги'},
        {key: 'questionnaires', title: 'Опросники'},
        {key: 'treadmills', title: 'Тредмилы'},
        {key: 'drochka', title: 'Карточки'}
    ];

    let selectedTeacher = teachers.find(x => (x.id == selectedId));

    return (
        <Wrapper>

            <h2>{`Выберите диапазон дат`}</h2>

            <RangeSelectorPlaceholder>
                <DatePickerPlaceholder>
                    <DateTimePicker
                        timestamp={from}
                        onChange={x => {
                            if (+x > +to) {
                                return;
                            }
                            setFrom(x);
                        }}
                        showBottomSection={false}
                        hasTimePicker={false}
                    />
                </DatePickerPlaceholder>

                <DatePickerPlaceholder>
                    <DateTimePicker
                        timestamp={to}
                        onChange={x => {
                            if (+x < +from) {
                                return;
                            }
                            setTo(x);
                        }}
                        showBottomSection={false}
                        hasTimePicker={false}
                    />
                </DatePickerPlaceholder>


            </RangeSelectorPlaceholder>

            <InnerWrapper>

                <h2 style={{padding: 10}}>
                    {`Статистика за ${moment(from).format('DD.MM.YYYY')} - ${moment(to).format('DD.MM.YYYY')}`}
                </h2>

                <div className={'histogram-row'}>
                    {histograms.map((histogram, index) => {
                        const dataNumbers = prepareData(histogram.key).map(item => item.number);
                        const avg = calculateMean(dataNumbers);
                        const med = calculateMedian(dataNumbers);
                        const mod = calculateMode(dataNumbers);
                        const range = calculateRange(dataNumbers);
                        const iqr = calculateInterquartileRange(dataNumbers);
                        const standardDeviation = calculateStandardDeviation(dataNumbers);
                        const varia = calculateVariance(dataNumbers);


                        return (
                            <div key={index} className={'histogram-container'}>
                                <h2>{histogram.title}</h2>
                                <Histogram
                                    data={prepareData(histogram.key)}
                                    bins={50} // You can adjust the number of bins
                                    chartWidth={400}
                                    chartHeight={300}
                                />
                                <div className="statistics">
                                    <p>Average: {avg.toFixed(2)}</p>
                                    <p>Median: {med.toFixed(2)}</p>
                                    <p>Mode: {mod.join(', ')}</p>
                                    <p>Range: {range.toFixed(2)}</p>
                                    <p>Interquartile Range: {iqr.toFixed(2)}</p>
                                    <p>Standard Deviation: {standardDeviation.toFixed(2)}</p>
                                    <p>Variance: {varia.toFixed(2)}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <TableWrapper>

                    <h2>{`Статистика преподавателей (${fTeachers.length}) за период`}</h2>

                    <TablePlaceholder>
                        <table className={'student-table'}>
                            <thead>
                            <tr>
                                <th>Преподаватель</th>
                                <th>Модули</th>
                                <th>Группы</th>
                                <th>Студенты</th>
                                <th>Упражнения</th>
                                <th>Диалоги</th>
                                <th>Опросники</th>
                                <th>Тредмилы</th>
                                <th>Карточки</th>
                                <th>Видео</th>
                                <th>Тесты</th>

                            </tr>
                            </thead>
                            <tbody>
                            {teachersWithTotalItemsCreated.map((teacher, i) => {
                                return (
                                    <tr key={teacher.id}>
                                        <td style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                setSelectedId(teacher.id);
                                            }}
                                        >{CommonHelper.getUserName(teacher)}</td>
                                        <td>{teacher?.teacherStats?.slides?.total}</td>
                                        <td>{teacher?.teacherStats?.groups?.total}</td>
                                        <td>{teacher?.teacherStats?.students?.total}</td>
                                        <td>{teacher?.teacherStats?.exercises?.total}</td>
                                        <td>{teacher?.teacherStats?.dialogs?.total}</td>
                                        <td>{teacher?.teacherStats?.questionnaires?.total}</td>
                                        <td>{teacher?.teacherStats?.treadmills?.total}</td>
                                        <td>{teacher?.teacherStats?.drochka?.total}</td>
                                        <td>{teacher?.teacherStats?.materials?.total}</td>
                                        <td>{teacher?.teacherStats?.tests?.total}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </TablePlaceholder>

                </TableWrapper>


            </InnerWrapper>

            <Sidebar visible={(selectedTeacher != undefined)} onCloserClick={() => {
                setSelectedId(undefined);
            }}>

                {selectedTeacher == undefined ? null :
                    <div>
                        {/*draw here for each type the stats*/}
                        <h2>{`Статистика преподавателя ${CommonHelper.getUserName(selectedTeacher)}`}</h2>

                        <div>
                            {histograms.map((histogram, index) => {
                                // i need stats for this teacher - table day by day
                                let days = Object.keys(selectedTeacher.teacherStats[histogram.key].byDay).sort();
                                let data = days.map(day => {
                                    return {
                                        day,
                                        value: selectedTeacher.teacherStats[histogram.key].byDay[day]
                                    }
                                }).sort((a, b) => {
                                    return +moment(b.day) - +moment(a.day);
                                });
                                return (
                                    <div key={index} className={'histogram-container'}>
                                        <h2>{histogram.title}</h2>

                                        <div style={{maxHeight: '500px', overflowY: 'auto'}} >
                                            <table className={'student-table'} >
                                                <thead>
                                                <tr>
                                                    <th>{translate("date")}</th>
                                                    <th>Кол-во</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {data.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{moment(item.day).format('DD.MM.YYYY')}</td>
                                                            <td>{item.value}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>


                                    </div>
                                )
                            })}
                        </div>

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const RangeSelectorPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: white;
`;

const DatePickerPlaceholder = styled.div`
  height: 320px;
  width: 400px;
  margin: 10px;
`;

const TablePlaceholder = styled.div`
  max-height: calc(90vh - 120px);
  overflow-y: auto;
`;

const TableWrapper = styled.div`
  padding: 15px;
`;

const InnerWrapper = styled.div`
  background: white;
`;

const Wrapper = styled.div`
  padding: 20px;
`;
