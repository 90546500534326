import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import axios from 'axios'
import StudentsList from "../../students/list/StudentsList";
import CommonHelper from "../../../helpers/CommonHelper";
import NiceModal from "../../modals/NiceModal";
import {Textarea, Input} from "../../ira/ui/Inputs";
import {API_ENDPOINT} from "../../../constants/config";

import validator from 'validator'
import shareImg from '../../../assets/img/share.svg'

import * as actions from '../../../redux/actions/GroupsActions'
import * as usersActions from '../../../redux/actions/UsersActions'
import MoodleSelectUserPanel from "../../moodle/panels/MoodleSelectUserPanel";

import no_users_woman_lottie from '../../../assets/lottie/no_users_lottie.json'
import LottieLoader from "../../loaders/LottieLoader";
import {BlueButton} from "../../ira/ui/Buttons";
import {translate} from "../../../utils/Translate";

export default function GroupUsersPanel(props) {
    const {
        id
    } = props;

    const {
        group,
        loading,
        onUserAdded = () => {

        },
        usersMap
    } = useMappedState(useCallback(state => {
        return {
            loading: state.groups.loading,
            group: state.groups.groupsMap.get(id),
            usersMap: state.users.usersMap
        }
    }));
    const bulkEmailsInput = useRef();
    const [localLoading, setLocalLoading] = useState(false);

    useEffect(() => {
        if (group != undefined && group?.users != undefined && group?.users?.length > 0) {
            dispatch(usersActions.loadUsersByIds(group?.users.map(x => x.id)))
        }
    }, [group?.id]);

    const dispatch = useDispatch();

    const [addModalVisible, setAddModalVisible] = useState(false);
    const emailInputRef = useRef();
    let shownUsers = (group?.users == undefined) ? [] : group.users.map(x => usersMap.get(x.id)).filter(xx => (xx != undefined));
    const [addMode, setAddMode] = useState('email');
    shownUsers = shownUsers.sort((a, b) => {
        let gs = x => (x == undefined ? '' : x);
        let s1 = `${a.lastName} ${gs(a.firstName)}`.toLowerCase();
        let s2 = `${b.lastName} ${gs(b.firstName)}`.toLowerCase();
        if (s1 > s2){
            return 1;
        }
        if (s1 < s2){
            return -1;
        }
        return 0;
    })

    console.log('render: group = ', group);

    return (
        <Wrapper className={''}>

            <UsersList>
                <StudentsList users={shownUsers} onUserClick={u => {
                    CommonHelper.linkTo(`/groups/${id}/students/${u.id}`);
                }}/>
            </UsersList>


            {loading == true ? null :
                <>
                    {shownUsers.length == 0 ?
                        <NoStudentsLottie>
                            <LottieLoader
                                json={no_users_woman_lottie}
                            />
                            <NoStudentsText>
                                <div style={{marginBottom: 40}}>
                                    {translate('no_students_in_the_group')}
                                </div>
                                <BlueButton onClick={() => {
                                    setAddModalVisible(true);
                                }}>
                                    {translate('add_students')}
                                </BlueButton>
                            </NoStudentsText>
                        </NoStudentsLottie>
                        :
                        <AddPlaceholder>
                    <span style={{cursor: 'pointer'}} onClick={() => {
                        setAddModalVisible(true);
                    }}>
                        <Plus/> {translate('add_student')}
                    </span>

                            <span style={{cursor: 'pointer', marginLeft: 15}} onClick={() => {
                                let gs = x => (x == undefined ? '' : x);
                                let csvString = shownUsers.map(x => (`${x.email};${gs(x.firstName)};${gs(x.lastName)}`)).join('\n');
                                let csvContent = `data:text/csv;charset=utf-8,email;first_name;last_name\n${csvString}`;
                                let encodedUri = encodeURI(csvContent);
                                window.open(encodedUri);
                                let link = document.createElement("a");
                                link.setAttribute("href", encodedUri);
                                link.setAttribute("download", `students_${id}.csv`);
                                document.body.appendChild(link);
                                link.click();
                            }}>
                        <CsvImage/> {translate('make_export')}
                    </span>


                        </AddPlaceholder>
                    }
                </>
            }


            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <ModalInner>

                        <AddModeTabsWrapper>
                            <AddModeTab selected={(addMode == 'email')} onClick={() => {
                                setAddMode('email');
                            }}>
                                по email
                            </AddModeTab>
                            <AddModeTab selected={(addMode == 'bulk_email')} onClick={() => {
                                setAddMode('bulk_email');
                            }}>
                                массовое добавление
                            </AddModeTab>
                            <AddModeTab selected={(addMode == 'moodle')} onClick={() => {
                                setAddMode('moodle');
                            }}>
                                импортировать из Moodle
                            </AddModeTab>
                        </AddModeTabsWrapper>


                        {addMode != 'email' ? null :
                            <div>
                                <Field>
                                    <Input autoFocus={true} ref={emailInputRef}
                                           placeholder={'Email студента'}/>
                                </Field>
                                <Field>
                                    {loading == true ?
                                        <SpinnerPlaceholder>
                                            <Spin visible={true}/>
                                        </SpinnerPlaceholder>
                                        :
                                        <BlueButton
                                            onClick={async () => {
                                                let email = emailInputRef.current.value;
                                                if (validator.isEmail(email) == false) {
                                                    return
                                                }
                                                setLocalLoading(true);
                                                let link = (await axios.post(`${API_ENDPOINT}/add_to_class_by_email`, {
                                                    email: email.toLowerCase(),
                                                    classId: id
                                                })).data;
                                                if (link.error != undefined) {
                                                    window.alert(link.error.message);
                                                    setLocalLoading(false);
                                                    return;
                                                }
                                                console.log('link = ', link);
                                                await dispatch(actions.loadGroupById(id));
                                                await dispatch(usersActions.loadUserById(link.studentId));
                                                setLocalLoading(false);
                                                setAddModalVisible(false);
                                            }
                                            }>
                                            Добавить cтудента
                                        </BlueButton>
                                    }
                                </Field>
                            </div>
                        }
                        {addMode != 'bulk_email' ? null :
                            <div>
                                <Field>
                                    <Textarea autoFocus={true} ref={bulkEmailsInput}
                                              placeholder={'Emailы студентов (1 email в одной строке)'}/>
                                </Field>
                                <Field>
                                    {loading || localLoading ?
                                        <SpinnerPlaceholder>
                                            <Spin visible={true}/>
                                        </SpinnerPlaceholder>
                                        :
                                        <BlueButton
                                            onClick={async () => {
                                                setLocalLoading(true);
                                                let emailsString = bulkEmailsInput.current.value;
                                                if (emailsString == undefined) {
                                                    return;
                                                }
                                                let emails = emailsString.split('\n').map(x => x.replace(/\n/, '').toLowerCase()).filter(em => validator.isEmail(em));
                                                if (emails.length == 0) {
                                                    return
                                                }
                                                setLocalLoading(true);

                                                async function addByEmail(em) {
                                                    let pld = await axios.post(`${API_ENDPOINT}/add_to_class_by_email`, { // todo: redactor
                                                        email: em.toLowerCase(),
                                                        classId: id
                                                    });
                                                    return pld.data;
                                                }

                                                for (let i in emails) {
                                                    let em = emails[i];
                                                    let link = await addByEmail(em);
                                                    if (link.error != undefined) {
                                                        continue;
                                                    }
                                                    await dispatch(actions.loadGroupById(id));
                                                }
                                                setLocalLoading(false);
                                                setAddModalVisible(false);
                                            }}>
                                            Добавить cтудентов
                                        </BlueButton>
                                    }
                                </Field>
                            </div>
                        }

                        {addMode != 'moodle' ? null :
                            <div>
                                <MoodleSelectUserPanel
                                    selectedEmailsSet={shownUsers.reduce((st, ua) => st.add(ua.email), Set())}
                                    onNewSelect={async d => {
                                        setLocalLoading(true)
                                        let link = (await axios.post(`${API_ENDPOINT}/add_to_class_by_email`, {
                                            ...d,
                                            email: d.email.toLowerCase(),
                                            classId: id
                                        })).data;
                                        if (link.error != undefined) {
                                            window.alert(link.error.message);
                                            setLocalLoading(false);
                                            return;
                                        }
                                        await dispatch(actions.loadGroupById(id));
                                        setLocalLoading(false);
                                        setAddModalVisible(false);
                                    }}/>
                            </div>
                        }


                    </ModalInner>

                </NiceModal>
            }

        </Wrapper>
    );
}

const NoStudentsLottie = styled.div`
  width: 100%;
  height: calc(70vh - 10px);
  text-align: center;
`;

const NoStudentsText = styled.div`
  padding: 20px;
  min-width: 20vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -150px;
`;

const AddModeTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const AddButton = styled.div`
  background: #FF624F;
  border-radius: 1000px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 36px;
  padding-right: 36px;
  height: 44px;
  cursor: pointer;

  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.8;
  }
`;

const AddModeTab = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: ${props => (props.selected == true ? '2px solid #FFA71B' : '2px solid lightgrey')};
  cursor: pointer;
  padding-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const UsersList = styled.div`
  width: 100%;
`;

const UserItem = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  padding: 5px;
  background: white;
  box-sizing: border-box;
  width: calc(33.333% - 10px);
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const leftWidth = 60;
const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${leftWidth}px;
`;

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - ${leftWidth}px);
  padding-left: 5px;
  box-sizing: border-box;
  padding-top: 3px;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Email = styled.div`
  opacity: 0.7;
`;

const Avatar = styled.div`
  width: ${leftWidth}px;
  height: ${leftWidth}px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const SpinnerPlaceholder = styled.div`
  padding: 10px;
  text-align: center;
`;

const AddPlaceholder = styled.div`
  padding: 5px;
  font-size: 16px;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  opacity: 0.8;

  :hover {
    opacity: 1.0;
  }
`;

const Plus = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://www.englishpatient.org/storage/41ecc36b-b54e-4042-9b81-a304518c77fb.png');
`;

const CsvImage = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${shareImg});
`;


const Field = styled.div`
  margin-bottom: 10px;
`;

const ModalInner = styled.div`
  padding: 10px;
`;
