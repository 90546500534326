import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import TopSquaresTool from "./TopSquaresTool";
import ExerciseCardItemView from "./ExerciseCardItemView";
import ExerciseAnswerInputTool from "./ExerciseAnswerInputTool";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";

import Spin from 'arui-feather/spin'
import './diff.css'
import {translate} from "../../../utils/Translate";
import CommonHelper from "../../../helpers/CommonHelper";
// import Diff from 'react-diff'

import share_img from '../../../assets/img/share_mini.svg'
import NiceModal from "../../modals/NiceModal";
import TeacherAssesAndCommentTool from "../../comment/tools/TeacherAssesAndCommentTool";
import NiceConfirm from "../../modals/NiceConfirm";
import ViewAuthorTool from "../../author/tools/ViewAuthorTool";
import incorrect_cross from "../../../assets/img/incorrect_cross.svg";
import copyright_cc_img from "../../../assets/img/copyright_cc.svg";

function getNextCardId(cards = [], selectedId) {
    if (cards.length == 0) {
        return undefined;
    }
    let k = 0;
    for (let i in cards) {
        if (cards[i].id == selectedId) {
            k = i;
        }
    }
    if (k == cards.length - 1) {
        return undefined;
    }
    return (cards[+k + 1]?.id);
}

export default function ExerciseViewTool(props) {
    const {
        id,
        userId,
        currentUser,
        cards = [],
        onAnswer = d => {

        },
        onFinish = d => {

        },

        userAnswers = [],
        isPreview = false,

        loading = false,

        onMarkUpdate = d => {

        },

        onDeleteExerciseAnswers = d => {

        },

        task,
        name,
        description,

        canShare = false,
        isTeacher = false,

        educationResult,

        copyrightText

    } = props;

    // console.log('render: ExerciseViewTool: props = ', props);

    const [selectedId, setSelectedId] = useState(cards == undefined || cards.length == 0 ? undefined : cards[0].id);

    const [copyText, setCopyText] = useState(undefined);

    let exAnswer = userAnswers.filter(x => (x?.cardId == id && x?.exerciseId == id))[0];

    const [deleteAnswersModalOpen, setDeleteAnswersModalOpen] = useState(false);
    const [updateMarkModalVisible, setUpdateMarkModalVisible] = useState(false);
    // const [mark, setMark] = useState(autoMark == undefined ? '5' : autoMark);
    const [mark, setMark] = useState((exAnswer?.autoMark == undefined) ? '3' : exAnswer?.autoMark);
    const [comment, setComment] = useState(props.comment || '');

    useEffect(() => {
        setMark((exAnswer?.autoMark == undefined) ? '3' : exAnswer?.autoMark);
    }, [selectedId]);

    if (cards.length == 0) {
        return null;
    }

    let selectedCard = (selectedId == undefined) ? undefined : cards.filter(x => (x.id == selectedId))[0];
    let selectedUserAnswerObj = userAnswers.filter(x => (x.cardId == selectedCard?.id))[0];
    let passedIds = userAnswers.map(x => x?.cardId).filter(x => x != undefined);
    let goodMarkIds = userAnswers.filter(x => {
        let {teacherMark, autoMark} = x;
        let isMarkNegative = (autoMark != undefined && teacherMark == undefined) && (+autoMark < 0);
        if (isMarkNegative == true) {
            return false;
        }
        let maxMark = Math.max((autoMark == undefined) ? 0 : +autoMark, (teacherMark == undefined) ? 0 : +teacherMark);
        if (maxMark == 5 || maxMark == 100) {
            return true;
        }
        return false;
    }).map(x => x?.cardId).filter(x => (x != undefined));


    let canFinish = (cards.filter(x => (x.answerType != 'none')).length <= passedIds.length);

    // console.log('render: userAnswers = ', userAnswers);
    // console.log('render: passedIds = ', passedIds);
    // console.log('render: selectedUserAnswerObj = ', selectedUserAnswerObj);
    // console.log('render: canShare = ', canShare);
    // console.log('render: creatorId = ', props.creatorId);

    // {type == 'rating' ?
    //     <div style={{marginBottom: 10}} onClick={() => {
    //
    //     }}>
    //         {((teacherMark == undefined && autoMark == undefined) || isMarkNegative) ? `Оценка не выставлена` :
    //             <span>{(teacherMark || autoMark) == 0 ?
    //                 <IncorrImg src={incorrect_cross}/> : (teacherMark || autoMark)}</span>}
    //     </div>
    //     :
    //     <div style={{marginBottom: 10}} onClick={() => {
    //
    //     }}>
    //         {props.teacherPassed == undefined ? 'Оценка не выставлена' : (props.teacherPassed ? 'Зачёт' : 'Незачёт')}
    //     </div>
    // }


    return (
        <Wrapper>

            {name == undefined || name == '' ? null :
                <NamePlaceholder>
                    {name}
                </NamePlaceholder>
            }

            {task == undefined || task == '' ? null :
                <TaskPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: `${task}`.replace(/\n/g, '<br/>')}}></div>
                </TaskPlaceholder>
            }

            {description == undefined || description == '' ? null :
                <TaskPlaceholder>
                    <div dangerouslySetInnerHTML={{__html: `${description}`.replace(/\n/g, '<br/>')}}></div>
                </TaskPlaceholder>
            }

            <TopPlaceholder>
                <TopSquaresTool
                    userAnswers={userAnswers}
                    cards={cards}
                    isFinished={exAnswer != undefined}
                    goodMarkIds={goodMarkIds}
                    passedIds={passedIds}
                    selectedId={selectedId}
                    onSelect={xId => {
                        setSelectedId(xId);
                    }}
                />
            </TopPlaceholder>

            <CurrentCardPlaceholder>
                <ExerciseCardItemView
                    {...selectedCard}
                />
            </CurrentCardPlaceholder>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: 10
            }}>
                {(copyrightText == undefined || copyrightText == '') ? null :
                    <ShareSpan style={{marginLeft: 25, marginRight: 25}} onClick={() => {
                        setCopyText(copyrightText);
                    }}>
                        <ShareImg src={copyright_cc_img}/>
                        <span>Источники</span>
                    </ShareSpan>
                }
            </div>

            <AnswerSectionPlaceholder key={`ans_${selectedCard?.id}`}>

                {selectedCard?.answerType == 'none' ? null :
                    <>
                        <YourAnswerLabel>
                            Ваш ответ
                        </YourAnswerLabel>
                        <ExerciseAnswerInputTool
                            isFinished={(exAnswer != undefined)}
                            {...selectedUserAnswerObj}
                            {...props}
                            {...selectedCard}
                            isPreview={isPreview}
                            userAnswerText={selectedUserAnswerObj?.answer}
                            onSave={(s, isCorrect, correctAnswer) => {
                                console.log('------');
                                if (s == undefined) {
                                    return;
                                }
                                console.log('onSave: selectedCard = ', selectedCard);
                                console.log('onSave: s, isCorrect, correctAnswer = ', s, isCorrect, correctAnswer);
                                console.log('onSave: selectedCard = ', selectedCard);
                                onAnswer({
                                    cardId: selectedId,
                                    answer: s,
                                    isCorrect: isCorrect,
                                    correctAnswer: selectedCard?.correctAnswer || correctAnswer,
                                    type: selectedCard?.answerType,
                                    answerType: selectedCard?.answerType
                                });
                                if (selectedCard?.answerType != undefined && (['speaking', 'match', 'order', 'highlight', 'video_recording'].indexOf(selectedCard?.answerType) == -1) || isCorrect) {
                                    let nextId = getNextCardId(cards, selectedId);
                                    if (nextId != undefined) {
                                        setSelectedId(nextId);
                                    }
                                }
                            }}
                            onMarkUpdate={d => {
                                if (selectedUserAnswerObj == undefined) {
                                    return;
                                }
                                console.log('onMarkUpdate: d = ', d);
                                onMarkUpdate({
                                    id: selectedUserAnswerObj?.id,
                                    ...d
                                });
                            }}
                        />
                    </>
                }

            </AnswerSectionPlaceholder>


            {(canFinish == false || exAnswer != undefined) ? null :
                <FinishSection>
                    <GreenButton onClick={() => {
                        onFinish();
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? '....' : 'Завершить упражнение'}
                    </GreenButton>
                </FinishSection>
            }

            {exAnswer?.autoMark == undefined ? null :
                <AutoAnswer>
                    <div>
                        {`Оценка за всё упражнение: ${Math.round(exAnswer?.autoMark) == -1 ? 'На проверке' : (exAnswer?.teacherMark || exAnswer?.autoMark).toFixed(2)}`}
                    </div>
                </AutoAnswer>
            }

            {userAnswers?.length === 0 || isTeacher === false ? null :
                <DeleteAnswers>
                    <span style={{color: 'red', cursor: 'pointer', opacity: 0.5, fontSize: 12, marginTop: 10}}
                            onClick={() => {
                                setDeleteAnswersModalOpen(true);
                            }}>
                        удалить ответы {userId == undefined || currentUser.id == userId? '' : 'студента'}
                    </span>
                </DeleteAnswers>
            }

            {(educationResult == undefined || educationResult == '') ? null :
                <ModBaA>
                    {educationResult}
                </ModBaA>
            }

            {canShare == false ? null :
                <ShareExecisePlaceholder>
                    <ShareLinkSpan onClick={() => {
                        CommonHelper.linkTo(`/share/exercises/${id}`, true);
                    }}>
                        <ShareImg src={share_img}/>
                        {translate('share_exercise')}
                    </ShareLinkSpan>
                </ShareExecisePlaceholder>
            }


            {isTeacher == false ? null :
                <ShareExecisePlaceholder>
                    <ViewAuthorTool userId={props.creatorId} badgeMode={false}/>
                </ShareExecisePlaceholder>
            }
            {deleteAnswersModalOpen == false ? null :
                <NiceConfirm
                    heading={'Подтвердите удаление'}
                    subHeading={'Вы уверены? Ответы студента будут удалены.'}
                    onConfirm={() => {
                        onDeleteExerciseAnswers();
                        setDeleteAnswersModalOpen(false);
                    }} onCancel={() => {
                    setDeleteAnswersModalOpen(false);
                }}>

                </NiceConfirm>
            }


            {updateMarkModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setUpdateMarkModalVisible(false);
                }}>
                    <InnerModalPlaceholder key={selectedId}>
                        <div>
                            <TeacherAssesAndCommentTool
                                hasTextComment={true}
                                rating={mark}
                                onRatingChange={(x, type) => {
                                    setMark(x);
                                }}
                                textComment={comment}
                                onTextCommentChange={s => {
                                    setComment(s);
                                }}
                            />
                            {/*<Input*/}
                            {/*    value={mark}*/}
                            {/*    placeholder={'Оценка от 1 до 5'}*/}
                            {/*    onChange={evt => {*/}
                            {/*        setMark(evt.target.value);*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <div>
                            <BlueButton onClick={() => {
                                onMarkUpdate({
                                    teacherMark: mark,
                                    teacherComment: comment
                                });
                            }}>
                                <Spin visible={loading}/>
                                {loading == true ? null : 'Сохранить'}
                            </BlueButton>
                        </div>
                    </InnerModalPlaceholder>
                </NiceModal>
            }

            {copyText == undefined ? null :
                <NiceModal onClose={() => {
                    setCopyText(undefined);
                }}>
                    <InnerModalPlaceholder>
                        <div dangerouslySetInnerHTML={{__html: `${copyText}`.replace(/\n/g, '<br/>')}}></div>
                    </InnerModalPlaceholder>
                </NiceModal>
            }

            {/*{JSON.stringify(exAnswer)}*/}

        </Wrapper>
    );
}

const ModBaA = styled.div`
  background: rgba(0, 114, 188, 0.35);
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  font-style: italic;
  margin-bottom: 40px;
`;


const InnerModalPlaceholder = styled.div`
  width: 420px;
`;

const ShareImg = styled.img`
  margin-right: 5px;
  width: 16px;
  height: 16px;
`;

const ShareLinkSpan = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const ShareExecisePlaceholder = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
`;

const NamePlaceholder = styled.div`
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
`;


const TaskPlaceholder = styled.div`
  padding: 10px;
`;

const AutoAnswer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  font-size: 14px;
  border-top: 1px solid lightgrey;
  margin-top: 10px;
`;

const DeleteAnswers = styled.div`
  padding-left: 10px;
  padding-top: 5px;
  font-size: 14px;
`;

const FinishSection = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const YourAnswerLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 15px;
  padding-left: 5px;
  font-weight: bold;
  font-size: 12px;
`;

const ShareSpan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  font-size: 12px;

  :hover {
    opacity: 1;
  }
`;

const AnswerSectionPlaceholder = styled.div`

`;

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid whitesmoke;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 6px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 5px;
  width: 100%;
`;

const CurrentCardPlaceholder = styled.div`
  width: 100%;
`;