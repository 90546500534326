import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import {Input, Textarea} from "../../ira/ui/Inputs";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";

import remove_icon from '../../../assets/img/x.svg'
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";
import {translate} from "../../../utils/Translate";
import {getFixedUrl} from "../../exercises/tools/ExerciseCardItemView";

export default function QuestionEditorTool(props) {

    const {
        loading = false,
        onSave = d => {

        }
    } = props;

    const [name, setName] = useState(props.name || '');
    const [question, setQuestion] = useState(props.question || '');
    const [ruQuestion, setRuQuestion] = useState(props.ruQuestion || '');
    const [url, setUrl] = useState(props.url);
    const [answers, setAnswers] = useState(props.answers || []);

    const [searchModalVisible, setSearchModalVisible] = useState(false);
    const [updT, setUpdT] = useState(0);


    return (
        <Wrapper>
            <TopPlaceholder>
                <TopLeftPlaceholder>
                    {url == undefined ? null :
                        <Video controls={true} key={url} >
                            <source src={getFixedUrl(url)}/>
                        </Video>
                    }
                    <BlueButton onClick={() => {
                        setSearchModalVisible(true);
                    }}>
                        {translate('add_video')}
                    </BlueButton>
                </TopLeftPlaceholder>
                <TopRightPlaceholder>
                    <Row>
                        <Input value={name} placeholder={'Название вопроса'} onChange={evt => {
                            setName(evt.target.value);
                        }}/>
                    </Row>
                    <Row>
                        <Input value={question} placeholder={'Вопрос по-английски'} onChange={evt => {
                            setQuestion(evt.target.value);
                        }}/>
                    </Row>
                    <Row>
                        <Input value={ruQuestion} placeholder={'Вопрос по-русски'} onChange={evt => {
                            setRuQuestion(evt.target.value);
                        }}/>
                    </Row>
                </TopRightPlaceholder>
            </TopPlaceholder>

            <MiddlePlaceholder key={`x${updT}`}>
                {answers.map((a, i) => {
                    return (
                        <AnsRow key={`${i}`}>
                            <AnsItemTriplet>
                                <Textarea value={a.answer} onChange={evt => {
                                    let s = evt.target.value;
                                    setAnswers(answers.map((b, j) => (j == i ? ({...b, answer: s}) : b)));
                                }}></Textarea>
                            </AnsItemTriplet>
                            <AnsItemTriplet>
                                <Textarea value={a.ruAnswer} onChange={evt => {
                                    let s = evt.target.value;
                                    setAnswers(answers.map((b, j) => (j == i ? ({...b, ruAnswer: s}) : b)));
                                }}></Textarea>
                            </AnsItemTriplet>
                            <AnsItemTriplet>
                                {a.audioUrl == undefined ? null :
                                    <audio controls={true}>
                                        <source src={getFixedUrl(a.audioUrl)}/>
                                    </audio>
                                }
                                <SimpleFileUploader
                                    placeholder={'Загрузить аудио'}
                                    onSave={u => {
                                        setAnswers(answers.map((b, j) => (j == i ? ({...b, audioUrl: u}) : b)));
                                    }}
                                />
                            </AnsItemTriplet>
                            <div>
                                <img src={remove_icon} onClick={() => {
                                    setAnswers(answers.filter((q, j) => (j != i)));
                                    setUpdT(+new Date());
                                }}/>
                            </div>
                        </AnsRow>
                    )
                })}

                <BottomSection>
                    <AddSpan onClick={() => {
                        setAnswers(answers.concat([{answer: '', ruAnswer: ''}]));
                    }}>
                        Создать ответ
                    </AddSpan>

                    <GreenButton onClick={() => {
                        onSave({
                            name: name,
                            question: question,
                            ruQuestion: ruQuestion,
                            url: getFixedUrl(url),
                            answers: answers
                        });
                    }}>
                        <Spin visible={loading}/>
                        Сохранить
                    </GreenButton>

                </BottomSection>

            </MiddlePlaceholder>

            <Sidebar visible={searchModalVisible}
                     width={Math.min(window.innerWidth, 1280)}
                     onCloserClick={() => {
                         setSearchModalVisible(false);
                     }}>

                <div style={{height: 'calc(100vh - 140px)'}}>
                    {searchModalVisible == false ? null :
                        <SearchOrCreateMaterialsPanel
                            type={'materials'}
                            onSelect={m => {
                                setUrl(m.url);
                                setSearchModalVisible(false);
                            }}/>
                    }
                </div>

            </Sidebar>

        </Wrapper>
    );
}

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AddSpan = styled.div`
  cursor: pointer;
  opacity: 0.5;
  margin-bottom: 10px;

  :hover {
    opacity: 1;
  }
`;

const MiddlePlaceholder = styled.div`

`;

const AnsRow = styled.div`
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const AnsItemTriplet = styled.div`
  box-sizing: border-box;
  flex: 1;
  padding: 5px;
`;

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 5px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
  
  @media (max-width: 720px){
    flex-direction: column;
    align-items: center;
  }
`;

const TopLeftPlaceholder = styled.div`
  box-sizing: border-box;
  width: 420px;
  padding: 10px;
  
  @media (max-width: 720px){
    width: 60vw;
  }
  
`;

const TopRightPlaceholder = styled.div`
  flex: 1;
`;

const Video = styled.video`
  display: block;
  width: 400px;
  height: 300px;
  
  @media (max-width: 720px){
    width: 40vw;
    height: 200px;
    padding-bottom: 20px;
  }
`;