import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import MonthCalendar from "../../calendars/MonthCalendar";
import Tabs, {TabItem} from "../../ui/Tabs";
import UsersChartTool from "../tools/UsersChartTool";
import AnswersChartTool from "../tools/AnswersChartTool";

export default function SimpleGroupOrStudentAnalyticsPanel(props) {

    const {
        groupId,
        studentId
    } = props;

    const [from, setFrom] = useState(+moment().add(-3, 'months'));
    const [to, setTo] = useState(+moment().endOf('day'));

    return (
        <Wrapper>

            <TopDataFiltersPlaceholder>
                <DateItemField>
                    <Label>
                        От
                    </Label>
                    <CalPlaceholder>
                        <MonthCalendar selectedTimestamp={from} dayContent={t => {
                            let isSelected = (+moment(t).startOf('day') == +moment(from).startOf('day'))
                            return (
                                <Cell selected={isSelected} onClick={() => {
                                    let newT = +moment(t).startOf('day');
                                    setFrom(newT);
                                }}>

                                </Cell>
                            )
                        }}/>
                    </CalPlaceholder>
                </DateItemField>
                <DateItemField>
                    <Label>
                        До
                    </Label>
                    <CalPlaceholder>
                        <MonthCalendar selectedTimestamp={to} dayContent={t => {
                            let isSelected = (+moment(t).startOf('day') == +moment(to).startOf('day'))
                            return (
                                <Cell selected={isSelected} onClick={() => {
                                    let newT = +moment(t).endOf('day');
                                    setTo(newT);
                                }}>

                                </Cell>
                            )
                        }}/>
                    </CalPlaceholder>
                </DateItemField>
            </TopDataFiltersPlaceholder>

            <ContentPlaceholder>
                <ContHeading style={{textAlign: 'center'}}>
                    {`${moment(from).format('DD.MM.YYYY')} - ${moment(to).format('DD.MM.YYYY')}`}
                </ContHeading>

                <AnswersChartTool
                    from={from}
                    to={to}
                    studentId={studentId}
                    groupId={groupId}
                />

            </ContentPlaceholder>

        </Wrapper>
    );
}

const ContHeading = styled.div`

`;

const CalPlaceholder = styled.div`
  height: 300px;
  padding: 10px;
`;

const Wrapper = styled.div`
  width: 820px;
  padding: 10px;
  background: white;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 4px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const ContentPlaceholder = styled.div`
  padding-top: 10px;
`;

const Cell = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => (props.selected == true ? '#FFA71C' : 'white')};
  color: ${props => (props.selected == true ? '#FFFFFF' : 'grey')};
  cursor: pointer;
`;


const Label = styled.div`
  font-weight: bold;
  text-align: center;
`;

const TopDataFiltersPlaceholder = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 720px){
    flex-direction: column;
  }
`;

const DateItemField = styled.div`

`;