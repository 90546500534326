import React, {useCallback, useEffect, useState} from 'react';

import axios from 'axios'
import styled from 'styled-components'
import Sidebar from 'arui-feather/sidebar'

import SmartFirebaseAPI from "../../api/SmartFirebaseAPI";
import ChambersEditor from "./tools/ChambersEditor";
import Select from 'react-select'
import moment from "moment";

import online_courses_and_tutorials from '../../assets/lottie/online-courses-and-tutorials.json'
import end_call from '../../assets/img/end_call.svg'
import lesson_image from '../../assets/img/mode_lesson.svg'
import editor_image from '../../assets/img/editor.svg'
import chambers_image from '../../assets/img/chambers.svg'
import whiteboard_image from '../../assets/img/whiteboard.svg'
import square_image from '../../assets/img/square.png'
import square_image_check from '../../assets/img/check-square_.png'

import close_img from '../../assets/img/black_close.svg'
// import chat_img from '../../assets/img/chat.svg'
import chat_img from '../../assets/img/chat_.svg'
import purp_chat_img from '../../assets/img/purp_chat.svg'

import SyncDrawableImage from "../canvas/SyncDrawableImage";

import SimpleInteractiveCommutePanel from "../commute/panels/SimpleInteractiveCommutePanel";
import LottieLoader from "../loaders/LottieLoader";
import CommonHelper from "../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../constants/config";
import LessonTasksEditor from "../lessons/tools/LessonTasksEditor";
import FreeSyncVideoPlayer from "../players/FreeSyncVideoPlayer";
import RealtimeEditor from "../tiny/RealtimeEditor";
import Tabs, {TabItem} from "../ui/Tabs";
import DeadlineCountdownPanel from "../countdown/panels/DeadlineCountdownPanel";
import DeadlineCountdownEditorPanel from "../countdown/panels/DeadlineCountdownEditorPanel";
import KvdbTasksFeedPanel from "./panels/KvdbTasksFeedPanel";
import NiceModal from "../modals/NiceModal";
import RoomChatPanel from "../chat/panels/RoomChatPanel";

import './style.css'

import ls from 'local-storage'

import forest_img from '../../assets/img/forest_background.jpg'

import {Rnd} from 'react-rnd';
import {Col, ColsWrapper, Row, RowsWrapper} from "./tools/grid";
import {getOnlineName} from "./OnlineLesson";

const getPosSettingsByName = name => {
    let val = ls(`rnd_${name}`);
    if (val == undefined) {
        if (name == 'working_area') {
            return {
                width: 720,
                minWidth: 620,
                height: window.innerHeight,
                x: 70,
                y: 0
            }
        }
        if (name == 'jitsi') {
            return {
                width: window.innerWidth - 70 - 720,
                minWidth: 400,
                minHeight: 300,
                height: window.innerHeight * 0.65,
                x: 800,
                y: 0
            }
        }
        if (name == 'chat') {
            return {
                width: (window.innerWidth - 70 - 720) * 0.6,
                minWidth: 240,
                minHeight: window.innerHeight * 0.3,
                height: window.innerHeight * 0.3,
                x: 800,
                y: window.innerHeight * 0.7

            }
        }
        if (name == 'sync_player') {
            return {
                width: (window.innerWidth - 70 - 720) * 0.6,
                minWidth: 400,
                minHeight: window.innerHeight * 0.3,
                height: window.innerHeight * 0.7,
                x: 800,
                y: window.innerHeight * 0.1
            }
        }
    }
    return val;
}

const setPosSettingsByName = (name, d) => {
    console.log('setPosSettingsByName: name, d = ', name, d);
    let val = getPosSettingsByName(name);
    val = JSON.parse(JSON.stringify(val));
    console.log('old val = ', val);
    for (let key in d) {
        let v = d[key];
        v = `${v}`.replace('px', '');
        console.log('key, v = ', key, v);
        if (v != undefined && +v > 0){
            val[key] = +v;
        }
    }
    console.log('-->> val = ', val);
    ls(`rnd_${name}`, val);
    return val;
}

const hideWidget = () => {
    try {
        let items = document.getElementsByClassName('b24-widget-button-wrapper');
        for (let i in items) {
            items[i].style.display = 'none';
        }
    } catch (exc) {

    }

}

const OnlineLessonMobile = (props) => {

    const {
        currentUser,
        allLessons = [],
        allTasks = [],
        groupId,
        onCurrentLessonChange = newId => {

        },
        updT = 0
    } = props;

    const lessonsOptions = allLessons.map(a => ({
        value: a.id,
        label: `[${moment(a.startTimestamp).format('DD.MM.YYYY HH:mm')}]: ${a.name}`
    }));
    console.log('lessons options = ', lessonsOptions);

    const [chatVisible, setChatVisible] = useState(false);
    const [syncPlayerVisible, setSyncPlayerVisible] = useState(true);
    const [selectedStudentId, setSelectedStudentId] = useState(undefined);

    const roomName = `room_${groupId}`;

    const [loaded, setLoaded] = useState(false);
    const [groupInfo, setGroupInfo] = useState(undefined);
    // const [allLessons, setAllLessons] = useState([])
    const [chambersEditorVisible, setChambersEditorVisible] = useState(false);
    // const dispatch = useDispatch();
    const [fakeLoaderVisible, setFakeLoaderVisible] = useState(true);
    let currentUserId = currentUser?.id;
    let userRole = currentUser?.userRole;
    let isTeacher = (userRole == 'teacher');

    useEffect(() => {
        hideWidget();
        SmartFirebaseAPI.initFirebase();
        axios.get(`${API_ENDPOINT}/classes/${groupId}`).then(d => d.data).then(x => {
            setGroupInfo(x);
            setLoaded(true);
            SmartFirebaseAPI.subscribeLessonZoomOnChanges(roomName, chData => {
                if (chData == undefined) {
                    return;
                }
                console.log('-> CHANGE! chData = ', chData);
                setZoomState(chData);
                onCurrentLessonChange(chData?.lessonSettings?.currentLessonId)
            }).then(d => {
                if (d == undefined) {
                    setLoaded(true);
                    setTimeout(() => {
                        setFakeLoaderVisible(false);
                    }, 2800);
                    return;
                }
                setZoomState(d);
                setLoaded(true);
                onCurrentLessonChange(d?.lessonSettings?.currentLessonId)
                setTimeout(() => {
                    setFakeLoaderVisible(false);
                }, 2800);
            });
        });

        return () => {
            SmartFirebaseAPI.unsubscribeFromLessonZoomChanges(roomName);
        }
    }, [groupId]);
    const [zoomState, setZoomState] = useState(undefined);

    let chambers = (zoomState == undefined || zoomState.chambers == undefined) ? [] : zoomState.chambers;
    let chamberMode = (zoomState == undefined || zoomState.chamberMode == undefined) ? false : zoomState.chamberMode;

    let activeTab = (zoomState == undefined || zoomState.lessonSettings == undefined) ? 'whiteboard' : zoomState.lessonSettings.activeTab;
    let openEyesIds = (zoomState == undefined || zoomState.lessonSettings == undefined || zoomState.lessonSettings.openEyesIds == undefined) ? [] : zoomState.lessonSettings.openEyesIds;


    let lessonSettings_ = (zoomState == undefined || zoomState.lessonSettings == undefined) ? {} : zoomState.lessonSettings;

    let endOnlineLesson = (zoomState == undefined || zoomState.lessonSettings == undefined) ? false : zoomState.lessonSettings.endOnlineLesson;
    let currentLesson = (zoomState?.lessonSettings?.currentLessonId) ? allLessons.filter(xx => (xx.id == zoomState?.lessonSettings?.currentLessonId))[0] : allLessons && allLessons.length !== 0 && allLessons[0];
    let currentLessonTab = (zoomState?.lessonSettings?.currentLessonTab == undefined) ? 'items' : zoomState?.lessonSettings?.currentLessonTab;
    let currentTaskId = (zoomState == undefined || zoomState.lessonSettings == undefined) ? 'false' : zoomState.lessonSettings.currentTaskId;
    let currentScrollBlockId = (zoomState?.lessonSettings?.currentScrollBlockId == undefined) ? 'none' : zoomState?.lessonSettings?.currentScrollBlockId;
    let hiddenItemsIds = (zoomState?.lessonSettings?.hiddenItemsIds == undefined) ? [] : zoomState?.lessonSettings?.hiddenItemsIds;
    let translatableModalVisible = (zoomState?.lessonSettings?.translatableModalVisible == undefined) ? 0 : zoomState?.lessonSettings?.translatableModalVisible;

    let syncVideoUrl = (zoomState == undefined || zoomState?.lessonSettings?.syncVideoUrl == undefined || zoomState?.lessonSettings?.syncVideoUrl == '') ? undefined : zoomState?.lessonSettings?.syncVideoUrl;

    let users = (groupInfo == undefined || groupInfo.users == undefined) ? [] : groupInfo.users;
    const activeChambers = chambers.filter(x => (x != undefined && x.usersIds != undefined && x.usersIds.length > 0));

    console.log("allUsers", users);
    let students = users.filter(x => (x?.userRole != 'teacher'));
    let studentsOptions = students.map(x => ({value: x.id, label: CommonHelper.getUserName(x)}));

    let myChamber = chambers.filter(x => (x != undefined && x.usersIds != undefined)).filter(xx => (xx.usersIds.indexOf(currentUserId) > -1))[0];

    let videoRoomName = (chamberMode == true && myChamber != undefined) ? `room_${roomName}_${myChamber == undefined ? undefined : myChamber.id}` : `room_${roomName}`;

    useEffect(() => {
        try {
            if (!isTeacher && currentScrollBlockId != 'none') {
                let element = document.getElementById(currentScrollBlockId);
                element.scrollIntoView({behavior: "smooth", block: "center"});
            }
        } catch (exc) {

        }
    }, [currentScrollBlockId]);

    useEffect(() => {
        if (zoomState?.lessonSettings?.currentLessonId != undefined) {
            onCurrentLessonChange(zoomState?.lessonSettings?.currentLessonId);
        }
    }, [zoomState?.lessonSettings?.currentLessonId])

    useEffect(() => {
        try {
            if (endOnlineLesson) {
                CommonHelper.linkTo(`/groups/${groupId}`)
                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                    ...lessonSettings_,
                    endOnlineLesson: false
                });
            }
        } catch (exc) {

        }
    }, [endOnlineLesson]);

    let tasks = allTasks.filter(xx => (xx.lessonId == currentLesson?.id));
    console.log('tasks = ', tasks);
    console.log('currentLesson = ', currentLesson);
    console.log('zoomState?.lessonSettings = ', zoomState?.lessonSettings);

    return (
    <Wrapper>
        {loaded == false ? null :
            <React.Fragment>
                <NavBar>
                    <NavTopLeft>
                        <EngPatSpan onClick={() => {
                            CommonHelper.linkTo(`/classes/${groupId}`)}
                        }>
                            {groupInfo.name}
                        </EngPatSpan>
                    </NavTopLeft>

                    <div style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                        <div>

                            {chamberMode == false ? null :
                                <span style={{marginLeft: 10}}>
                                        <>
                                            <DeadlineCountdownPanel
                                                    name={videoRoomName}
                                                />

                                        </>
                                    </span>
                            }
                        </div>

                        {currentUser?.userRole != 'teacher' ? null :

                            <div style={{width: '100%', marginLeft: 10, zIndex: 15}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} >
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}  >
                                        {'Lesson: '}
                                        <div style={{marginLeft: 5, width: 280}} >
                                            <Select
                                                placeholder={'Select lesson'}
                                                options={lessonsOptions}
                                                value={lessonsOptions.filter(xx => (xx?.value == currentLesson?.id))[0]}
                                                onChange={a => {
                                                    console.log('a.value == ', a.value);
                                                    SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                        ...lessonSettings_,
                                                        currentLessonId: a.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5}}  >
                                        {'Student: '}
                                        <div style={{marginLeft: 5, width: 220}} >
                                            <Select
                                                isClearable={true}
                                                placeholder={'Student'}
                                                options={studentsOptions}
                                                value={studentsOptions.filter(xx => (xx?.value == selectedStudentId))[0]}
                                                onChange={a => {
                                                    if (a == undefined){
                                                        setSelectedStudentId(undefined);
                                                    }else{
                                                        setSelectedStudentId(a.value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>

                    <NavTopRight>
                        {currentUser.email}
                    </NavTopRight>

                </NavBar>

                    <ContentPlaceholder>
                        <GridPlaceholder>

                            <LeftPlaceholder style={{
                                width: '100%',
                                height: (window.innerWidth*0.69),
                                maxHeight: '51%',
                                boxSizing: "border-box",
                                borderRadius: 4,
                                position: 'relative',
                                display: (chatVisible == true) ? 'none' : 'block'
                            }}>

                                <div style={{
                                    height: (window.innerWidth*0.69),
                                    maxHeight: '100%',
                                    //overflowY: 'auto',
                                    width: '100%',
                                    backgroundColor: 'whitesmoke',
                                    padding: 0
                                }}>
                                    {activeTab != 'editor_mode' ? null :
                                        <div style={{width: '100%'}}>
                                            <SyncHeading>
                                                Синхронный редактор
                                            </SyncHeading>
                                            <RealtimeEditor
                                                roomId={videoRoomName}
                                            />
                                        </div>
                                    }

                                    <LeftInnerWrapper>
                                        {activeTab != 'whiteboard' ? null :
                                            <div style={{height: '100%',
                                                width: '100%'}}>
                                                <SyncDrawableImage
                                                    roomName={`whiteboard_${groupId}`}
                                                    src={'https://www.englishpatient.org/english-patient-files/b449ce7f-fb7b-499d-8972-c7088922b196.jpeg'}
                                                />
                                            </div>
                                        }


                                        {activeTab != 'lesson_mode' ? null :
                                            <VeryLeftPlaceholder
                                                key={`${currentLesson && allLessons && allLessons[0] ? currentLesson?.id : 'lesson'}_${0}`}>

                                                {chamberMode == true ?
                                                    <React.Fragment>
                                                        <KvdbTasksFeedPanel
                                                            name={videoRoomName}
                                                            canEdit={userRole == 'teacher'}
                                                        />
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment key={`zz+${selectedStudentId}`} >
                                                        {currentLesson != undefined ? null :
                                                            <div>
                                                                Выберите урок
                                                            </div>
                                                        }
                                                        <LessonTasksEditor
                                                            userId={selectedStudentId}
                                                            isTeacher={isTeacher}
                                                            onlineHiddenItemsIds={hiddenItemsIds}
                                                            isOnlineMode={true}

                                                            forceTab={currentLessonTab}
                                                            isForceTab={true}

                                                            hasTabs={isTeacher}
                                                            canEdit={false}
                                                            tasks={tasks}
                                                            selectedTaskId={zoomState?.lessonSettings?.selectedTaskId}

                                                            showMoveToMeButton={isTeacher}
                                                            onMoveToBlockClick={blockDivId => {
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    currentScrollBlockId: blockDivId,
                                                                });
                                                            }}
                                                            onOnlineEyeToggle={eId => {
                                                                console.log('!!!=> onOnlineEyeToggle: eId = ', eId);
                                                                let newIds = hiddenItemsIds.indexOf(eId) > -1 ? hiddenItemsIds.filter(xx => (xx != eId)) : hiddenItemsIds.concat([eId]);
                                                                console.log('onlineHiddenItemsIds: newIds = ', newIds);
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    hiddenItemsIds: newIds,
                                                                });
                                                            }}
                                                            onTranscriptOnlineEyeToggle={eId => {
                                                                console.log('!!!=> onOnlineEyeToggle: eId = ', eId);
                                                                let trId = `tr-${eId}`;
                                                                let newIds = hiddenItemsIds.indexOf(eId) > -1 ? hiddenItemsIds.filter(xx => (xx != trId)) : hiddenItemsIds.concat([trId]);
                                                                console.log('onlineHiddenItemsIds: newIds = ', newIds);
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    hiddenItemsIds: newIds,
                                                                });
                                                            }}

                                                            onSyncVideoSelect={url => {
                                                                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                                                                    ...lessonSettings_,
                                                                    syncVideoUrl: url
                                                                });
                                                            }}

                                                        />
                                                    </React.Fragment>
                                                }


                                            </VeryLeftPlaceholder>
                                        }

                                    </LeftInnerWrapper>
                                </div>

                            </LeftPlaceholder>

                            <div style={{position: 'relative',
                                width: '100%',
                                /*height: (window.innerHeight * 0.84 - (window.innerWidth*0.69)),*/
                                height: '100%',
                                display: (chatVisible == true) ? 'none' : 'block'
                            }} >
                                <div style={{position: 'absolute', left: 0, right: 0, top: 0, height: 80, zIndex: 2}} >

                                </div>
                                {chambersEditorVisible == true ? null :
                                    <SimpleInteractiveCommutePanel
                                        roomId={videoRoomName}
                                    />
                                }
                            </div>

                            {chatVisible == false ? null :
                                <div style={{
                                    backgroundColor: 'white',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                    <RoomChatPanel roomId={groupId} style={{overflowY: 'auto'}}

                                    />
                                </div>
                            }

                        </GridPlaceholder>

                        {syncVideoUrl &&
                            <Rnd
                                dragHandleClassName={'rnd_handle'}
                                default={{
                                    ...getPosSettingsByName('sync_player')
                                }}
                                minWidth={getPosSettingsByName('sync_player').minWidth}
                                minHeight={getPosSettingsByName('chat').minHeight}
                                bounds="window"
                                onDragStop={(e, d) => {
                                    setPosSettingsByName('sync_player', {x: d.x, y: d.y})
                                }}
                                onResizeStop={(e, direction, ref, delta, position) => {
                                    setPosSettingsByName('sync_player', {
                                        width: ref.style.width,
                                        height: ref.style.height,
                                        x: position.x,
                                        y: position.y
                                    })
                                }}
                            >
                                <div style={{
                                    width: '100%', height: '102px', maxHeight: '100%',
                                    overflowY: 'hidden', boxSizing: "border-box", padding: 8, paddingTop: 15,
                                    backgroundColor: "#614C2E",
                                    borderRadius: 4,
                                    position: 'relative'
                                }}>
                                    <div className={'rnd_handle'}>

                                    </div>

                                    <div style={{
                                        height: '103px', maxHeight: '100%',
                                        overflowY: 'auto', width: '100%',
                                        backgroundColor: 'whitesmoke', padding: 5,
                                        position: 'relative'
                                    }}>

                                        <FreeSyncVideoPlayer
                                            roomPrefix={roomName}
                                            url={syncVideoUrl}
                                        />

                                    </div>

                                </div>
                            </Rnd>
                        }

                        {isTeacher == true ? null :
                            <VeryRightPlaceholder className={'very_right_placeholder'}>
                                <React.Fragment>
                                    <SideButton onClick={() => {

                                    }}>
                                        <SideImg src={end_call}/>
                                    </SideButton>

                                    <SideButton selected={chatVisible} onClick={() => {
                                        setChatVisible(!chatVisible);
                                        setSyncPlayerVisible(!syncPlayerVisible);
                                    }}>
                                        <SideImg src={chat_img}/>
                                    </SideButton>

                                </React.Fragment>
                            </VeryRightPlaceholder>
                        }

                    </ContentPlaceholder>

                    <Sidebar visible={chambersEditorVisible}
                             width={Math.min(window.innerWidth)}
                             onCloserClick={() => {
                                 setChambersEditorVisible(false);
                             }}>

                    </Sidebar>

            </React.Fragment>
        }

        {fakeLoaderVisible == false ? null :
            <FakeLoaderPlaceholder>
                <LottieLoader
                    json={online_courses_and_tutorials}
                />
            </FakeLoaderPlaceholder>
        }

        {translatableModalVisible != 1 ? null :
            <NiceModal onClose={() => {
                SmartFirebaseAPI.updateLessonZoomFireState(roomName, chambers, chamberMode, {
                    ...lessonSettings_,
                    translatableModalVisible: 0
                });
            }}>

                <TransInner>
                    <RoomChatPanel roomId={groupId}/>
                </TransInner>

            </NiceModal>
        }

    </Wrapper>
    );
};

export default OnlineLessonMobile;

const topBarHeight = 48;

const TransInner = styled.div`
  width: 100%;
  height: calc(80vh - 40px);
`;

const GridCell = styled.div`
  //height: 50vh;
  height: 100px;
  box-sizing: border-box;
  padding: 5px;
`;

const SyncHeading = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  margin-top: 20px;
`;

const LeftInnerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const NavTopLeft = styled.div`
  font-weight: bold;
  padding-left: 20px;
  box-sizing: border-box;
`;

const EngPatSpan = styled.span`
  cursor: pointer;
`;

const NavTopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-right: 20px;
`;

const OnlineGridMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const VeryLeftPlaceholder = styled.div`
  height: 100px;
  overflow: auto;
`;

const VeryRightPlaceholder = styled.div`
  height: 9%;
  background: #F2EDF4;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const SideImg = styled.img`
  height: 32px;
  box-sizing: border-box;
`;

const SideButton = styled.div`
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-weight: ${props => (props.selected == true ? 'bold' : 'default')};
  background: ${props => (props.selected == true ? 'lightyellow' : 'transparent')};

  :hover {
    opacity: 0.8;
  }
`;

const NavBar = styled.div`
  height: 7%;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid lightgrey;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  //display: flex;
`;

const ContentPlaceholder = styled.div`
  box-sizing: border-box;
  //height: calc(100vh - 40px);
  height: 93%;
  overflow-y: auto;
  //overflow-x: hidden;
  position: relative;
  width: 100vw;
  background: grey;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${forest_img});
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const GridPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  height: 84%;
`;


const LeftPlaceholder = styled.div`

`;

const FakeLoaderPlaceholder = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.95);
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;
