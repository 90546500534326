import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import SlideContentItemView from "./SlideContentItemView";

import eye_no from '../../../assets/img/eye_no.svg'
import eye_yes from '../../../assets/img/eye_yes.svg'
import streaming_img from '../../../assets/img/streaming.svg'

import copyright_icon from '../../../assets/img/copyright-violation.svg'
import up_icon from '../../../assets/img/upward-arrow.svg'
import analytics_img from '../../../assets/img/analytics.svg'
import orange_plus from '../../../assets/img/orange_plus.svg'
import orange_minus from '../../../assets/img/orange_minus.svg'
import paste_img from '../../../assets/img/paste-file.svg'
import scroll_img from '../../../assets/img/scroll.svg'
import copy_q from '../../../assets/img/copy_q.svg'
import close_x from '../../../assets/img/close_e.svg'
import edit_icon from '../../../assets/img/edit_icon.svg'
import ExercisePanel from "../../exercises/panels/ExercisePanel";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";
import TestsListInfoTool from "../../tests/tools/TestsListInfoTool";
import * as actions from "../../../redux/actions/CopyPasterActions";
import {useDispatch} from "redux-react-hook";

import Toggle from 'react-toggle'

import './style.css'
import AddItemTool from "./AddItemTool";
import TreadmillsListViewTool from "../../treadmills_exercises/panels/TreadmillsListViewTool";
import {translate} from "../../../utils/Translate";
import SlideForm from "../forms/SlideForm";
import {AddComponent} from "../../lessons/tools/TaskEditor";
import NiceModal from "../../modals/NiceModal";
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";
import TextNlpTool from "../../nlp/tools/TextNlpTool";
import {saveBasketItem} from "../../basket/ContentBasketPanel";
import DialogExercisePanel from "../../dialogs/panels/DialogExercisePanel";
import LottieLoader from "../../loaders/LottieLoader";
import treadmills_lottie from "../../../assets/lottie/female-employee-stretching-in-chair.json";
import PasteTool from "../../lessons/tools/PasteTool";
import QuestionnairePanel from "../../questionnaires/panels/QuestionnairePanel";
import SlideBackupsPanel from "../panels/SlideBackupsPanel";
import SlidesAPI from "../../../api/SlidesAPI";
import toast from "react-hot-toast";
import * as slidesActions from "../../../redux/actions/SlidesActions";
import * as containersActions from "../../../redux/actions/ContainersActions";
import PrivateContainerFolderSelectorPanel from "../../containers/panels/PrivateContainerFolderSelectorPanel";
import OccupiedInfoTool from "./OccupiedInfoTool";
import ElementsEditorForm from "./ElementsEditorForm";
import UIHelper from "../../../helpers/UIHelper";
import DownloadMp3Span from "../../mp3/tools/DownloadMp3Span";
import ImageViewer from "react-simple-image-viewer";
import CommonHelper from "../../../helpers/CommonHelper";

const TABS = [
    {
        label: UIHelper.isProUniMode() ? 'Юнит' : 'Модуль',
        value: 'items'
    },
    {
        label: 'Упражнения',
        value: 'exercises'
    },
    {
        label: 'Карточки',
        value: 'cards'
    },
    {
        label: 'Тредмилы',
        value: 'treadmills'
    },
    {
        label: 'Опросники',
        value: 'questionnaires'
    },
    {
        label: 'Диалоги',
        value: 'dialogs'
    },
    {
        label: 'Тесты',
        value: 'tests'
    }
]

export default function ViewSlideTool(props) {
    const {
        id,
        canSeeCopyrightWarning = false,
        buffer,
        selectedTaskId,
        isOnlineMode = false,
        onlineHiddenItemsIds = [],
        showMoveToMeButton = false,
        loading = false,
        currentUserRole = 'teacher',
        isModerator,

        onMoveToBlockClick = divId => {

        },

        onOnlineEyeToggle = eId => {

        },

        onTranscriptOnlineEyeToggle = eId => {

        },


        onSyncVideoSelect = videoUrl => {

        },

        canEdit = true,
        canShare = true,

        isOccupied = false,
        canToggleOccupy = false,

        onOccupyToggle = () => {

        },


        items = [],
        exercisesIds = [],
        drochkaExercisesIds = [],
        testsIds = [],
        treadmillsIds = [],
        dialogsIds = [],
        questionnairesIds = [],

        canSwitchTabs = true,

        useTranslatableTranscript = true,
        canCopyToBuffer = false,

        onCopySlideItemToBuffer = slideItem => {

        },

        onCopyExerciseToBuffer = exId => {

        },

        onCopyDrochkaExerciseToBuffer = exId => {

        },

        onCopyTestToBuffer = testId => {

        },

        onCopyTreadmillToBuffer = exId => {

        },

        onEditItemClick = editItemId => {

        },

        canDeleteItem = false,
        onChange = d => {

        },
        canPasteIntoItem = false,
        creatorMode = false,
        onDelete = () => {

        },
        onRecover = () => {

        },
        canRecover = false,
        userId,

        occupantId,
        currentUserId,

        prouni,
        proUniGoalsText

    } = props;

    const dispatch = useDispatch();
    const [tab, setTab] = useState('items'); // items / exercises / cards / dialogs / questionnaires / treadmills
    let numMap = {
        exercises: exercisesIds.length,
        cards: drochkaExercisesIds.length,
        tests: testsIds.length,
        treadmills: treadmillsIds.length,
        dialogs: dialogsIds.length,
        questionnaires: questionnairesIds.length,
        items: items.length
    }
    // let realTabs = TABS.filter(x => numMap[x.value] > 0);
    let realTabs = TABS.filter(x => numMap[x.value] > ((creatorMode == true || canEdit == true) ? -1 : 0));

    useEffect(() => {
        setTab('items');
    }, [id]);

    const [expandedItemId, setExpandedItemId] = useState();
    const [addingMode, setAddingMode] = useState();
    const [analyticsTranscript, setAnalyticsTranscript] = useState();
    const [recoverVisible, setRecoverVisible] = useState(false);

    const [previewImageUrl, setPreviewImageUrl] = useState(undefined);

    realTabs = realTabs.concat([{
        label: translate('settings'),
        value: 'settings'
    }]);

    if (creatorMode == true || (window?.location?.href || '').indexOf('petr') > -1 || isModerator == true) {
        realTabs = realTabs.concat([{
            label: translate('backups'),
            value: 'backups'
        }]);
        realTabs = realTabs.concat([{
            label: translate('elements'),
            value: 'elements'
        }]);
    }

    // if (UIHelper.isProUniMode() == true){
    //     realTabs = realTabs.concat([{
    //         label: 'ProUni',
    //         value: 'prouni'
    //     }]);
    // }


    const allSelIds = exercisesIds.concat(dialogsIds).concat(questionnairesIds).concat(drochkaExercisesIds).concat(testsIds).concat(treadmillsIds);

    // console.log('ViewSlideTool: canEdit = ', canEdit);
    // console.log('render: realTabs = ', realTabs);
    // console.log('ViewSlideTool: render: items = ', items);
    // console.log('ViewSlideTool: render: allSelIds = ', allSelIds);
    // console.log('ViewSlideTool: render: props.user = ', props.user);


    return (
        <Wrapper>

            {isOccupied == false || occupantId == currentUserId ? null :
                <OccupiedInfoTool occupantId={occupantId}/>
            }

            {canSwitchTabs == false ? null :
                <TopTabsPlaceholder>
                    {realTabs.map((a, i) => {
                        let num = numMap[a.value];
                        return (
                            <TabItem key={a.value}
                                     selected={a.value == tab}
                                     onClick={() => {
                                         setTab(a.value);
                                     }}>
                                {a.label}
                                {(num == undefined || num == 0 || a.value == 'items') ? null :
                                    <span style={{opacity: 0.6}}>
                                    {` (${num})`}
                                </span>
                                }
                            </TabItem>
                        )
                    })}

                </TopTabsPlaceholder>
            }


            {tab != 'items' ? null :
                <ItemsListPlaceholder>

                    {canToggleOccupy == false ? null :
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            paddingTop: 5,
                            paddingBottom: 5,
                            opacity: (loading == true ? 0.4 : 1)
                        }}
                        >
                            <Toggle checked={isOccupied} onChange={() => {
                                if (loading == true) {
                                    return;
                                }
                                onOccupyToggle();
                            }}/>
                            <span style={{marginLeft: 5}} onClick={() => {
                                if (loading == true) {
                                    return;
                                }
                                onOccupyToggle();
                            }}>
                                {' occupied'}
                            </span>
                        </div>
                    }

                    {/*{JSON.stringify(prouni)}*/}
                    {(UIHelper.isProUniMode() == false || prouni?.unit == undefined) ? null :
                        <div>
                            <ModBaA>
                                {`Unit: ${prouni?.unit}`}
                                <br/>
                                {`Theme: ${prouni?.theme}`}
                                <br/>
                                {`Module: ${prouni?.module}`}
                                <br/>
                                {`Level: ${prouni?.level}`}
                                <br/>
                                <GoalP>
                                    {proUniGoalsText}
                                </GoalP>
                            </ModBaA>

                        </div>
                    }

                    {items.map((a, i) => {
                        let isHidden = (isOnlineMode == true && onlineHiddenItemsIds.indexOf(a.id) > -1 && showMoveToMeButton == false);
                        let isExpanded = (a?.id == expandedItemId);
                        let copX = a?.content?.copyrightDescription || a?.content?.copyrightUrl;
                        let isExercise = (['exercise', 'dialog', 'drochka', 'test', 'questionnaire', 'treadmill'].indexOf(a?.type) > -1);

                        return (
                            <Item key={`${a.id}_${i}`} id={`item_${a.id}`}
                                  hidden={isHidden}
                            >
                                {canCopyToBuffer == false || canEdit == true ? null :
                                    <BufferControlsPlaceholder className={'buffer_controls_placeholder'}>
                                        <CopyImg src={copy_q}
                                                 onClick={() => {
                                                     onCopySlideItemToBuffer(a);
                                                     saveBasketItem(a.type, a.content);
                                                 }}
                                        />
                                    </BufferControlsPlaceholder>
                                }

                                {canEdit == false ? null :
                                    <BufferControlsPlaceholder className={'delete_controls_placeholder'}>

                                        {canCopyToBuffer == false ? null :
                                            <CopyImg src={copy_q}
                                                     onClick={() => {
                                                         onCopySlideItemToBuffer(a);
                                                         saveBasketItem(a.type, a.content);
                                                     }}
                                            />
                                        }

                                        {i == 0 ? null :
                                            <CopyImg src={up_icon}
                                                     onClick={() => {
                                                         let i1 = i - 1, i2 = i;
                                                         let newItems = items.slice(0, i1).concat(items[i2], items.slice(i1 + 1, i2), items[i1], items.slice(i2 + 1));
                                                         onChange({items: newItems});
                                                     }}
                                            />
                                        }

                                        {(i == items.length - 1) ? null :
                                            <CopyImg src={up_icon}
                                                     style={{transform: 'rotate(180deg)'}}
                                                     onClick={() => {
                                                         let i1 = i, i2 = i + 1;
                                                         let newItems = items.slice(0, i1).concat(items[i2], items.slice(i1 + 1, i2), items[i1], items.slice(i2 + 1));
                                                         onChange({items: newItems});
                                                     }}
                                            />
                                        }

                                        {(['text', 'image', 'video', 'audio'].indexOf(a.type) == -1) ? null :
                                            <CopyImg src={edit_icon}
                                                     onClick={() => {
                                                         onEditItemClick(a.id);
                                                     }}
                                            />
                                        }

                                        {a?.type != 'video' ? null :
                                            <DownloadMp3Span
                                                {...a}
                                                url={a?.content?.url}
                                            />
                                        }

                                        {/*{(['video'].indexOf(a.type) == -1 || a?.content?.transcript == undefined || a?.content?.transcript == '') ? null :*/}
                                        {/*    <CopyImg src={analytics_img}*/}
                                        {/*             onClick={() => {*/}
                                        {/*                 setAnalyticsTranscript(a?.content?.transcript);*/}
                                        {/*             }}*/}
                                        {/*    />*/}
                                        {/*}*/}


                                        {canDeleteItem == false && canEdit == false ? null :
                                            <CopyImg src={close_x}
                                                     style={{marginLeft: 5}}
                                                     onClick={() => {
                                                         let newItems = items.filter(b => (b.id != a.id));
                                                         onChange({items: newItems});
                                                     }}
                                            />
                                        }
                                    </BufferControlsPlaceholder>
                                }

                                {(canSeeCopyrightWarning == false || !((a?.type == 'video') && (copX == undefined || copX == ''))) ? null :
                                    <BufferControlsPlaceholder
                                        style={{display: 'block', top: 'auto', bottom: 15, background: '#ffc02c36'}}>
                                        <CopyImg
                                            className={'blink_me'}
                                            src={copyright_icon}
                                            style={{marginLeft: 5, height: 32, width: 32}}
                                            onClick={() => {

                                            }}
                                        />
                                    </BufferControlsPlaceholder>
                                }


                                {showMoveToMeButton == false ? null :
                                    <BufferControlsPlaceholder className={'delete_controls_placeholder'}
                                                               style={{left: 0, right: 'auto'}}
                                    >

                                        <CopyImg src={scroll_img}
                                                 onClick={() => {
                                                     onMoveToBlockClick(`item_${a.id}`);
                                                 }}
                                        />

                                        {a.type != 'video' ? null :
                                            <CopyImg src={streaming_img}
                                                     style={{width: 18, height: 18, marginRight: 10}}
                                                     onClick={() => {
                                                         onSyncVideoSelect(a?.content?.url);
                                                     }}
                                            />
                                        }

                                    </BufferControlsPlaceholder>
                                }

                                {showMoveToMeButton == false ? null :
                                    <BufferControlsPlaceholder
                                        style={{display: 'block'}}
                                        className={'visibility_eye_controls_placeholder'}>

                                        <CopyImg src={onlineHiddenItemsIds.indexOf(a.id) > -1 ? eye_no : eye_yes}
                                                 style={{width: 14, height: 14}}
                                                 onClick={() => {
                                                     onOnlineEyeToggle(a.id);
                                                 }}
                                        />

                                    </BufferControlsPlaceholder>
                                }

                                <SlideContentItemView
                                    currentUserRole={currentUserRole}
                                    {...props}
                                    canToggleTranscriptEye={showMoveToMeButton}
                                    isHidden={isHidden}
                                    canShare={canShare}
                                    selectedTaskId={selectedTaskId}
                                    moduleId={id}
                                    useTranslatableTranscript={useTranslatableTranscript}
                                    onTranscriptOnlineEyeToggle={eId => {

                                    }}
                                    canEdit={canEdit}
                                    onExerciseEditClick={() => {

                                    }}
                                    onImageClick={imUrl => {
                                        setPreviewImageUrl(imUrl);
                                    }}
                                    {...a}
                                />

                                {(canPasteIntoItem == false || !((buffer?.slideItem != undefined || buffer?.exerciseId != undefined || buffer?.drochkaExerciseId != undefined))) ? null :
                                    <BetweenPasterPlaceholder className={''}>
                                        <CopyImg src={paste_img}
                                                 onClick={() => {
                                                     console.log('props.buffer?.slideItem = ', buffer?.slideItem);
                                                     let exSlideItem = (buffer?.exerciseId == undefined) ? undefined : {
                                                         type: 'exercise',
                                                         exerciseId: buffer?.exerciseId
                                                     };
                                                     let drochkaExSlideItem = (buffer?.drochkaExerciseId == undefined) ? undefined : {
                                                         type: 'drochka',
                                                         exerciseId: buffer?.drochkaExerciseId
                                                     };
                                                     let newItem = buffer?.slideItem || exSlideItem || drochkaExSlideItem;
                                                     let newItems = items.slice(0, i + 1).concat([
                                                         {
                                                             ...newItem,
                                                             id: `bf${+new Date()}`
                                                         }
                                                     ]).concat(items.slice(i + 1));
                                                     onChange({items: newItems});
                                                     dispatch(actions.copyToBuffer('SLIDE_ITEM', null));
                                                     dispatch(actions.copyToBuffer('EXERCISE', null));
                                                     dispatch(actions.copyToBuffer('DROCHKA_EXERCISE', null));
                                                 }}
                                        />
                                    </BetweenPasterPlaceholder>
                                }

                                {isExpanded == false ? null :
                                    <BetweenEditPlaceholder>
                                        <AddItemTool
                                            topItemId={expandedItemId}
                                            items={items}
                                            onItemsChange={newItems => {
                                                onChange({items: newItems});
                                                setExpandedItemId(undefined);
                                            }}
                                        />
                                    </BetweenEditPlaceholder>
                                }

                                {((canEdit == false) || (+i >= items.length - 1)) ? null :
                                    <EditPlusPlaceholder>
                                        <EditPlusDiv
                                            selected={(a?.id == expandedItemId)}
                                            onClick={() => {
                                                if (a?.id == expandedItemId) {
                                                    setExpandedItemId(undefined);
                                                } else {
                                                    setExpandedItemId(a?.id);
                                                }
                                            }}>
                                            <EditPlus
                                                src={a?.id == expandedItemId ? orange_minus : orange_plus}></EditPlus>
                                        </EditPlusDiv>
                                    </EditPlusPlaceholder>
                                }


                            </Item>
                        )
                    })}

                    {canEdit == false ? null :
                        <BetweenEditPlaceholder>
                            <AddItemTool
                                topItemId={expandedItemId}
                                items={items}
                                onItemsChange={newItems => {
                                    console.log('AddItemTool: onItemsChange: newItems = ', newItems);
                                    onChange({items: newItems});
                                }}
                            />
                        </BetweenEditPlaceholder>
                    }

                </ItemsListPlaceholder>
            }

            {tab != 'exercises' ? null :
                <ItemsListPlaceholder>

                    {exercisesIds.map((exId, i) => {
                        return (
                            <ExItem key={`${exId}_${i}`}>
                                {canCopyToBuffer == false || creatorMode == true ? null :
                                    <BufferControlsPlaceholder className={'buffer_controls_placeholder'}>
                                        <CopyImg src={copy_q}
                                                 onClick={() => {
                                                     onCopyExerciseToBuffer(exId);
                                                 }}
                                        />
                                    </BufferControlsPlaceholder>
                                }
                                {canEdit == false ? null :
                                    <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>
                                        {i == 0 ? null :
                                            <CopyImg src={up_icon}
                                                     onClick={() => {
                                                         let i1 = i - 1, i2 = i;
                                                         let newIds = exercisesIds.slice(0, i1).concat(exercisesIds[i2], exercisesIds.slice(i1 + 1, i2), exercisesIds[i1], exercisesIds.slice(i2 + 1));
                                                         onChange({exercisesIds: newIds});
                                                     }}
                                            />
                                        }

                                        {(i == exercisesIds.length - 1) ? null :
                                            <CopyImg src={up_icon}
                                                     style={{transform: 'rotate(180deg)'}}
                                                     onClick={() => {
                                                         let i1 = i, i2 = i + 1;
                                                         let newIds = exercisesIds.slice(0, i1).concat(exercisesIds[i2], exercisesIds.slice(i1 + 1, i2), exercisesIds[i1], exercisesIds.slice(i2 + 1));
                                                         onChange({exercisesIds: newIds});
                                                     }}
                                            />
                                        }
                                        <CopyImg src={close_x}
                                                 onClick={() => {
                                                     let newIds = exercisesIds.filter(b => (b != exId));
                                                     onChange({
                                                         exercisesIds: newIds
                                                     })
                                                 }}
                                        />
                                    </DeleteControlsPlaceholder>
                                }
                                <ExercisePanel
                                    {...props}
                                    canShare={canShare}
                                    id={exId}
                                />
                            </ExItem>
                        )
                    })}

                    {canEdit == false ? null :
                        <AddingCell onClick={() => {
                            setAddingMode('exercises');
                        }}>
                            <AddComponent text={'Добавить'}/>
                        </AddingCell>
                    }


                </ItemsListPlaceholder>
            }

            {tab != 'cards' ? null :
                <ItemsListPlaceholder>

                    {drochkaExercisesIds.map((exId, i) => {
                        return (
                            <ExItem key={`${exId}_${i}`}>
                                {canCopyToBuffer == false || creatorMode == true ? null :
                                    <BufferControlsPlaceholder className={'buffer_controls_placeholder'}>
                                        <CopyImg src={copy_q}
                                                 onClick={() => {
                                                     onCopyDrochkaExerciseToBuffer(exId);
                                                 }}
                                        />
                                    </BufferControlsPlaceholder>
                                }
                                {canEdit == false ? null :
                                    <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>

                                        {i == 0 ? null :
                                            <CopyImg src={up_icon}
                                                     onClick={() => {
                                                         let i1 = i - 1, i2 = i;
                                                         let newIds = drochkaExercisesIds.slice(0, i1).concat(drochkaExercisesIds[i2], drochkaExercisesIds.slice(i1 + 1, i2), drochkaExercisesIds[i1], drochkaExercisesIds.slice(i2 + 1));
                                                         onChange({drochkaExercisesIds: newIds});
                                                     }}
                                            />
                                        }

                                        {(i == drochkaExercisesIds.length - 1) ? null :
                                            <CopyImg src={up_icon}
                                                     style={{transform: 'rotate(180deg)'}}
                                                     onClick={() => {
                                                         let i1 = i, i2 = i + 1;
                                                         let newIds = drochkaExercisesIds.slice(0, i1).concat(drochkaExercisesIds[i2], drochkaExercisesIds.slice(i1 + 1, i2), drochkaExercisesIds[i1], drochkaExercisesIds.slice(i2 + 1));
                                                         onChange({drochkaExercisesIds: newIds});
                                                     }}
                                            />
                                        }

                                        <CopyImg src={close_x}
                                                 onClick={() => {
                                                     let newIds = drochkaExercisesIds.filter(b => (b != exId));
                                                     onChange({
                                                         drochkaExercisesIds: newIds
                                                     })
                                                 }}
                                        />
                                    </DeleteControlsPlaceholder>
                                }
                                <DrochkaExercisePanel
                                    {...props}
                                    id={exId}
                                />
                            </ExItem>
                        )
                    })}

                    {canEdit == false ? null :
                        <AddingCell onClick={() => {
                            setAddingMode('cards');
                        }}>
                            <AddComponent text={'Добавить'}/>
                        </AddingCell>
                    }

                </ItemsListPlaceholder>
            }

            {tab != 'dialogs' ? null :
                <div>

                    {dialogsIds.map((exId, i) => {
                        return (
                            <ExItem key={`${exId}_${i}`}>

                                {canEdit == false ? null :
                                    <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>

                                        {i == 0 ? null :
                                            <CopyImg src={up_icon}
                                                     onClick={() => {
                                                         let i1 = i - 1, i2 = i;
                                                         let newIds = dialogsIds.slice(0, i1).concat(dialogsIds[i2], dialogsIds.slice(i1 + 1, i2), dialogsIds[i1], dialogsIds.slice(i2 + 1));
                                                         onChange({dialogsIds: newIds});
                                                     }}
                                            />
                                        }

                                        {(i == dialogsIds.length - 1) ? null :
                                            <CopyImg src={up_icon}
                                                     style={{transform: 'rotate(180deg)'}}
                                                     onClick={() => {
                                                         let i1 = i, i2 = i + 1;
                                                         let newIds = dialogsIds.slice(0, i1).concat(dialogsIds[i2], dialogsIds.slice(i1 + 1, i2), dialogsIds[i1], dialogsIds.slice(i2 + 1));
                                                         onChange({dialogsIds: newIds});
                                                     }}
                                            />
                                        }

                                        <CopyImg src={close_x}
                                                 onClick={() => {
                                                     let newIds = dialogsIds.filter(b => (b != exId));
                                                     onChange({
                                                         dialogsIds: newIds
                                                     })
                                                 }}
                                        />
                                    </DeleteControlsPlaceholder>
                                }

                                <DialogExercisePanel
                                    {...props}
                                    userId={userId}
                                    id={exId}
                                />
                            </ExItem>
                        )
                    })}

                    {dialogsIds.length > 0 ? null :
                        <VoidListLottiePlaceholder>
                            <LottieLoader json={treadmills_lottie}/>
                            <VoidListTextPlaceholder>
                                В данном задании ещё нет диалогов.
                            </VoidListTextPlaceholder>
                        </VoidListLottiePlaceholder>
                    }

                    {canEdit == false ? null :
                        <BottomAddingRow>
                            <PasteTool
                                type={'DIALOG'}
                                onTreadmillIdAdd={exId => {
                                    let newIds = dialogsIds.concat([exId]);
                                    onChange({
                                        dialogsIds: newIds
                                    })
                                }}
                            />
                            <AddingCell onClick={() => {
                                setAddingMode('dialogs');
                            }}>
                                <AddComponent text={'Добавить'}/>
                            </AddingCell>
                        </BottomAddingRow>

                    }

                </div>
            }

            {tab != 'questionnaires' ? null :
                <div>

                    {questionnairesIds.map((exId, i) => {
                        return (
                            <ExItem key={`${exId}_${i}`}>

                                {canEdit == false ? null :
                                    <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>

                                        {i == 0 ? null :
                                            <CopyImg src={up_icon}
                                                     onClick={() => {
                                                         let i1 = i - 1, i2 = i;
                                                         let newIds = questionnairesIds.slice(0, i1).concat(questionnairesIds[i2], questionnairesIds.slice(i1 + 1, i2), questionnairesIds[i1], questionnairesIds.slice(i2 + 1));
                                                         onChange({questionnairesIds: newIds});
                                                     }}
                                            />
                                        }

                                        {(i == questionnairesIds.length - 1) ? null :
                                            <CopyImg src={up_icon}
                                                     style={{transform: 'rotate(180deg)'}}
                                                     onClick={() => {
                                                         let i1 = i, i2 = i + 1;
                                                         let newIds = questionnairesIds.slice(0, i1).concat(questionnairesIds[i2], questionnairesIds.slice(i1 + 1, i2), questionnairesIds[i1], questionnairesIds.slice(i2 + 1));
                                                         onChange({questionnairesIds: newIds});
                                                     }}
                                            />
                                        }

                                        <CopyImg src={close_x}
                                                 onClick={() => {
                                                     let newIds = questionnairesIds.filter(b => (b != exId));
                                                     onChange({
                                                         questionnairesIds: newIds
                                                     })
                                                 }}
                                        />
                                    </DeleteControlsPlaceholder>
                                }

                                <QuestionnairePanel
                                    {...props}
                                    userId={userId}
                                    id={exId}
                                />
                            </ExItem>
                        )
                    })}

                    {dialogsIds.length > 0 ? null :
                        <VoidListLottiePlaceholder>
                            <LottieLoader json={treadmills_lottie}/>
                            <VoidListTextPlaceholder>
                                В данном задании ещё нет опросников.
                            </VoidListTextPlaceholder>
                        </VoidListLottiePlaceholder>
                    }

                    {canEdit == false ? null :
                        <BottomAddingRow>
                            <PasteTool
                                type={'QUESTIONNAIRE'}
                                onTreadmillIdAdd={exId => {
                                    let newIds = questionnairesIds.concat([exId]);
                                    onChange({
                                        questionnairesIds: newIds
                                    })
                                }}
                            />
                            <AddingCell onClick={() => {
                                setAddingMode('questionnaires');
                            }}>
                                <AddComponent text={'Добавить'}/>
                            </AddingCell>
                        </BottomAddingRow>

                    }

                </div>
            }

            {tab != 'treadmills' ? null :
                <ItemsListPlaceholder key={treadmillsIds.join('_')}>


                    {treadmillsIds.length > 0 ?
                        <TreadmillsListViewTool
                            {...props}
                            canEdit={canEdit}
                            canDelete={canEdit}
                            canCopy={!creatorMode}
                            selectedTaskId={selectedTaskId}
                            ids={treadmillsIds}
                            onCopy={tId => {
                                onCopyTreadmillToBuffer(tId);
                            }}
                            onDelete={delId => {
                                let newTreadmillsIds = treadmillsIds.filter(x => (x != delId));
                                onChange({
                                    treadmillsIds: newTreadmillsIds
                                });
                            }}
                        />
                        :
                        <VoidListLottiePlaceholder>
                            <LottieLoader json={treadmills_lottie}/>
                            <VoidListTextPlaceholder>
                                В данном задании ещё нет тредмилов.
                            </VoidListTextPlaceholder>
                        </VoidListLottiePlaceholder>
                    }


                    {canEdit == false ? null :
                        <BottomAddingRow>
                            <PasteTool
                                type={'TREADMILL'}
                                onTreadmillIdAdd={exId => {
                                    let newIds = treadmillsIds.concat([exId]);
                                    onChange({
                                        treadmillsIds: newIds
                                    });
                                }}
                            />
                            <AddingCell onClick={() => {
                                setAddingMode('treadmills');
                            }}>
                                <AddComponent text={'Добавить'}/>
                            </AddingCell>
                        </BottomAddingRow>

                    }

                </ItemsListPlaceholder>
            }

            {tab != 'tests' ? null :

                <ItemsListPlaceholder>
                    <TestsListInfoTool
                        {...props}
                        canEdit={canEdit}
                        canDelete={canEdit}
                        canCopyTest={!creatorMode}
                        ids={testsIds}
                        onCopy={tId => {
                            onCopyTestToBuffer(tId);
                        }}
                        onDelete={delId => {
                            let newIds = testsIds.filter(b => (b != delId));
                            onChange({
                                testsIds: newIds
                            })
                        }}
                    />

                    {canEdit == false ? null :
                        <AddingCell onClick={() => {
                            setAddingMode('tests');
                        }}>
                            <AddComponent text={'Добавить'}/>
                        </AddingCell>
                    }


                </ItemsListPlaceholder>
            }

            {tab != 'settings' ? null :
                <ItemsListPlaceholder>
                    <SlideForm
                        canEdit={(creatorMode == true || (window?.location?.href || '').indexOf('petr') > -1 || isModerator == true)}
                        {...props}
                        loading={loading}
                        canDelete={canEdit}
                        onDelete={() => {
                            onDelete();
                        }}
                        onRecover={() => {
                            setRecoverVisible(true);
                        }}
                        onSave={d => {
                            onChange({...d});
                        }}
                    />

                </ItemsListPlaceholder>
            }

            {tab != 'backups' ? null :
                <ItemsListPlaceholder>
                    <SlideBackupsPanel {...props}/>
                </ItemsListPlaceholder>
            }

            {tab != 'elements' ? null :
                <ItemsListPlaceholder>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <ElementsEditorForm
                            {...props}
                            onChange={arr => {
                                onChange({elements: arr});
                            }}
                        />
                    </div>
                </ItemsListPlaceholder>
            }




            {addingMode == undefined ? null :
                <NiceModal onClose={() => {
                    setAddingMode(undefined);
                }}>

                    <ItemEditModalInner
                        style={{height: `calc(100vh - 120px)`}}
                    >
                        <SearchOrCreateMaterialsPanel
                            type={addingMode}
                            ids={allSelIds}
                            onSelect={x => {
                                if (addingMode == 'exercises') {
                                    let newIds = exercisesIds.concat([x.id]);
                                    onChange({
                                        exercisesIds: newIds
                                    })
                                }
                                if (addingMode == 'dialogs') {
                                    let newIds = dialogsIds.concat([x.id]);
                                    onChange({
                                        dialogsIds: newIds
                                    })
                                }
                                if (addingMode == 'questionnaires') {
                                    let newIds = questionnairesIds.concat([x.id]);
                                    onChange({
                                        questionnairesIds: newIds
                                    })
                                }
                                if (addingMode == 'cards') {
                                    let newIds = drochkaExercisesIds.concat([x.id]);
                                    onChange({
                                        drochkaExercisesIds: newIds
                                    })
                                }
                                if (addingMode == 'tests') {
                                    let newIds = testsIds.concat([x.id]);
                                    onChange({
                                        testsIds: newIds
                                    })
                                }
                                if (addingMode == 'treadmills') {
                                    let newIds = treadmillsIds.concat([x.id]);
                                    onChange({
                                        treadmillsIds: newIds
                                    })
                                }
                                // setAddingMode(undefined);
                            }}
                        />
                    </ItemEditModalInner>

                </NiceModal>
            }

            {analyticsTranscript == undefined ? null :
                <NiceModal onClose={() => {
                    setAnalyticsTranscript(undefined);
                }}>

                    <AnalyticsTranscriptPlaceholder
                        style={{height: `calc(100vh - 120px)`}}
                    >

                        <TextNlpTool
                            text={analyticsTranscript}
                        />

                    </AnalyticsTranscriptPlaceholder>

                </NiceModal>
            }

            {recoverVisible == false ? null :
                <NiceModal onClose={() => {
                    setRecoverVisible(false);
                }}>

                    <p>
                        Выберите в какую папку вы хотите восстановить модуль
                    </p>

                    <PrivateContainerFolderSelectorPanel
                        onFolderSelect={cId => {
                            onChange({isDeleted: false, recoverContainerId: cId});
                            setRecoverVisible(false);
                        }}
                    />
                </NiceModal>
            }

            {previewImageUrl && <ImageViewer
                src={[previewImageUrl]}
                onClose={() => {
                    setPreviewImageUrl(undefined);
                }}
                disableScroll={false}
                backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)"
                }}
                closeOnClickOutside={true}
            />}

        </Wrapper>
    );
}

const GoalP = styled.div`
  margin-top: 20px;
  font-style: italic;
  margin-bottom: 15px;
`;

const ModBaA = styled.div`
  background: rgba(0, 114, 188, 0.35);
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  font-style: italic;
  margin-bottom: 40px;
`;

const BottomAddingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


const VoidListLottiePlaceholder = styled.div`
  width: 100%;
  height: 320px;

  @media (max-width: 720px) {
    width: 90%;
  }
`;

const VoidListTextPlaceholder = styled.div`
  text-align: center;
  margin-top: -50px;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
`;


const AnalyticsTranscriptPlaceholder = styled.div`
  width: 1200px;
  box-sizing: border-box;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  @media (max-width: 1280px) {
    width: calc(100vw - 80px);
  }
`;

const DeleteControlsPlaceholder = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  right: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.75);
  padding: 5px;
`;

const ItemEditModalInner = styled.div`
  width: 1200px;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: calc(100vw - 80px);
  }
`;

const AddingCell = styled.div`
  margin-left: 7px;
  margin-right: 7px;
`;

const EditPlusPlaceholder = styled.div`
  position: relative;
`;

const EditPlusDiv = styled.div`
  position: absolute;
  right: -28px;
  bottom: -15px;
  width: 24px;
  height: 24px;
  background: ${props => (props.selected == true ? '#ffe6b7' : 'white')};
  border: 2px solid #FFA71B;
  border-radius: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;

  :hover {
    background: #faf4ea;
  }
`;

const EditPlus = styled.img`
  width: 15px;
  height: 15px;
`;

const BetweenPasterPlaceholder = styled.div`
  border: 1px dashed whitesmoke;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 4px;

  :hover {
    background: whitesmoke;
  }
`;

const BetweenEditPlaceholder = styled.div`
  border: 1px dashed whitesmoke;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 4px;

  background: #fcfcfc;

  :hover {

  }
`;


const ExItem = styled.div`
  margin-bottom: 15px;
  position: relative;

  :hover .buffer_controls_placeholder {
    display: block;
  }

  :hover .delete_controls_placeholder {
    display: block;
  }
`;

const CopyImg = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const BufferControlsPlaceholder = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  //z-index: 0;
  right: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.75);
  padding: 5px;
`;

const PasterControlsPlaceholder = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 0px;
  background: pink;
  background: rgba(255, 255, 255, 0.75);
  padding: 5px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const ItemsListPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Item = styled.div`
  padding-bottom: 5px;
  //padding-top: 5px;
  border-bottom: 1px solid rgba(133, 147, 168, 0.06);
  position: relative;

  :hover .buffer_controls_placeholder {
    display: block;
  }

  :hover .delete_controls_placeholder {
    display: block;
  }

  :hover .paste_controls_placeholder {
    display: block;
  }

  opacity: ${props => (props.hidden == true ? 0 : 1)};

`;

const TopTabsPlaceholder = styled.div`
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #fcfcfc;
  
  @media (max-width: 720px){
    margin-left: 7%;
  }
`;

const TabItem = styled.div`
  margin-left: 7px;
  margin-right: 7px;
  border-bottom: ${props => (props.selected == true ? '4px solid #FFC02C' : '4px solid transparent')};
  padding-bottom: 5px;
  cursor: pointer;
  font-size: ${props => (props.selected == true ? '14px' : '12px')};
  display: inline-block;

  :first-of-type {
    margin-left: 0px;
  }

  :hover {
    opacity: 0.8;
  }
`;