import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import UpdateProfileForm from "../forms/UpdateProfileForm";
import * as actions from '../../../redux/actions/UsersActions'
import {API_ENDPOINT} from "../../../constants/config";

export default function UpdateProfilePanel(props) {
    const {
        userId
    } = props;

    const {
        user,
        loading,
        uId
    } = useMappedState(useCallback(state => {
        let uId = userId || state.users.currentUserId;
        return {
            user: state.users.usersMap.get(uId),
            loading: state.users.loading,
            uId: uId
        }
    }));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadUserById(uId));
    }, [uId]);

    console.log('UpdateProfilePanel: render: ', user);


    return (
        <Wrapper>
            <UpdateProfileForm
                {...user}
                loading={loading}
                onSave={async d => {
                    await dispatch(actions.updateUser({
                        id: uId,
                        ...d
                    }));
                }}
            />

            <div style={{marginTop: 20}}>
                <span style={{cursor: 'pointer', color: 'red'}} onClick={async () => {
                    let pwd = window.prompt('Новый пароль');
                    if (pwd == undefined || pwd.length < 6) {
                        return window.alert('Пароль слишком короткий');
                    }
                    await axios.post(`${API_ENDPOINT}/change-my-password`, {
                        password: pwd
                    }, {
                        params: {
                            userId: userId
                        }
                    });
                    window.alert('Пароль изменен');
                }}>
                    сменить пароль
                </span>
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;