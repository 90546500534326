import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'
import axios from 'axios'
import StudentsList from "../../students/list/StudentsList";
import CommonHelper from "../../../helpers/CommonHelper";
import NiceModal from "../../modals/NiceModal";
import {Textarea, Input} from "../../ira/ui/Inputs";
import {API_ENDPOINT} from "../../../constants/config";

import validator from 'validator'
import shareImg from '../../../assets/img/share.svg'

import * as actions from '../../../redux/actions/GroupsActions'
import * as usersActions from '../../../redux/actions/UsersActions'
import MoodleSelectUserPanel from "../../moodle/panels/MoodleSelectUserPanel";

import no_users_woman_lottie from '../../../assets/lottie/no_users_lottie.json'
import LottieLoader from "../../loaders/LottieLoader";
import {BlueButton} from "../../ira/ui/Buttons";
import {Code} from "react-content-loader";
import LogoDiv from "../../templates/LogoDiv";
import StudentGroupLessonsPanel from "../../lessons/panels/StudentGroupLessonsPanel";
import NiceConfirm from "../../modals/NiceConfirm";
import StudentTasksPanel from "../../lessons/panels/StudentTasksPanel";
import UsersAPI from "../../../api/UsersAPI";
import VerfactoAnalyticsPanel from "../../verfacto/panels/VerfactoAnalyticsPanel";
import UpdateProfilePanel from "../../profile/panels/UpdateProfilePanel";
import select_circle from "../../../assets/img/select-circle-area.svg";

import Select from 'react-select'
import UserVocabularyPanel from "../../vocabulary/panels/UserVocabularyPanel";
import SimpleGroupOrStudentAnalyticsPanel from "../../analytics/panels/SimpleGroupOrStudentAnalyticsPanel";
import {translate} from "../../../utils/Translate";
import UIHelper from "../../../helpers/UIHelper";

export default function TeacherGroupStudentPanel(props) {
    const {
        id,
        studentId
    } = props;

    const {
        group,
        loading,
        student,
        usersMap
    } = useMappedState(useCallback(state => {
        return {
            loading: state.groups.loading,
            group: state.groups.groupsMap.get(id),
            student: state.users.usersMap.get(studentId),
            usersMap: state.users.usersMap

        }
    }));

    const [tab, setTab] = useState('lessons');

    const dispatch = useDispatch();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [selectorVisible, setSelectorVisible] = useState(false);

    useEffect(() => {
        dispatch(actions.loadGroupById(id)).then(pld => {
            console.log('xPld = ', pld);
            let gr = pld.groups[0];
            if (gr != undefined && gr?.users != undefined && gr?.users?.length > 0) {
                dispatch(usersActions.loadUsersByIds(gr?.users.map(x => x.id)))
            }
        });
        dispatch(usersActions.loadUserById(studentId));
    }, [studentId, id]);


    if (group == undefined || student == undefined) {
        return (
            <Code/>
        )
    }

    let shownUsers = (group?.users == undefined) ? [] : group.users.map(x => usersMap.get(x.id)).filter(xx => (xx != undefined));
    const dropOptions = shownUsers.map(a => ({label: CommonHelper.getUserName(a), value: a?.id}));
    const dropSelected = dropOptions.filter(xx => (xx.value == studentId))[0];

    return (
        <Wrapper className={''}>

            <TopPlaceholder>


                <TopLeft>

                    <BackSpan
                        onClick={() => {
                            CommonHelper.linkTo(`/groups`);
                        }}>
                        Мои группы
                    </BackSpan>
                    <span>
                            {' / '}
                    </span>
                    <BackSpan
                        style={{marginLeft: 15}}
                        onClick={() => {
                            CommonHelper.linkTo(`/groups/${group?.id}/students`);
                        }}>
                        {group?.name}
                    </BackSpan>
                    <span style={{marginRight: 10}}>
                            {' / '}
                    </span>
                    <GSpan>
                        <Select
                            options={dropOptions}
                            value={dropSelected}
                            onChange={x => {
                                CommonHelper.linkTo(`/groups/${group?.id}/students/${x?.value}`);
                                window.location.reload();
                            }}
                        />
                    </GSpan>
                </TopLeft>

                <TopRight>
                    {
                        [
                            {
                                label: 'Уроки',
                                value: 'lessons'
                            },
                            {
                                label: 'Задания',
                                value: 'tasks'
                            },
                            {
                                label: 'Активность',
                                value: 'stats'
                            },
                            UIHelper.isDictionaryAvailable() == false ? undefined :
                                {
                                    label: translate('vocabulary'),
                                    value: 'vocabulary'
                                },
                            {
                                label: translate('settings'),
                                value: 'settings'
                            }
                        ].filter(x => (x != undefined)).map((x, i) => {
                            return (
                                <TopRightTabItem selected={(x.value == tab)} key={i} onClick={() => {
                                    setTab(x.value);
                                    // CommonHelper.linkTo(`/groups/${id}/${x.value}`);
                                }}>
                                    {x.label}
                                </TopRightTabItem>
                            )
                        })
                    }

                </TopRight>

            </TopPlaceholder>

            <ContentPlaceholder>

                {tab != 'lessons' ? null :
                    <React.Fragment>
                        <StudentGroupLessonsPanel
                            id={group?.id}
                            studentId={student?.id}
                        />
                    </React.Fragment>
                }

                {tab != 'tasks' ? null :
                    <React.Fragment>
                        <StudentTasksPanel
                            classId={id}
                            userId={student?.id}
                        />
                    </React.Fragment>
                }

                {tab != 'stats' ? null :
                    <React.Fragment>
                        {/*<VerfactoAnalyticsPanel*/}
                        {/*    userId={student?.id}*/}
                        {/*/>*/}
                        {student == undefined ? null :
                            <SimpleGroupOrStudentAnalyticsPanel
                                studentId={student?.id}
                            />
                        }
                    </React.Fragment>
                }

                {tab != 'vocabulary' ? null :
                    <React.Fragment>
                        <VocabularyPlaceholder>
                            <UserVocabularyPanel
                                userId={student?.id}
                            />
                        </VocabularyPlaceholder>
                    </React.Fragment>
                }


                {tab != 'settings' ? null :
                    <React.Fragment>
                        <SettingsBox>

                            <UpdateProfilePanel
                                userId={studentId}
                            />

                            <div style={{marginTop: 10}}>
                                <TopRight>
                                    <DeleteSpan onClick={() => {
                                        setDeleteModalVisible(true);
                                    }}>
                                        удалить из группы
                                    </DeleteSpan>
                                </TopRight>
                                <TopRight>
                                    <DeleteSpan onClick={() => {
                                        setResetModalVisible(true);
                                    }}>
                                        сбросить пароль
                                    </DeleteSpan>
                                </TopRight>
                            </div>
                        </SettingsBox>
                    </React.Fragment>
                }


            </ContentPlaceholder>

            {deleteModalVisible == false ? null :
                <NiceConfirm
                    loading={loading}
                    confirmText={'Удалить'}
                    cancelText={'Отмена'}
                    heading={'Удаление студента из группы'}
                    subHeading={'Подтвердите, что вы хотите удалить студента из группы'}
                    onCancel={() => {
                        setDeleteModalVisible(false);
                    }}
                    onConfirm={async () => {
                        if (loading == true) {
                            return;
                        }
                        await dispatch(actions.removeFromGroup(student?.id, group?.id))
                        setDeleteModalVisible(false);
                        CommonHelper.linkTo(`/groups/${group?.id}`);
                    }}
                >

                </NiceConfirm>
            }

            {resetModalVisible == false ? null :
                <NiceConfirm
                    loading={loading}
                    confirmText={'Cбросить'}
                    cancelText={'Отмена'}
                    heading={'Сброс пароля студента'}
                    subHeading={'Подтвердите, что вы хотите сбросить у студента пароль. Новый пароль будет отправлен ему на почту.'}
                    onCancel={() => {
                        setResetModalVisible(false);
                    }}
                    onConfirm={async () => {
                        if (loading == true) {
                            return;
                        }
                        await UsersAPI.forceSetPassword(
                            {
                                userId: studentId,
                                password: `y${Math.floor(Math.random() * 100000)}${Math.floor(Math.random() * 100000)}`.padStart(9, 'z')
                            }
                        );
                        setResetModalVisible(false);
                    }}
                >

                </NiceConfirm>
            }


            {selectorVisible == false ? null :
                <NiceModal onClose={() => {
                    setSelectorVisible(false);
                }}>

                    <InnerPlaceholder>
                        <StudentsList users={shownUsers} onUserClick={u => {
                            CommonHelper.linkTo(`/groups/${group?.id}/students/${u?.id}`);
                            setSelectorVisible(false);
                            window.location.reload();
                        }}/>
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const VocabularyPlaceholder = styled.div`
  width: calc(100vw - 400px);
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 720px) {
    width: 95%;
  }
`;

const SelImg = styled.img`
  cursor: pointer;
  opacity: 0.5;
  display: inline-block;
  height: 18px;
  margin-left: 5px;

  :hover {
    opacity: 1;
  }
`;

const InnerPlaceholder = styled.div`
  width: 820px;
  max-height: 400px;
`;

const SettingsBox = styled.div`
  width: 720px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  background: white;
  border-radius: 4px;
  margin-top: 20px;

  @media (max-width: 720px) {
    width: 95%;
  }
`;

const TopRightTabItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.selected == true ? '#085BFF' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  border-bottom: 6px solid ${props => (props.selected == true ? '#FFA71B' : 'white')};
  border-top: 6px solid white;
  box-sizing: border-box;
  height: 70px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }


  :last-of-type {

  }

  :hover {
    color: #085BFF;
  }
`;

const DeleteSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;
  color: red;
`;

const DeleteModalInner = styled.div`
  padding: 20px;
  width: 420px;
`;

const ContentPlaceholder = styled.div`
  max-height: calc(100vh - 70px);
  overflow-y: auto;
`;

const GSpan = styled.span`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const BackSpan = styled.span`
  opacity: 0.7;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  :hover {
    opacity: 1;
  }
`;


const TopPlaceholder = styled.div`
  background: white;
  height: 70px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
    height: 5%;
    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const NoStudentsLottie = styled.div`
  width: 100%;
  height: calc(70vh - 10px);
  text-align: center;
`;

const NoStudentsText = styled.div`
  padding: 20px;
  min-width: 20vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -150px;
`;

const AddModeTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const AddButton = styled.div`
  background: #FF624F;
  border-radius: 1000px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 36px;
  padding-right: 36px;
  height: 44px;
  cursor: pointer;

  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.8;
  }
`;

const AddModeTab = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: ${props => (props.selected == true ? '2px solid #FFA71B' : '2px solid lightgrey')};
  cursor: pointer;
  padding-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const UsersList = styled.div`
  width: 100%;
`;

const UserItem = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  padding: 5px;
  background: white;
  box-sizing: border-box;
  width: calc(33.333% - 10px);
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const leftWidth = 60;
const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  width: ${leftWidth}px;
`;

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - ${leftWidth}px);
  padding-left: 5px;
  box-sizing: border-box;
  padding-top: 3px;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Email = styled.div`
  opacity: 0.7;
`;

const Avatar = styled.div`
  width: ${leftWidth}px;
  height: ${leftWidth}px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const SpinnerPlaceholder = styled.div`
  padding: 10px;
  text-align: center;
`;

const AddPlaceholder = styled.div`
  padding: 5px;
  font-size: 16px;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  opacity: 0.8;

  :hover {
    opacity: 1.0;
  }
`;

const Plus = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://www.englishpatient.org/storage/41ecc36b-b54e-4042-9b81-a304518c77fb.png');
`;

const CsvImage = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${shareImg});
`;


const Field = styled.div`
  margin-bottom: 10px;
`;

const ModalInner = styled.div`
  padding: 10px;
`;
