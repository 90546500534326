import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Tabs, {TabItem} from "../../ui/Tabs";

import UpdateAccessGroupForm from "../forms/UpdateAccessGroupForm";

import * as actions from '../../../redux/actions/AccessGroupsActions'
// import {OutlinedButton} from "../../ui/Button";

import {GreenButton} from "../../ira/ui/Buttons";
import NiceModal from "../../modals/NiceModal";
import {useDispatch, useMappedState} from "redux-react-hook";
import allow_edit from "../../../assets/img/allow_edit.svg";
const renderGroups = (groups = [], selectedGroupId, onSelect = g => {
}) => {

    return (
        <GroupsList>
            {groups.map((a, i) => {
                let isSelected = (a.id == selectedGroupId);
                return (
                    <GroupItem key={a.id} selected={isSelected} onClick={() => {
                        onSelect(a);
                    }}>
                        <div style={{fontWeight: 'bold', fontSize: 18, marginBottom: 5, display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                            {a.name}
                            {a.allowEdit != true ? null :
                                <img src={allow_edit} style={{height: 24, marginLeft: 5}} />
                            }
                        </div>
                        <div style={{fontStyle: 'italic', fontSize: 12, opacity: 0.5}}>
                            {(a.emails || []).join(', ')}
                        </div>
                    </GroupItem>
                )
            })}
        </GroupsList>
    )

}

export default function AccessGroupsPanel(props) {

    const {
        groups = [],
        user,
        loading = false
    } = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        return {
            groups: state.accessGroups.accessGroupsMap.toArray(),
            loading: state.accessGroups.loading,
            user: currentUser,
        }
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.loadAccessGroups());
    }, []);

    const [addVisible, setAddVisible] = useState(false);


    const [selectedId, setSelectedId] = useState(undefined);
    let email = user?.email;
    let myGroups = groups.filter(x => (x.creatorId == user?.id));
    let sharedGroups = groups.filter(x => (x.emails != undefined && x.emails.indexOf(email) > -1)).filter(x => (x.emails != undefined && x.emails.indexOf(user.email) > -1));
    const selectedGroup = (setSelectedId == undefined) ? undefined : groups.filter(x => (x.id == selectedId))[0];

    return (
        <Wrapper>

            <Tabs
                tabs={[
                    {
                        label: 'Группы, созданные мной',
                        content: (
                            <TabItem>
                                <div>
                                    {renderGroups(myGroups, selectedId, a => {
                                        setSelectedId(a.id);
                                    })}
                                </div>

                                <div style={{marginTop: 20, display: 'flex'}}>
                                    <GreenButton onClick={() => {
                                        setAddVisible(true);
                                    }}>
                                        Создать группу
                                    </GreenButton>
                                </div>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Группы, в которые меня добавили',
                        content: (
                            <TabItem>
                                {renderGroups(sharedGroups, selectedId, a => {
                                    setSelectedId(a.id);
                                }, true)}
                            </TabItem>
                        )
                    }
                ]}
            />


            {selectedGroup == undefined ? null :
                <NiceModal visible={(selectedGroup != undefined)}
                           onClose={() => {
                               setSelectedId(undefined);
                           }}>

                    <ModalInner>

                        <UpdateAccessGroupForm
                            loading={loading}
                            {...selectedGroup}
                            canLeave={selectedGroup.creatorId != user?.id}
                            canDelete={selectedGroup.creatorId == user?.id}
                            canEdit={selectedGroup.creatorId == user?.id}
                            onSave={async d => {
                                await dispatch(actions.updateAccessGroup({
                                    id: selectedGroup.id,
                                    ...d
                                }));
                                setSelectedId(undefined);
                            }}
                            onDelete={async () => {
                                await dispatch(actions.deleteAccessGroup(selectedGroup?.id));
                                setSelectedId(undefined);
                            }}
                            onLeave={async () => {
                                let newEmails = (selectedGroup?.emails || []).filter(xx => (xx != user?.email));
                                await dispatch(actions.updateAccessGroup({
                                    id: selectedGroup?.id,
                                    emails: newEmails
                                }));
                                setSelectedId(undefined);
                            }}

                        />
                    </ModalInner>

                </NiceModal>
            }


            {addVisible == false ? null :
                <NiceModal visible={addVisible}
                           onClose={() => {
                               setAddVisible(false);
                           }}>

                    <ModalInner>
                        <InnerHeading>
                            Редактирование группы доступа
                        </InnerHeading>

                        <UpdateAccessGroupForm
                            loading={loading}
                            onSave={async d => {
                                await dispatch(actions.createAccessGroup({
                                    ...d,
                                    name: (d.name == undefined || d.name == '') ? 'Группа без названия' : d.name
                                }));
                                setAddVisible(false);
                            }}
                        />
                    </ModalInner>

                </NiceModal>
            }

        </Wrapper>
    );
}

const InnerHeading = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
`;

const ModalInner = styled.div`
  width: 520px;
  max-height: 70vh;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
`;

const GroupsList = styled.div`

`;

const GroupItem = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background: white;
  cursor: pointer;

  :hover {

  }
`;