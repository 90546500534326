import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import Select from 'react-select'
import SimpleOptionsEditor from "./SimpleOptionsEditor";

import {Textarea, Input} from "../../../ira/ui/Inputs";

import BrutalOverlayModal from '../../../modals/BrutalOverlayModal'
import MaterialSearchPanel from "../../../materials/panels/MaterialSearchPanel";
import {GreenButton} from "../../../ira/ui/Buttons";
import SimpleFileUploader from "../../../uploader/tools/SimpleFileUploader";
import SearchOrCreateMaterialsPanel from "../../../materials/panels/SearchOrCreateMaterialsPanel";

const OPTIONS = [
    {
        label: 'Множестенный выбор',
        value: true
    },
    {
        label: 'Выбор одного варианта ответа',
        value: false
    },
]

export default function MultipleChoiceExerciseEditorTool(props) {

    const {
        onSave = d => {

        }
    } = props;

    const [options, setOptions] = useState(props.options == undefined ? [] : props.options);
    const [isMultiple, setIsMultiple] = useState(props.isMultiple == undefined ? false : props.isMultiple);
    const [taskText, setTaskText] = useState(props.taskText == undefined ? '' : props.taskText);
    const [videoUrl, setVideoUrl] = useState(props.videoUrl == undefined ? '' : props.videoUrl);
    const [imageUrl, setImageUrl] = useState(props.imageUrl == undefined ? '' : props.imageUrl);

    const [selectVideoModalVisible, setSelectVideoModalVisible] = useState(false);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Текст задания
                </Label>
                <Textarea value={taskText} onChange={evt => {
                    setTaskText(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Видео
                </Label>
                {(videoUrl == undefined || videoUrl == '') ?
                    <div>
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            setSelectVideoModalVisible(true);
                        }}>
                            выбрать видео
                        </span>
                    </div> :
                    <div>
                        <Video controls={true}>
                            <source src={videoUrl}/>
                        </Video>
                        <div style={{marginTop: 5}}>
                            <span style={{color: 'red', cursor: 'pointer'}} onClick={() => {
                                setVideoUrl('');
                            }}>
                                удалить
                            </span>
                        </div>
                    </div>
                }
            </Field>

            <Field>
                <Label>
                    Картинка
                </Label>
                {(imageUrl == undefined || imageUrl == '') ?
                    null :
                    <ImagePlaceholder>
                        <GoodFittedImage src={imageUrl}/>
                        <div style={{marginTop: 5}}>
                            <span style={{color: 'red', cursor: 'pointer'}} onClick={() => {
                                setImageUrl('');
                            }}>
                                удалить
                            </span>
                        </div>
                    </ImagePlaceholder>
                }
                <div>
                    <div>
                        Изображение
                    </div>
                    <SimpleFileUploader
                        onSave={s => {
                            setImageUrl(s);
                        }}
                    />
                </div>
            </Field>

            <Field>
                <Label>
                    Тип выбора
                </Label>
                <Select options={OPTIONS} value={OPTIONS.filter(x => (x.value == isMultiple))[0]} onChange={a => {
                    setIsMultiple(a.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Варианты ответа
                </Label>
                <SimpleOptionsEditor options={options} onSave={opts => {
                    setOptions(opts);
                }}/>
            </Field>

            <Field>
                <GreenButton onClick={() => {
                    onSave({
                        options: options,
                        isMultiple: isMultiple,
                        taskText: taskText,
                        videoUrl: videoUrl,
                        imageUrl: imageUrl
                    })
                }}>
                    Сохранить
                </GreenButton>
            </Field>


            <Sidebar
                width={Math.min(820, window.innerWidth)}
                visible={selectVideoModalVisible} onCloserClick={() => {
                setSelectVideoModalVisible(false);
            }}>

                {selectVideoModalVisible == false ? null :
                    <div style={{paddingLeft: 20, paddingRight: 20, height: 'calc(100vh - 120px)'}}>
                        <SearchOrCreateMaterialsPanel onSelect={m => {
                            console.log('onMaterialClick: m = ', m);
                            console.log('setting videoUrl = ', m.url);
                            setVideoUrl(m.url);
                            setSelectVideoModalVisible(false);
                        }}/>
                    </div>
                }

            </Sidebar>


        </Wrapper>
    );
}

const ImagePlaceholder = styled.div`
  margin-bottom: 10px;
`;

export const GoodFittedImage = styled.div`
  background: white;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 400px;
  background-image: url(${props => props.src});
`;

const Image = styled.img`
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 400px;
`;

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 14px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;