import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import {translate} from "../../../utils/Translate";

import {Input, Textarea} from "../../ira/ui/Inputs";
import {GreenButton} from "../../ira/ui/Buttons";
import BulkDndPlaceholder from "../../bulk_uploader/BulkDndPlaceholder";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import cit_img from '../../../assets/img/citation.svg'
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import SelectVideoScreenshotWrapper from "../../video/preview/buttons/SelectVideoScreenshotWrapper";
import MosesEditorTool from "../../moses/MosesEditorTool";
import Tabs, {TabItem} from "../../ui/Tabs";
import SubtitlesPlayer from "../tools/SubtitlesPlayer";

import shot_icon from '../../../assets/img/shot_icon.png'
import ViewAuthorTool from "../../author/tools/ViewAuthorTool";
import NewBulkUploader from "../../bulk_uploader/NewBulkUploader";
import {toolbarOptions} from "../../exercises/forms/ExerciseCardForm";
import ChatGPTAPI from "../../../api/ChatGPTAPI";


export default function UpdateMaterialForm(props) {
    const {
        hasName = true,

        onSave = d => {

        },
        isModerator = false,
        canDelete = false,
        onDelete = () => {

        },
        loading = false,
        isCreatorMode = true
    } = props;

    console.log('UpdateMaterialForm: render: props = ', props);

    const [updT, setUpdT] = useState(0);
    const [avatar, setAvatar] = useState(props.avatar);
    const [subtitles, setSubtitles] = useState(props.subtitles || []);
    const [url, setUrl] = useState(props.url);
    const [name, setName] = useState(props.name ? props.name : '');
    const [comment, setComment] = useState(props.comment ? props.comment : '');
    const [transcript, setTranscript] = useState(props.transcript ? props.transcript : '');
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription ? props.copyrightDescription : '');

    const [isIdiom, setIsIdiom] = useState(props.isIdiom);
    const [isVocWord, setIsVocWord] = useState(props.isVocWord);

    const [citationModalVisible, setCitationModalVisible] = useState(false);
    const [subtitlesModalOpen, setSubtitlesModalOpen] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);

    const [aiKeywords, setAiKeywords] = useState([]);
    const [aiGrammarItems, setAiGrammarItems] = useState([]);
    const [aiWorking, setAiWorking] = useState(false);


    return (
        <Wrapper>

            {hasName == false ? null :
                <Row>
                    <Label style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <span>
                            Название
                        </span>
                        {props?.id && <span style={{opacity: 0.3}}>
                            {props.id}
                        </span>}
                    </Label>
                    <Input disabled={!isCreatorMode} placeholder={'Название'} value={name} onChange={evt => {
                        setName(evt.target.value);
                    }}/>
                </Row>
            }

            <Row>
                <Label>
                    Транскрипт
                </Label>
                <Textarea disabled={!isCreatorMode} placeholder={'Транскрипт'} value={transcript} onChange={evt => {
                    setTranscript(evt.target.value);
                }}/>
            </Row>

            {(transcript == undefined || transcript.length == 0) ? null :
                <Row>
                    <Label>
                        {'AI - ключевые слова'}
                    </Label>
                    {aiWorking == true ? null :
                        <div>
                            {aiKeywords == undefined || aiKeywords.length == 0 ?
                                <div>
                                <span style={{cursor: 'pointer'}} onClick={async () => {
                                    setAiWorking(true);
                                    let pld = await ChatGPTAPI.getKeywords(transcript);
                                    let pld2 = await ChatGPTAPI.getEnglishGrammarItems(transcript);
                                    setAiGrammarItems(pld2);
                                    console.log('getKeywords: pld = ', pld);
                                    setAiKeywords(pld);
                                    setAiWorking(false);
                                }}>
                                    извлечь
                                </span>
                                </div>
                                :
                                <div>
                                    <div>
                                        Ключевые слова
                                    </div>
                                    <div>
                                        {aiKeywords.map((a, i) => {
                                            return (
                                                <span key={i} style={{
                                                    backgroundColor: 'lightgoldenrodyellow',
                                                    display: 'inline-block',
                                                    margin: 5,
                                                    padding: 5,
                                                    border: '1px solid whitesmoke',
                                                    lineHeight: '20px'
                                                }}>
                                                    {` ${a} `}
                                                </span>
                                            )
                                        })}
                                    </div>

                                    <div>
                                        Грамматические конструкции
                                    </div>
                                    <div>
                                        {aiGrammarItems.map((a, i) => {
                                            return (
                                                <span key={i} style={{
                                                    backgroundColor: 'lightgoldenrodyellow',
                                                    display: 'inline-block',
                                                    margin: 5,
                                                    padding: 5,
                                                    border: '1px solid whitesmoke',
                                                    lineHeight: '20px'
                                                }}>
                                                    {` ${a} `}
                                                </span>
                                            )
                                        })}
                                    </div>


                                    <div>
                                <span style={{cursor: 'pointer'}} onClick={async () => {
                                    setAiWorking(true);
                                    let pld = await ChatGPTAPI.getKeywords(transcript);
                                    let pld2 = await ChatGPTAPI.getEnglishGrammarItems(transcript);
                                    setAiGrammarItems(pld2);
                                    console.log('getKeywords: pld = ', pld);
                                    setAiKeywords(pld);
                                    setAiWorking(false);
                                }}>
                                    перегенерировать
                                </span>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {aiWorking == false ? null :
                        <div>
                            {'AI is working...'}
                        </div>
                    }

                </Row>
            }

            {((isVocWord == false || isVocWord == undefined) && (isIdiom == false || isIdiom == undefined)) ? null :
                <Row>
                    <Label>
                        Комментарий
                    </Label>
                    {/*<Textarea disabled={!isCreatorMode} placeholder={'Comment'} value={comment} onChange={evt => {*/}
                    {/*    setComment(evt.target.value);*/}
                    {/*}}/>*/}
                    <ReactQuill value={comment}
                                modules={{toolbar: toolbarOptions}}
                                onChange={s => {
                                    setComment(s);
                                }}/>

                </Row>
            }

            <HorRow>
                <Row style={{flex: 1}}>
                    <Label>
                        Видео
                    </Label>

                    {url == undefined ? null :
                        <div>
                            <Video src={url} controls={true}></Video>
                            {isCreatorMode == false ? null :
                                <div>
                                <span style={{cursor: 'pointer', color: 'red', opacity: 0.75}} onClick={() => {
                                    setUrl(undefined);
                                    setUpdT(+new Date());
                                }}>
                                    загрузить другой файл
                                </span>
                                </div>
                            }
                        </div>
                    }


                    {url != undefined || isCreatorMode == false ? null :
                        <>
                            {/*<BulkDndPlaceholder*/}
                            {/*    singleMode={true}*/}
                            {/*    onSingleFileUploaded={d => {*/}
                            {/*        console.log('onSingleFileUploaded: d = ', d);*/}
                            {/*        setAvatar(d.avatar);*/}
                            {/*        setUrl(d.url);*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <NewBulkUploader
                                singleMode={true}
                                onSingleFileUploaded={d => {
                                    console.log('onSingleFileUploaded: d = ', d);
                                    setAvatar(d.avatar);
                                    setUrl(d.url);
                                }}
                            />

                        </>

                    }


                    {/*<SimpleFileUploader*/}
                    {/*    onSave={s => {*/}
                    {/*        setUrl(s);*/}
                    {/*    }}*/}
                    {/*/>*/}
                </Row>

                {url == undefined ? null :
                    <Row style={{flex: 1, marginLeft: 20}}>
                        <Label>
                            Обложка
                        </Label>

                        {avatar == undefined ? null :
                            <Avatar src={avatar} controls={true}></Avatar>
                        }

                        {isCreatorMode == false ? null :
                            <>
                                <SimpleFileUploader
                                    onSave={s => {
                                        setAvatar(s);
                                    }}
                                />
                                <div style={{marginTop: 10}}>
                                    <SelectVideoScreenshotWrapper url={url} onSave={x => {
                                        // console.log('frame onSave: url = ', url);
                                        setAvatar(x);
                                    }}>
                                        <FrameSpan>
                                            <img src={shot_icon} style={{height: 18, marginRight: 10}}/>
                                            Выбрать кадр
                                        </FrameSpan>
                                    </SelectVideoScreenshotWrapper>
                                </div>
                            </>
                        }

                    </Row>
                }

            </HorRow>

            <Row>
                <Label>
                    Субтитры ({subtitles.length})
                </Label>
                <SubsSpan onClick={() => {
                    setSubtitlesModalOpen(true);
                }}>
                    Создание титрованных дидактических материалов
                </SubsSpan>
            </Row>

            {isModerator == false ? null :
                <Row>
                    <Label>
                        Дополнительные настройки
                    </Label>
                    <SubsSpan onClick={() => {
                        setSettingsModalOpen(true);
                    }}>
                        Открыть
                    </SubsSpan>
                </Row>
            }

            <Row>
                <Label>
                    Информация об источниках
                    <CitImg src={cit_img} onClick={() => {
                        setCitationModalVisible(true);
                    }}/>
                </Label>
                <Textarea disabled={!isCreatorMode} placeholder={'Информация об источниках'}
                          value={copyrightDescription} onChange={evt => {
                    setCopyrightDescription(evt.target.value);
                }}/>
            </Row>

            {isCreatorMode == true ? null :
                <Row>
                    <ViewAuthorTool
                        badgeMode={false}
                        userId={props.userId || props.creatorId}
                    />
                </Row>
            }

            <BottomRow>
                {(url == undefined || isCreatorMode == false) ? null :
                    <GreenButton onClick={() => {
                        onSave({
                            url: url,
                            name: name,
                            comment: comment,
                            subtitles: subtitles,
                            copyrightDescription: copyrightDescription,
                            transcript: transcript,
                            avatar: avatar,
                            isIdiom: isIdiom,
                            isVocWord: isVocWord
                        })
                    }}>
                        <Spin visible={loading}/>
                        Сохранить
                    </GreenButton>
                }
                {canDelete == false || isCreatorMode == false ? null :
                    <div>
                        <DelSpan onClick={() => {
                            if (window.confirm('Вы уверены?') == false) {
                                return;
                            }
                            onDelete();
                        }}>
                            удалить
                        </DelSpan>
                    </div>
                }
            </BottomRow>

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(s);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

            <Sidebar width={Math.min(window.innerWidth, 920)} visible={subtitlesModalOpen} onCloserClick={() => {
                setSubtitlesModalOpen(false);
            }}>

                {subtitlesModalOpen == false ? null :
                    <SubtitlesInnerPlaceholder>
                        <Tabs
                            tabs={[
                                (isCreatorMode == false) ? undefined :
                                    {
                                        label: translate('editor'),
                                        content: (
                                            <TabItem>
                                                <MosesEditorTool
                                                    url={url}
                                                    hasText={true}
                                                    intervals={subtitles}
                                                    onChange={arr => {
                                                        setSubtitles(arr);
                                                    }}
                                                />
                                            </TabItem>
                                        )
                                    },
                                {
                                    label: translate('preview'),
                                    content: (
                                        <TabItem>
                                            <SubtitlesPlayer
                                                url={url}
                                                avatar={avatar}
                                                subtitles={subtitles}
                                            />
                                        </TabItem>
                                    )
                                }
                            ].filter(x => (x != undefined))}
                        />
                    </SubtitlesInnerPlaceholder>
                }

            </Sidebar>

            <Sidebar width={Math.min(window.innerWidth, 920)} visible={settingsModalOpen} onCloserClick={() => {
                setSettingsModalOpen(false);
            }}>

                {settingsModalOpen == false ? null :
                    <SubtitlesInnerPlaceholder>

                        <Row>
                            <Label>
                                Является идиомой
                            </Label>
                            <SubsSpan style={{cursor: 'pointer'}} onClick={() => {
                                setIsIdiom(!isIdiom);
                            }}>
                                {isIdiom == true ? 'Да' : 'Нет'}
                            </SubsSpan>
                        </Row>

                        <Row>
                            <Label>
                                Является словарным словом
                            </Label>
                            <SubsSpan style={{cursor: 'pointer'}} onClick={() => {
                                setIsVocWord(!isVocWord);
                            }}>
                                {isVocWord == true ? 'Да' : 'Нет'}
                            </SubsSpan>
                        </Row>


                    </SubtitlesInnerPlaceholder>
                }

            </Sidebar>


        </Wrapper>
    );
}

const SubtitlesInnerPlaceholder = styled.div`
  width: 100%;
`;

const SubsSpan = styled.span`
  opacity: 0.6;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const FrameSpan = styled.span`
  font-size: 12px;
  opacity: 0.7;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;


  padding-left: 15px;
  padding-right: 15px;
  border-radius: 1000px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0s;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;

  :hover {
    opacity: 1;
  }
`;


const DelSpan = styled.div`
  color: red;
  opacity: 0.5;
  margin-left: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const HorRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Avatar = styled.div`
  width: 280px;
  height: 180px;
  background-color: whitesmoke;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`;

const Wrapper = styled.div`

`;

const Video = styled.video`
  height: 180px;
`;

const Label = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 20px;
`;