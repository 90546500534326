import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SlidesAPI from "../../../api/SlidesAPI";
import BackupForm from "../forms/BackupForm";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import Tabs, {TabItem} from "../../ui/Tabs";
import * as copyPaterActions from "../../../redux/actions/CopyPasterActions";
import ViewSlideTool from "../tools/ViewSlideTool";
import * as actions from "../../../redux/actions/SlidesActions";
import toast from "react-hot-toast";
import NiceModal from "../../modals/NiceModal";

export default function SlideBackupsPanel({id}) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    const [addModalVisible, setAddModalVisible] = useState(false);

    const dispatch = useDispatch();

    const {
        slide,
        selectedSlideId
    } = useMappedState(useCallback(state => {
        return {
            slide: state.slides.slidesMap.get(id),
            loading: state.slides.loading,
            selectedSlideId: state.slides.selectedSlideId
        }
    }));

    useEffect(() => {
        dispatch(actions.getSlide(id));
    }, []);

    useEffect(() => {
        setLoading(true);
        SlidesAPI.getSlideBackups(id).then(arr => {
            console.log('got slide backups: arr = ', arr);
            setItems(arr.sort((a, b) => (+b.timestamp - +a.timestamp)));
            setLoading(false);
        });
    }, [id]);

    if (slide == undefined) {
        return null;
    }

    if (items.length == 0 && loading == true) {
        return (<Code/>)
    }

    const selectedItem = (selectedId == undefined) ? undefined : items.filter(x => (x.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <BlueButton onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Создать резервную копию
                </BlueButton>
            </TopPlaceholder>

            <ListPlaceholder>
                {items.map((a, i) => {
                    return (
                        <SlideItem key={a.id}
                                   selected={(selectedItem?.id == a.id)}
                                   onClick={() => {
                                       setSelectedId(a.id);
                                   }}>
                            <Name>
                                {a.name}
                            </Name>
                            <div style={{fontSize: 12, opacity: 0.7, fontStyle: 'italic'}}>
                                {moment(a.timestamp).format('DD.MM.YYYY HH:mm')}
                            </div>
                        </SlideItem>
                    )
                })}
            </ListPlaceholder>

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <Heading style={{marginBottom: 50, marginTop: 10}}>
                        {`Создание резервной копии модуля "${slide.name}"`}
                    </Heading>

                    <BackupForm
                        loading={loading}
                        buttonName={'Создать бекап'}
                        onSave={async d => {
                            setLoading(true);
                            await SlidesAPI.createSlideBackup(id, d.name);
                            let arr = await SlidesAPI.getSlideBackups(id);
                            setItems(arr);
                            setLoading(false);
                            setAddModalVisible(false);
                        }}
                    />


                </NiceModal>
            }

            <Sidebar
                width={Math.min(820, window.innerWidth)}
                visible={(selectedItem != undefined)}
                onCloserClick={() => {
                    setSelectedId(undefined);
                }}
            >

                {selectedItem == undefined ? null :
                    <div>
                        <Tabs
                            tabs={[
                                {
                                    label: 'Просмотр',
                                    content: (
                                        <TabItem>
                                            <ViewSlideTool
                                                canEdit={false}
                                                canShare={false}
                                                {...selectedItem}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Редактировать',
                                    content: (
                                        <TabItem>
                                            <BackupForm
                                                canDelete={true}
                                                loading={loading}
                                                {...selectedItem}
                                                onSave={async d => {
                                                    setLoading(true);
                                                    await SlidesAPI.updateSlideBackupName(selectedItem.id, d.name);
                                                    let arr = await SlidesAPI.getSlideBackups(id);
                                                    setItems(arr);
                                                    setLoading(false);
                                                    setSelectedId(undefined);
                                                }}
                                                onDelete={async () => {
                                                    setLoading(true);
                                                    await SlidesAPI.deleteSlideBackup(selectedItem.id);
                                                    let arr = await SlidesAPI.getSlideBackups(id);
                                                    setItems(arr);
                                                    setLoading(false);
                                                    setSelectedId(undefined);
                                                }}
                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Восстановление',
                                    content: (
                                        <TabItem>
                                            <GreenButton onClick={async () => {
                                                if (window.confirm('Вы уверены? ') == false) {
                                                    return;
                                                }
                                                if (loading == true) {
                                                    return;
                                                }
                                                setLoading(true);
                                                let ddd = JSON.parse(JSON.stringify(selectedItem));
                                                delete ddd.slideId;
                                                delete ddd.timestamp;
                                                delete ddd.updatedTimestamp;
                                                await dispatch(actions.updateSlide({...ddd, id: id}, false));
                                                setLoading(false);
                                                setSelectedId(undefined);
                                                toast('Восстановлено');
                                            }}>
                                                <Spin visible={loading}/>
                                                {loading == true ? null :
                                                    'Восстановить модуль из этой версии'
                                                }
                                            </GreenButton>
                                        </TabItem>
                                    )
                                }
                            ]}
                        />

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-size: 18px;
`;

const SlideItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  background: ${props => (props.selected == true ? 'blue' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  border: 1px solid lightgrey;
  border-radius: 4px;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Wrapper = styled.div`
  padding-top: 30px;
  margin-top: 30px;
`;

const ListPlaceholder = styled.div`

`;