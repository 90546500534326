import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

import {Input, Textarea} from "../../ira/ui/Inputs";

import QuestionsEditorTool from "../tools/QuestionsEditorTool";
import SimpleQuizQuestionsViewer from "../tools/SimpleQuizQuestionsViewer";
import {GreenButton} from "../../ira/ui/Buttons";
import CommonHelper from "../../../helpers/CommonHelper";
import {translate} from "../../../utils/Translate";
import cit_img from "../../../assets/img/citation.svg";
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import Sidebar from "arui-feather/sidebar";
import AccessGroupsDropdownPanel from "../../access_groups/panels/AccessGroupsDropdownPanel";
import Select from "react-select";
import {ACCESS_OPTIONS} from "../../exercises/forms/ExerciseInfoForm";

export default function QuizForm(props) {

    const {
        onSave = d => {

        },
        loading = false,
        canDelete = false,
        onDelete = () => {

        }
    } = props;

    const [mode, setMode] = useState('info');
    const [access, setAccess] = useState(props.access ? props.access : 'private');
    const [accessGroupsIds, setAccessGroupsIds] = useState(props?.accessGroupsIds ? props.accessGroupsIds : []);

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [copyrightDescription, setCopyrightDescription] = useState(props.copyrightDescription == undefined ? '' : props.copyrightDescription);
    const [criteria, setCriteria] = useState(props.criteria == undefined ? '' : props.criteria);
    const [questions, setQuestions] = useState(props.questions == undefined ? [] : props.questions);
    const [citationModalVisible, setCitationModalVisible] = useState(false);


    let isCreateMode = (props.id == undefined);

    return (
        <Wrapper>

            {isCreateMode == true ? null :
                <TabsPlaceholder>
                    <TabItem selected={(mode == 'info')} onClick={() => {
                        setMode('info');
                    }}>
                        {translate('information')}
                    </TabItem>
                    <TabItem selected={(mode == 'questions')} onClick={() => {
                        setMode('questions');
                    }}>
                        {translate('questions')}
                    </TabItem>
                    {/*<TabItem selected={(mode == 'preview')} onClick={() => {*/}
                    {/*    setMode('preview');*/}
                    {/*}}>*/}
                    {/*    Превью*/}
                    {/*</TabItem>*/}
                </TabsPlaceholder>
            }


            <TabPlaceholder>

                {mode != 'info' ? null :
                    <React.Fragment>

                        <Field>
                            <Label>
                                {translate('title')}
                            </Label>
                            <Input value={name} placeholder={translate('title')} onChange={evt => {
                                setName(evt.target.value);
                            }}/>
                        </Field>

                        <Field>
                            <Label>
                                Критерии оценивания
                            </Label>
                            <Textarea value={criteria} placeholder={'Критерии оценивания'} onChange={evt => {
                                setCriteria(evt.target.value);
                            }}/>
                        </Field>

                        <Field>
                            <Label>
                                Описание
                            </Label>
                            <Textarea value={description} placeholder={'Описание'} onChange={evt => {
                                setDescription(evt.target.value);
                            }}/>
                        </Field>

                        <Field>
                            <Label>
                                <span>
                                    Информация об источниках
                                </span>
                                <CitImg src={cit_img} onClick={() => {
                                    setCitationModalVisible(true);
                                }}/>
                            </Label>
                            <Textarea value={copyrightDescription} placeholder={'Информация об источниках'}
                                      onChange={evt => {
                                          setCopyrightDescription(evt.target.value);
                                      }}></Textarea>
                        </Field>

                        <Field className="field">
                            <Label>
                                <span>
                                    Доступ
                                </span>
                            </Label>
                            <Select options={ACCESS_OPTIONS}
                                    value={ACCESS_OPTIONS.filter(x => (x.value == access))[0]}
                                    onChange={a => {
                                        setAccess(a.value);
                                    }}/>
                        </Field>

                        {access != 'shared' ? null :
                            <Field>
                                <Label>
                                <span>
                                    Группы доступа
                                </span>
                                </Label>
                                <AccessGroupsDropdownPanel
                                    selectedIds={accessGroupsIds}
                                    onChange={x => {
                                        setAccessGroupsIds(x);
                                    }}
                                />
                            </Field>
                        }

                        {props.id == undefined ? null :
                            <div>
                                <Field>
                                    <Label>
                                        Ссылка для просмотра
                                    </Label>
                                    <span style={{cursor: 'pointer'}} onClick={() => {
                                        CommonHelper.linkTo(`/tests/${props.id}`);
                                    }}>
                                        посмотреть
                                    </span>
                                </Field>
                            </div>
                        }

                        {canDelete == false ? null :
                            <div>
                                <Field>
                                    <span style={{color: 'red', cursor: 'pointer'}} onClick={() => {
                                        onDelete();
                                    }} >
                                        удалить тест
                                    </span>
                                </Field>
                            </div>
                        }

                    </React.Fragment>
                }

                {mode != 'questions' ? null :
                    <React.Fragment>
                        <QuestionsEditorTool questions={questions} onChange={arr => {
                            setQuestions(arr);
                        }}/>
                    </React.Fragment>
                }

                {mode != 'preview' ? null :
                    <React.Fragment>
                        <SimpleQuizQuestionsViewer questions={questions}/>
                    </React.Fragment>
                }
            </TabPlaceholder>


            {mode == 'preview' ? null :
                <Field>
                    <GreenButton onClick={() => {
                        onSave({
                            name: name,
                            description: description,
                            criteria: criteria,
                            questions: questions,
                            copyrightDescription: copyrightDescription,
                            accessGroupsIds: accessGroupsIds
                        });
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            'Сохранить'
                        }
                    </GreenButton>
                </Field>
            }

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightDescription(s);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const TabsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const TabItem = styled.div`
  margin-right: 20px;
  border-bottom: ${props => (props.selected == true ? '2px solid blue' : '2px solid transparent')};
  cursor: pointer;
`;

const TabPlaceholder = styled.div`

`;

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 15px;
  
  @media (max-width: 720px) {
    width: calc(100vw - 125px);
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;