import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import CommonHelper from "../../../helpers/CommonHelper";
import LottieLoader from "../../loaders/LottieLoader";

import loading_lottie from '../../../assets/lottie/loading_group_lottie.json'
import GroupUsersPanel from "./GroupUsersPanel";

import * as actions from '../../../redux/actions/GroupsActions'
import * as tasksActions from '../../../redux/actions/TasksActions'

import ls from 'local-storage'

import ch_box_image from '../../../assets/img/ch_box.svg'
import {BlueButton} from "../../ira/ui/Buttons";
import UpdateGroupForm from "../forms/UpdateGroupForm";
import TeacherGroupLessonsPanel from "../../lessons/panels/TeacherGroupLessonsPanel";
import LogoDiv from "../../templates/LogoDiv";
import GroupStatsPanel from "./GroupStatsPanel";
import GroupLedgerPanel from "../../ledger/panels/GroupLedgerPanel";
import {translate} from "../../../utils/Translate";
import Tabs, {TabItem} from "../../ui/Tabs";
import AnswersChartTool from "../../analytics/tools/AnswersChartTool";
import SimpleGroupOrStudentAnalyticsPanel from "../../analytics/panels/SimpleGroupOrStudentAnalyticsPanel";
import NewGroupLedgerPanel from "../../ledger/panels/NewGroupLedgerPanel";
import GroupStatsTool from "../../stats/tools/GroupStatsTool";

export default function TeacherGroupPanel(props) {
    const {
        id,
        tab
    } = props;

    const {
        loading,
        group,
        lessons,
        selectedSlide,
        tasks
    } = useMappedState(useCallback(state => {
        return {
            loading: state.groups.loading,
            tasks: state.tasks.tasksMap.toArray().filter(x => (x.classId == id)).sort((a, b) => {
                return (+b.deadlineTimestamp - +a.deadlineTimestamp)
            }),

            lessons: state.lessons.lessonsMap.toArray().filter(x => (x?.classId == id)).sort((a, b) => (+b.startTimestamp - +b.startTimestamp)),
            group: state.groups.groupsMap.get(id),
            selectedSlide: state.slides.slidesMap.get(state.slides.selectedSlideId)
        }
    }));
    const dispatch = useDispatch();

    // const [tab, setTab] = useState(ls('gr_mode') == undefined ? 'tasks' : ls('gr_mode'));
    let hasAtLeastOneLesson = (lessons.length > 0);
    const [newTab, setNewTab] = useState(undefined);

    useEffect(() => {
        dispatch(tasksActions.getGroupTasks(id));
    }, [id]);

    let name = group?.name || '...';
    let invitationCode = group?.invitationCode || '';
    let isSomethingLoading = (loading == true && group == undefined);

    // let hasLogo = (hasAtLeastOneLesson && tab == 'tasks');
    let hasLogo = (tab == 'tasks');

    return (
        <Wrapper>

            <TopPlaceholder hasLogo={hasLogo}>


                <TopLeft>

                    {!hasLogo ? null :
                        <LogoDiv/>
                    }

                    <BackSpan
                        onClick={() => {
                            CommonHelper.linkTo(`/groups`);
                        }}>
                        {translate('my_groups')}
                    </BackSpan>
                    <span style={{marginRight: 10}}>
                            {' / '}
                        </span>
                    <GSpan>
                            <span style={{fontWeight: 'bold', opacity: 0.65}}>
                                {name}
                            </span>
                        {invitationCode == '' ? null :
                            <InvitationBadge>
                                {invitationCode}
                            </InvitationBadge>
                        }
                    </GSpan>
                </TopLeft>
                <TopRight>
                    {
                        [
                            {
                                label: translate('lessons'),
                                value: 'tasks'
                            },
                            {
                                label: <RowSpan>
                                    <span>{translate('students')}</span>
                                    {group?.users == undefined ? null :
                                        <StudentsNumSpan>{`(${group.users.length})`}</StudentsNumSpan>}
                                </RowSpan>,
                                value: 'students'
                            },
                            {
                                label: translate('settings'),
                                value: 'settings'
                            },
                            {
                                label: translate('stats'),
                                value: 'stats'
                            }
                        ].map((x, i) => {
                            return (
                                <TopRightTabItem selected={(x.value == tab)} key={i} onClick={() => {
                                    // setTab(x.value);
                                    CommonHelper.linkTo(`/groups/${id}/${x.value}`);
                                }}>
                                    {x.label}
                                </TopRightTabItem>
                            )
                        })
                    }
                    <BlueButton style={{marginLeft: 10,
                    display: window.innerWidth >= 720 ? 'flex' : 'none'}} onClick={() => {
                        let url = window.location.origin + window.location.pathname + '#' + `/online/${id}`;
                        window.open(url, '_blank').focus();
                    }}>
                        {translate('online_lesson')}
                    </BlueButton>
                </TopRight>
            </TopPlaceholder>

            <TabsPlaceholder>

                {tab != 'tasks' ? null :
                    <React.Fragment>
                        <TeacherGroupLessonsPanel
                            id={id}
                            hasTopInfoBar={true}
                        />
                        {/*<ClassTasksPanel classId={classId} userId={userId} canUpdate={true}/>*/}
                    </React.Fragment>
                }
                {tab != 'students' ? null :
                    <React.Fragment>
                        <GroupUsersPanel
                            id={id}
                        />

                        {/*<ClassUsersPanel users={users} classId={classId} patientClass={patientClass}*/}
                        {/*                 onUserAdded={() => {*/}
                        {/*                     this.load();*/}
                        {/*                 }}*/}
                        {/*                 onUserRemoved={() => {*/}
                        {/*                     this.load();*/}
                        {/*                 }}*/}
                        {/*/>*/}
                    </React.Fragment>
                }

                {(tab != 'settings' || group == undefined) ? null :
                    <React.Fragment>
                        <GroupSettingsPlaceholder>
                            <UpdateGroupForm
                                {...group}
                                loading={loading}
                                onSave={async d => {
                                    await dispatch(actions.updateGroup({
                                        ...d,
                                        id: id
                                    }));
                                }}
                                canDelete={true}
                                onDelete={async () => {
                                    await dispatch(actions.deleteGroup(id));
                                    CommonHelper.linkTo('/');
                                }}
                            />
                        </GroupSettingsPlaceholder>
                        {/*<UpdateClassPanel classId={classId} onUpdated={updatedClass => {*/}
                        {/*    this.setState({*/}
                        {/*        patientClass: updatedClass*/}
                        {/*    }, () => {*/}
                        {/*        onUpdated(updatedClass);*/}
                        {/*    });*/}
                        {/*}}/>*/}
                    </React.Fragment>
                }

                {tab != 'stats' ? null :
                    <React.Fragment>

                        <LedgerPlaceholder>

                            <Tabs
                                changeTab={newTab}
                                tabUpdated={() => setNewTab(undefined)}
                                tabs={[
                                    {
                                        label: 'Журнал',
                                        content: (
                                            <TabItem>
                                                {/*<GroupLedgerPanel*/}
                                                {/*    id={id}*/}
                                                {/*/>*/}
                                                <NewGroupLedgerPanel
                                                    id={id}
                                                    openStats={() => {
                                                        setNewTab(1);
                                                    }}
                                                />

                                            </TabItem>
                                        )
                                    },
                                    {
                                        label: 'Графики',
                                        content: (
                                            <TabItem>
                                                <SimpleGroupOrStudentAnalyticsPanel
                                                    groupId={id}
                                                />
                                            </TabItem>
                                        )
                                    },
                                    {
                                        label: 'Аналитика',
                                        content: (
                                            <TabItem>
                                                <GroupStatsTool
                                                    groupId={id}
                                                />
                                            </TabItem>
                                        )
                                    },


                                ]}
                            />

                        </LedgerPlaceholder>

                        {/*<GroupStatsPanel*/}
                        {/*    students={group?.users || []}*/}
                        {/*    tasks={tasks}*/}
                        {/*    groupId={group?.id}*/}
                        {/*/>*/}

                        {/*<TeacherClassStatsPanel classId={classId}/>*/}
                    </React.Fragment>
                }

            </TabsPlaceholder>

            {isSomethingLoading == false ? null :
                <LottiePlaceholder>
                    <LottieLoader
                        json={loading_lottie}
                    />
                    <div>
                        {translate('loading')}
                    </div>
                </LottiePlaceholder>
            }

        </Wrapper>
    );
}

const LedgerPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 15px;
`;

const GroupSettingsPlaceholder = styled.div`
  width: 720px;
  margin: 0 auto;
  background: white;
  padding: 20px;
  border-radius: 0px;
  margin-top: 40px;

  @media (max-width: 720px){
    width: 95%;
  }
`;

const RowSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

`;

const StudentsNumSpan = styled.span`
  opacity: 0.5;
  margin-left: 5px;
  font-size: 12px;
  line-height: 12px;
`;

const LottiePlaceholder = styled.div`
  height: calc(80vh - 120px);
  width: 100%;
  text-align: center;
`;

const GSpan = styled.span`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  
`;

const InvitationBadge = styled.span`
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px dashed grey;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.65;
  background: antiquewhite;

  @media (max-width: 1300px) {
    font-size: 12px;
    margin-left: 10px;
  }

  @media (max-width: 1200px) {
    display: none;
  }
  
`;

const BackSpan = styled.span`
  opacity: 0.7;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  margin-left: 15px;

  @media (max-width: 1300px) {
    font-size: 12px;
  }
  
  :hover {
    opacity: 1;
  }
`;

const TopPlaceholder = styled.div`
  background: white;
  height: 70px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  padding-left: ${props => (props.hasLogo ? 0 : 15)} px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  @media (max-width: 720px){
    flex-direction: column;
    height: 5%;
    align-items: flex-start;
    overflow-x: auto;
    
    padding-top: 15px;
    
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
`;

const TopRightTabItem = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.selected == true ? '#085BFF' : 'black')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  border-bottom: 6px solid ${props => (props.selected == true ? '#FFA71B' : 'white')};
  border-top: 6px solid white;
  box-sizing: border-box;
  height: 70px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 720px){
    margin-left: 6px;
    margin-right: 6px;
  }
  
  :last-of-type {

  }

  :hover {
    color: #085BFF;
  }
`;

const Wrapper = styled.div`
`;

const Content = styled.div`
  width: 100%;
  padding-top: 5px;
`;

const TabsPlaceholder = styled.div`
  box-sizing: border-box;
  //padding: 15px;
`;

const ClassNamePlaceholder = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 32px;
`;

const ClassCodePlaceholder = styled.div`
  padding: 0px;
  text-align: center;
  font-size: 18px;
  opacity: 0.7;
  font-weight: bold;
`;

const SpinnerPlaceholder = styled.div`
  text-align: center;
  padding: 10px;
`;

const OnlineLinkPlaceholder = styled.div`
  text-align: center;
  padding: 5px;
`;

const OnlineLinkSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    text-decoration: underline;
    opacity: 1;
  }
`;