import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import Select from "react-select";
import SimpleFileUploader from "../../uploader/tools/SimpleFileUploader";
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import {BlueButton, GreenButton} from "../../ira/ui/Buttons";
import {Input, Textarea} from "../../ira/ui/Inputs";
import cit_img from "../../../assets/img/citation.svg";
import SimpleCitationTool from "../../citation/SimpleCitationTool";
import AccessGroupsDropdownPanel from "../../access_groups/panels/AccessGroupsDropdownPanel";
import UIHelper from "../../../helpers/UIHelper";
import SmartGrammarSelector from "../../prouni/tools/SmartGrammarSelector";
import {TINY_MCE_API_KEY} from "../../../constants/config";
import {Editor} from "@tinymce/tinymce-react";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {toolbarOptions} from "./ExerciseCardForm";

export const ACCESS_OPTIONS = [
    {
        label: 'Приватный',
        value: 'private'
    },
    {
        label: 'Публичный',
        value: 'public'
    },
    {
        label: 'Группы доступа',
        value: 'shared'
    }
];

export const PROUNI_LING_CATEGORIES = [
    {
        label: 'V',
        value: 'V'
    },
    {
        label: 'G',
        value: 'G'
    },
    {
        label: 'V+G',
        value: 'V+G'
    }
];

export const TYPES_OPTIONS = [
    {
        label: 'Multiple choice',
        value: 'multiple_choice',
        taskText: 'Watch the video. Read the questions/statements and choose the correct answer. Check your answers carefully before saving your answer. Click "reply". Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can choose a word from the given options to fit the context within the topic of the Unit UNIT_NAME'
    },
    {
        label: 'True/False/Not Stated',
        value: 'true_false',
        taskText: 'Watch the video. Read the questions/statements and choose the correct answer TRUE, FALSE or NOT STATED. Check your answers carefully before saving your answer. Click "reply". Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can understand the meaning of the sentence containing a word from the vocabulary list WORDS_LIST of the Unit UNIT_NAME and say whether it is true, false or not stated in accordance with the information provided '
    },

    {
        label: 'Matching',
        value: 'matching',
        taskText: 'Place the items on the right by matching them to the proper elements/synonyms/antonyms/picture/definitions in the left column. Check your answers carefully before saving your answer. Click "reply". Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can compare and choose the right match (a picture/definition/ video/audio) for a word from the vocabulary list WORDS_LIST of the Unit UNIT_NAME'
    },
    {
        label: 'Gap filling',
        value: 'gaps',
        taskText: 'Watch  the clip. Insert the missing words [...]. Write your answer in the box below. If there are more than ONE  word or gap, separate answers with commas. Check your spelling carefully before saving your answer. Click "reply". Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can infer the word/grammar form from the context of the Unit UNIT_NAME and spell it'
    },
    {
        label: 'Typing',
        value: 'typing',
        taskText: 'Watch  the clip. Write in English everything you hear in the “Your Answer” box. Click "reply". Do the same with all the items on the simulator. Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can infer the words and their sequences by hearing and  put them in the written form.can infer the word WORDS_LIST from the context of the Unit UNIT_NAME and spell it'
    },
    // {
    //     label: 'Cards',
    //     value: 'cards',
    //     taskText: '',
    //     outcomeMask: 'Learning outcome: now you can recognise and repeat/write down the word from the vocabulary list WORDS_LIST or grammar pattern on topic of the Unit UNIT_NAME, can make an utterance, express your opinion  on topic of the Unit UNIT_NAME'
    // },
    {
        label: 'Recording',
        value: 'recording',
        taskText: 'Watch  the clip. Click "start recording" and repeat verbatim, then click "stop". In your answer, try to repeat the pronunciation, logical stress and intonation of the original. Listen to the result (>). If everything is OK, click "save". If not, record again. Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can spell the words/grammar forms from the designated list on topic of the Unit UNIT_NAME'
    },
    {
        label: 'Restore the order',
        value: 'order',
        taskText: 'Restore the right order of words. The sentence starts with the word in capital letter',
        outcomeMask: 'Learning outcome: now you can understand the meaning and function of a word/expression from the vocabulary list WORDS_LIST in the sentence describing the context of the Unit UNIT_NAME'
    },
    {
        label: 'Speaking',
        value: 'speaking',
        taskText: '[задание формулируется преподавателем] Record a [1-2] - minute audio about [their success story]. While speaking, use the vocabulary items and the outline below. Listen to the result (>). If everything is OK, click "save". If not, record again. Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can produce a structured short talk related to the UNIT_NAME using required topical vocabulary WORDS_LIST'
    },
    {
        label: 'Writing',
        value: 'writing',
        taskText: 'Write [задание формулируется преподавателем] (100-150 words). Use the vocabulary items WORDS_LIST and the tips below. Upon completion, click "complete exercise".',
        outcomeMask: 'Learning outcome: now you can produce a piece of writing related to the context of UNIT_NAME  using required topical vocabulary WORDS_LIST'
    },
    {
        label: 'Drag and drop',
        value: 'drag_and_drop',
        taskText: 'Drag the words and drop them into the answer area to complete the task below.',
        outcomeMask: 'Learning outcome: can choose the right match for a(n) word/expression from the WORDS_LIST of the Unit UNIT_NAME.'
    },

    {
        label: 'Cards',
        value: 'cards',
        taskText: 'Watch the clip. Repeat the phrase each time from the beginning, changing the words in square brackets with the one on the screen. Listen to the result (>). If everything is OK, click "save". If not, record again. Upon completion, click "complete exercise".',
        outcomeMask: 'Now you can recognise and repeat the word from the vocabulary list WORDS_LIST or grammar pattern on topic of the Unit UNIT_NAME'
    }


]


export default function ExerciseInfoForm(props) {

    const {
        onSave = d => {

        },
        loading = false
    } = props;

    const [accessGroupsIds, setAccessGroupsIds] = useState(props?.accessGroupsIds ? props.accessGroupsIds : []);
    const [name, setName] = useState(props.name ? props.name : '');
    const [lingCategory, setLingCategory] = useState(props.lingCategory ? props.lingCategory : 'V');

    const [access, setAccess] = useState(props.access ? props.access : 'private');
    const [grammars, setGrammars] = useState(props.grammars ? props.grammars : []);
    const [keywords, setKeywords] = useState(props.keywords ? props.keywords : []);
    const [words, setWords] = useState(props.words ? props.words : []);

    const [outcome, setOutcome] = useState(props.outcome ? props.outcome : '');

    const [description, setDescription] = useState(props.description ? props.description : '');
    const [weight, setWeight] = useState(props.weight != undefined ? +props.weight : 1);
    const [task, setTask] = useState(props.task ? props.task : '');
    const [educationResult, setEducationResult] = useState(props.educationResult ? props.educationResult : '');
    const [copyrightText, setCopyrightText] = useState(props.copyrightText ? props.copyrightText : '');
    const [avatar, setAvatar] = useState(props.avatar ? props.avatar : '');
    const [videoUrl, setVideoUrl] = useState(props.videoUrl);
    const [exerciseType, setExerciseType] = useState(props.exerciseType);
    const [citationModalVisible, setCitationModalVisible] = useState(false);


    const [selectVideoModalVisible, setSelectVideoModalVisible] = useState(false);

    console.log('ExerciseInfoForm: render: window.prouniSelectedSlide = ', window.prouniSelectedSlide);
    let allGrammars = (window.prouniSelectedSlide?.prouni?.grammars || []);
    let allProUniWords = (window.prouniSelectedSlide?.proUniWords || []);
    let allProUniKeyWords = (window.prouniSelectedSlide?.proUniKeyWords || []);
    let unitName = (window.prouniSelectedSlide?.prouni?.unit || '');

    console.log('props.exerciseType = ', props.exerciseType);
    console.log('allProUniKeyWords: ', allProUniKeyWords);
    console.log('allProUniWords: ', allProUniWords);
    let allProUniKeyWordsOptions = allProUniKeyWords.map(x => (`${x.word}${(x.types || []).length > 0 ? ` (${x.types.join(', ')})` : ''}`))
    let allProUniWordsOptions = allProUniWords.map(x => (`${x.word}${(x.types || []).length > 0 ? ` (${x.types.join(', ')})` : ''}`))


    return (
        <Wrapper className={'update_drochka_form'}>

            <div className="ui form ">

                <Row className="field">
                    <Label>
                        Название
                    </Label>
                    <Input value={name} placeholder={'Название'} onChange={(evt) => {
                        setName(evt.target.value);
                    }}/>
                </Row>

                {UIHelper.isProUniMode() == false ? null :
                    <Row className="field">
                        <Label>
                            Лингвистическая категория упражнения
                        </Label>
                        <Select options={PROUNI_LING_CATEGORIES}
                                value={PROUNI_LING_CATEGORIES.filter(x => (x.value == lingCategory))[0]}
                                onChange={a => {
                                    setLingCategory(a.value);
                                }}/>
                    </Row>
                }

                {UIHelper.isProUniMode() == false ? null :
                    <Row className="field">
                        <Label>
                            Тип упражнения
                        </Label>
                        <Select options={TYPES_OPTIONS}
                                value={TYPES_OPTIONS.filter(x => (x.value == exerciseType))[0]}
                                placeholder={'Тип упражнения'} onChange={a => {
                            let val = a?.value;
                            setExerciseType(val);
                            let rp = TYPES_OPTIONS.filter(x => (x.value == val))[0];
                            if (rp != undefined) {
                                setTask(rp?.taskText);
                                setEducationResult(rp.outcomeMask.replace(/UNIT_NAME/g, `"${unitName}"`).replace(/WORDS_LIST/g, `(${words.join(', ')})`));
                            }
                        }}/>
                    </Row>
                }

                {UIHelper.isProUniMode() == false ? null :
                    <Row className="field">
                        <Label>
                            Целевые грамматические конструкции
                        </Label>
                        <SmartGrammarSelector allOptions={allGrammars}
                                              value={grammars}
                                              placeholder={'Целевые грамматические конструкции'}
                                              onChange={a => {
                                                  setGrammars(a);
                                              }}/>
                    </Row>
                }

                {UIHelper.isProUniMode() == false ? null :
                    <Row className="field">
                        <Label>
                            Целевые тематические слова
                        </Label>
                        <SmartGrammarSelector allOptions={allProUniWordsOptions}
                                              value={words}
                                              placeholder={'Целевые тематические слова'}
                                              onChange={a => {
                                                  setWords(a);
                                                  let rp = TYPES_OPTIONS.filter(x => (x.value == exerciseType))[0];
                                                  if (rp != undefined) {
                                                      setTask(rp?.taskText);
                                                      setEducationResult(rp.outcomeMask.replace(/UNIT_NAME/g, `"${unitName}"`).replace(/WORDS_LIST/g, `(${a.join(', ')})`));
                                                  }
                                              }}/>
                    </Row>
                }

                {/*{UIHelper.isProUniMode() == false ? null :*/}
                {/*    <Row className="field">*/}
                {/*        <Label>*/}
                {/*            Ключевые слова*/}
                {/*        </Label>*/}
                {/*        <SmartGrammarSelector allOptions={allProUniKeyWordsOptions}*/}
                {/*                              value={keywords}*/}
                {/*                              placeholder={'Ключевые слова'}*/}
                {/*                              onChange={a => {*/}
                {/*                                  setKeywords(a);*/}
                {/*                                  let rp = TYPES_OPTIONS.filter(x => (x.value == exerciseType))[0];*/}
                {/*                                  if (rp != undefined) {*/}
                {/*                                      setTask(rp?.taskText);*/}
                {/*                                      setEducationResult(rp.outcomeMask.replace(/UNIT_NAME/g, `"${unitName}"`).replace(/WORDS_LIST/g, `(${words.join(', ')})`));*/}
                {/*                                  }*/}
                {/*                              }}/>*/}
                {/*    </Row>*/}
                {/*}*/}


                <Row className="field">
                    <Label>
                        Формулировка задания для обучающегося
                    </Label>
                    <Textarea value={task} placeholder={'Формулировка задания для обучающегося'} onChange={(evt) => {
                        setTask(evt.target.value);
                    }}></Textarea>
                </Row>

                <Row className="field">
                    <Label>
                        Задание
                    </Label>
                    {/*<Textarea value={description} placeholder={'Описание'} onChange={(evt) => {*/}
                    {/*    setDescription(evt.target.value);*/}
                    {/*}}></Textarea>*/}

                    <ReactQuill value={description}
                                modules={{toolbar: toolbarOptions}}
                                onChange={s => {
                                    setDescription(s);
                                }}/>


                    {/*<Editor*/}
                    {/*    onInit={(evt, editor) => {*/}
                    {/*        editorRef.current = editor;*/}
                    {/*        // setTimeout(() => {*/}
                    {/*        //     setLoaded(true);*/}
                    {/*        // }, 100);*/}
                    {/*    }}*/}
                    {/*    initialValue={description}*/}
                    {/*    contextmenu_never_use_native={true}*/}
                    {/*    apiKey={TINY_MCE_API_KEY}*/}
                    {/*    init={{*/}
                    {/*        height: 300,*/}
                    {/*        menubar: false,*/}
                    {/*        contextmenu_never_use_native: true,*/}
                    {/*        plugins: [*/}
                    {/*            'contextmenu advlist autolink lists link image charmap print preview anchor',*/}
                    {/*            'searchreplace visualblocks code fullscreen',*/}
                    {/*            'insertdatetime media table code help wordcount'*/}
                    {/*        ],*/}
                    {/*        contextmenu: "paste | link image inserttable | cell row column deletetable",*/}
                    {/*        toolbar: 'undo redo | formatselect | ' +*/}
                    {/*            'bold italic backcolor forecolor | alignleft aligncenter ' +*/}
                    {/*            'alignright alignjustify | bullist numlist outdent indent | ' +*/}
                    {/*            'table | ' +*/}
                    {/*            'media pageembed | ' +*/}
                    {/*            'charmap emoticons | ' +*/}
                    {/*            'removeformat | help',*/}
                    {/*        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'*/}
                    {/*    }}*/}
                    {/*/>*/}

                </Row>


                {UIHelper.isProUniMode() == false ? null :
                    <Row className="field">
                        <Label>
                            Образовательный результат
                        </Label>
                        {(exerciseType == undefined || exerciseType == '') ? 'Выберите тип упражнения' :
                            <Textarea value={educationResult} placeholder={'Образовательный результат'}
                                      onChange={(evt) => {
                                          setEducationResult(evt.target.value);
                                      }}></Textarea>
                        }
                    </Row>
                }


                {/*{UIHelper.isProUniMode() == false ? null :*/}
                <Row className="field">
                    <Label>
                        Вес (сложность)
                    </Label>
                    <Input value={weight} onChange={evt => {
                        setWeight(evt.target.value);
                    }}/>
                </Row>
                {/*}*/}

                <Row className="field">
                    <Label>
                        <span>
                            Информация об источниках
                        </span>
                        <CitImg src={cit_img} onClick={() => {
                            setCitationModalVisible(true);
                        }}/>
                    </Label>
                    <Textarea value={copyrightText} placeholder={'Информация об источниках'} onChange={(evt) => {
                        setCopyrightText(evt.target.value);
                    }}></Textarea>
                </Row>

                <Row className="field">
                    <Label>
                        <span>
                            Доступ
                        </span>
                    </Label>
                    <Select options={ACCESS_OPTIONS}
                            value={ACCESS_OPTIONS.filter(x => (x.value == access))[0]}
                            onChange={a => {
                                setAccess(a.value);
                            }}/>
                </Row>

                {access != 'shared' ? null :
                    <Row className="field">
                        <Label>
                        <span>
                            Группы доступа
                        </span>
                        </Label>
                        <AccessGroupsDropdownPanel
                            selectedIds={accessGroupsIds}
                            onChange={x => {
                                setAccessGroupsIds(x);
                            }}
                        />
                    </Row>
                }

                <Row className="field">
                    <Label>
                        Аватар
                    </Label>
                    <div>
                        {avatar == undefined ? null :
                            <img src={avatar}/>
                        }
                    </div>
                    <SimpleFileUploader
                        currentImage={avatar}
                        onSave={url => {
                            setAvatar(url);
                        }}
                    />
                    {avatar == '' ? null :
                        <DelelteAvatarButton onClick={() => {
                            setAvatar(''); 
                         }}>удалить</DelelteAvatarButton>
                    }
                </Row>

                <div className="field">
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        <GreenButton className={'ui primary button fluid'} onClick={() => {
                            if (name == undefined || name == '') {
                                return window.alert('Name is required.');
                            }
                            onSave({
                                name,
                                description,
                                task,
                                avatar,
                                copyrightText,
                                lingCategory: lingCategory,
                                accessGroupsIds: accessGroupsIds,
                                access: access,
                                words: words,
                                keywords: keywords,
                                exerciseType: exerciseType,
                                grammars: grammars,
                                educationResult: educationResult,
                                weight: (weight == undefined || window.isNaN(weight) == true) ? 1 : +weight
                            })
                        }}>
                            <i className={'icon save'}></i> Сохранить
                        </GreenButton>
                    }
                </div>

            </div>


            <Sidebar visible={selectVideoModalVisible}
                     width={Math.min(window.innerWidth, 1280)}
                     onCloserClick={() => {
                         setSelectVideoModalVisible(false);
                     }}>

                {selectVideoModalVisible == false ? null :
                    <MaterialSearchPanel
                        type={'materials'} onSelect={m => {
                        setVideoUrl(m.url);
                        setSelectVideoModalVisible(false);
                    }}/>
                }

            </Sidebar>

            <Sidebar width={window.innerWidth} visible={citationModalVisible} onCloserClick={() => {
                setCitationModalVisible(false);
            }}>

                {citationModalVisible == false ? null :
                    <InnerPlaceholder>
                        <SimpleCitationTool
                            onQuote={s => {
                                setCopyrightText(`${copyrightText == undefined ? '' : copyrightText}\n${s}`);
                                setCitationModalVisible(false);
                            }}
                        />
                    </InnerPlaceholder>
                }

            </Sidebar>

        </Wrapper>
    );
}

const InnerPlaceholder = styled.div`
  height: calc(100vh - 80px);
  width: calc(100vw - 100px);
`;

const CitImg = styled.img`
  opacity: 0.7;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`

`;

const Video = styled.video`
  width: 540px;
  height: 320px;
`;

const DelelteAvatarButton = styled.div`
  color: red;
  cursor: pointer;
`;