import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import ViewSlideTool from "../../slides/tools/ViewSlideTool";
import ExercisePanel from "../../exercises/panels/ExercisePanel";
import DrochkaExercisePanel from "../../drochka_exercise/panels/DrochkaExercisePanel";

import not_tests from '../../../assets/lottie/not_tests.json'

import books_lottie from '../../../assets/lottie/books_lottie.json'
import exam_lottie from '../../../assets/lottie/exam_lottie.json'
import exam2_lottie from '../../../assets/lottie/exam2_lottie.json'
import treadmills_lottie from '../../../assets/lottie/female-employee-stretching-in-chair.json'

import LottieLoader from "../../loaders/LottieLoader";
import TestsListInfoTool from "../../tests/tools/TestsListInfoTool";
import PasteTool from "./PasteTool";
import close_x from "../../../assets/img/close_e.svg";
import NiceModal from "../../modals/NiceModal";
import SlideItemEditTool from "./SlideItemEditTool";
import TreadmillsListViewTool from "../../treadmills_exercises/panels/TreadmillsListViewTool";

import adding_svg from '../../../assets/img/add.svg'
import MaterialSearchPanel from "../../materials/panels/MaterialSearchPanel";
import SearchOrCreateMaterialsPanel from "../../materials/panels/SearchOrCreateMaterialsPanel";
import DialogExercisePanel from "../../dialogs/panels/DialogExercisePanel";
import QuestionnairePanel from "../../questionnaires/panels/QuestionnairePanel";
import {isMobile} from "polotno/utils/screen";

const TABS = [
    {
        label: 'Модуль',
        value: 'items'
    },
    {
        label: 'Упражнения',
        value: 'exercises'
    },
    {
        label: 'Карточки',
        value: 'cards'
    },
    {
        label: 'Тредмилы',
        value: 'treadmills'
    },
    {
        label: 'Тесты',
        value: 'tests'
    },
    {
        label: 'Диалоги',
        value: 'dialogs'
    },
    {
        label: 'Опросники',
        value: 'questionnaires'
    }
]

export const AddComponent = ({text = 'Добавить'}) => {
    return (
        <AddingWrapper>
            <AddingIcon src={adding_svg}/>
            <AddingText>
                {text}
            </AddingText>
        </AddingWrapper>
    )
}

export default function TaskEditor(props) {
    const {
        isOnlineMode = false,
        onlineHiddenItemsIds = [],
        groupId,

        onOnlineEyeToggle = eId => {

        },

        onTranscriptOnlineEyeToggle = eId => {

        },


        onSyncVideoSelect = videoUrl => {

        },

        buffer,
        canPasteIntoItem = false,
        showMoveToMeButton = false,
        onMoveToBlockClick = divId => {

        },

        isForceTab = false,
        forceTab = undefined,
        selectedTaskId,

        userId,
        canEdit = true,
        items = [],

        isPreview = false,

        questionnairesIds = [],
        exercisesIds = [],
        dialogsIds = [],
        testsIds = [],
        drochkaExercisesIds = [],
        treadmillsIds = [],

        loading = false,
        onChange = d => {

        },

        onTabChange = tb => {

        }

    } = props;

    const [tab, setTab] = useState('items'); // items / exercises / cards / dialogs / questionnaires / treadmills
    let numMap = {
        exercises: exercisesIds.length,
        cards: drochkaExercisesIds.length,
        tests: testsIds.length,
        treadmills: treadmillsIds.length,
        dialogs: dialogsIds.length,
        questionnaires: questionnairesIds.length,
    }
    let realTab = (isForceTab == true) ? forceTab : tab;
    const [editItemId, setEditItemId] = useState();

    const editItem = (editItemId == undefined) ? undefined : items.filter(x => (x.id == editItemId))[0];
    const [addingMode, setAddingMode] = useState();

    const allSelIds = exercisesIds.concat(dialogsIds).concat(questionnairesIds).concat(drochkaExercisesIds).concat(testsIds).concat(treadmillsIds);

    console.log('TaskEditor: userId = ', userId);
    console.log('TaskEditor: allSelIds = ', allSelIds);
    console.log('TaskEditor: groupId = ', groupId);


    return (
        <Wrapper>

            <TopTabsPlaceholder>
                {TABS.map((a, i) => {
                    let num = numMap[a.value];
                    return (
                        <TabItem key={a.value}
                                 selected={a.value == realTab}
                                 onClick={() => {
                                     setTab(a.value);
                                     if (isForceTab) {
                                         onTabChange(a.value);
                                     }
                                 }}>
                            {a.label}
                            {(num == undefined || num == 0) ? null :
                                <span style={{opacity: 0.6}}>
                                    {` (${num})`}
                                </span>
                            }
                        </TabItem>
                    )
                })}
            </TopTabsPlaceholder>

            <SelectedTabPlaceholder>

                {realTab != 'items' ? null :
                    <div>

                        {items.length > 0 ? null :
                            <VoidListLottiePlaceholder>
                                <LottieLoader json={exam2_lottie}/>
                                <VoidListTextPlaceholder>
                                    В модуле еще нет материалов.
                                    <br/>
                                    {canEdit == false ? null : 'Добавьте материалы из раздела слева.'}
                                </VoidListTextPlaceholder>
                            </VoidListLottiePlaceholder>
                        }
                        <ViewSlideTool
                            {...props}
                            buffer={buffer}
                            isOnlineMode={isOnlineMode}
                            showMoveToMeButton={showMoveToMeButton}
                            onMoveToBlockClick={onMoveToBlockClick}
                            canSwitchTabs={false}
                            items={items}
                            canEdit={canEdit}
                            selectedTaskId={selectedTaskId}
                            useTranslatableTranscript={isOnlineMode}
                            canDeleteItem={true}
                            canPasteIntoItem={canPasteIntoItem}
                            onlineHiddenItemsIds={onlineHiddenItemsIds}
                            onChange={d => {
                                onChange({
                                    ...d
                                })
                            }}
                            onEditItemClick={eId => {
                                setEditItemId(eId);
                            }}
                            onOnlineEyeToggle={eId => {
                                console.log('onOnlineEyeToggle: eId = ', eId);
                                onOnlineEyeToggle(eId);
                            }}
                            onTranscriptOnlineEyeToggle={eId => {
                                console.log('onTranscriptOnlineEyeToggle: eId = ', eId);
                                onTranscriptOnlineEyeToggle(eId);
                            }}

                            onSyncVideoSelect={videoUrl => {
                                onSyncVideoSelect(videoUrl);
                            }}
                        />

                        {canEdit == false ? null :
                            <PasteTool
                                type={'SLIDE_ITEM'}
                                onSlideItemAdd={a => {
                                    let newItems = items.concat([
                                        {
                                            ...a,
                                            id: `cp-${+new Date()}`
                                        }
                                    ]);
                                    onChange({
                                        items: newItems
                                    })
                                }}
                            />
                        }

                    </div>
                }

                {realTab != 'exercises' ? null :
                    <div>
                        {exercisesIds.length > 0 ? null :
                            <VoidListLottiePlaceholder>
                                <LottieLoader json={books_lottie}/>
                                <VoidListTextPlaceholder>
                                    В данном задании ещё нет упражнений.
                                </VoidListTextPlaceholder>
                            </VoidListLottiePlaceholder>
                        }
                        {exercisesIds.map((exId, i) => {
                            return (
                                <ExItem key={`${exId}_${i}`}>

                                    {canEdit == false ? null :
                                        <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>
                                            <CopyImg src={close_x}
                                                     onClick={() => {
                                                         let newIds = exercisesIds.filter(b => (b != exId));
                                                         onChange({
                                                             exercisesIds: newIds
                                                         })
                                                     }}
                                            />
                                        </DeleteControlsPlaceholder>
                                    }

                                    <ExercisePanel
                                        userId={userId}
                                        id={exId}
                                        isPreview={isPreview}
                                    />
                                </ExItem>
                            )
                        })}

                        {canEdit == false ? null :
                            <BottomAddingRow>

                                <PasteTool
                                    type={'EXERCISE'}
                                    onExerciseIdAdd={exId => {
                                        let newIds = exercisesIds.concat([exId]);
                                        onChange({
                                            exercisesIds: newIds
                                        })
                                    }}
                                />

                                <AddingCell onClick={() => {
                                    setAddingMode('exercises');
                                }}>
                                    <AddComponent text={'Добавить'}/>
                                </AddingCell>


                            </BottomAddingRow>
                        }


                        {/*{JSON.stringify(exercisesIds)}*/}
                    </div>
                }

                {realTab != 'cards' ? null :
                    <div>

                        {drochkaExercisesIds.length > 0 ? null :
                            <VoidListLottiePlaceholder>
                                <LottieLoader json={not_tests}/>
                                <VoidListTextPlaceholder>
                                    В данном задании ещё нет карточек.
                                </VoidListTextPlaceholder>
                            </VoidListLottiePlaceholder>
                        }

                        {drochkaExercisesIds.map((exId, i) => {
                            return (
                                <ExItem key={`${exId}_${i}`}>

                                    {canEdit == false ? null :
                                        <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>
                                            <CopyImg src={close_x}
                                                     onClick={() => {
                                                         let newIds = drochkaExercisesIds.filter(b => (b != exId));
                                                         onChange({
                                                             drochkaExercisesIds: newIds
                                                         })
                                                     }}
                                            />
                                        </DeleteControlsPlaceholder>
                                    }

                                    <DrochkaExercisePanel
                                        userId={userId}
                                        id={exId}
                                        isPreview={isPreview}
                                    />
                                </ExItem>
                            )
                        })}

                        {canEdit == false ? null :
                            <BottomAddingRow>

                                <PasteTool
                                    type={'DROCHKA_EXERCISE'}
                                    onDrochkaExerciseIdAdd={exId => {
                                        let newIds = drochkaExercisesIds.concat([exId]);
                                        onChange({
                                            drochkaExercisesIds: newIds
                                        })
                                    }}
                                />

                                <AddingCell onClick={() => {
                                    setAddingMode('cards');
                                }}>
                                    <AddComponent text={'Добавить'}/>
                                </AddingCell>


                            </BottomAddingRow>
                        }

                    </div>
                }

                {realTab != 'treadmills' ? null :
                    <div>

                        {treadmillsIds.length > 0 ?
                            <TreadmillsListViewTool
                                userId={userId}
                                groupId={groupId}
                                canEdit={canEdit}
                                selectedTaskId={selectedTaskId}
                                ids={treadmillsIds}
                                isOnlineMode={isOnlineMode}
                                canDelete={true}
                                onDelete={delId => {
                                    let newIds = treadmillsIds.filter(b => (b != delId));
                                    onChange({
                                        treadmillsIds: newIds
                                    })
                                }}
                            />
                            :

                                <VoidListLottiePlaceholder>
                                    <LottieLoader json={treadmills_lottie}/>

                                    <VoidListTextPlaceholder>
                                        'В данном задании ещё нет тредмилов.'
                                    </VoidListTextPlaceholder>

                                </VoidListLottiePlaceholder>
                        }

                        {canEdit == false ? null :
                            <BottomAddingRow>
                                <PasteTool
                                    type={'TREADMILL'}
                                    onTreadmillIdAdd={exId => {
                                        let newIds = treadmillsIds.concat([exId]);
                                        onChange({
                                            treadmillsIds: newIds
                                        })
                                    }}
                                />
                                <AddingCell onClick={() => {
                                    setAddingMode('treadmills');
                                }}>
                                    <AddComponent text={'Добавить'}/>
                                </AddingCell>
                            </BottomAddingRow>


                        }

                    </div>
                }

                {realTab != 'dialogs' ? null :
                    <div>

                        {dialogsIds.map((exId, i) => {
                            return (
                                <ExItem key={`${exId}_${i}`}>

                                    {canEdit == false ? null :
                                        <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>
                                            <CopyImg src={close_x}
                                                     onClick={() => {
                                                         let newIds = dialogsIds.filter(b => (b != exId));
                                                         onChange({
                                                             dialogsIds: newIds
                                                         })
                                                     }}
                                            />
                                        </DeleteControlsPlaceholder>
                                    }

                                    <DialogExercisePanel
                                        userId={userId}
                                        id={exId}
                                        isPreview={isPreview}
                                    />
                                </ExItem>
                            )
                        })}

                        {dialogsIds.length > 0 ? null :
                            <VoidListLottiePlaceholder>
                                <LottieLoader json={treadmills_lottie}/>
                                <VoidListTextPlaceholder>
                                    В данном задании ещё нет диалогов.
                                </VoidListTextPlaceholder>
                            </VoidListLottiePlaceholder>
                        }

                        {canEdit == false ? null :
                            <BottomAddingRow>
                                <PasteTool
                                    type={'DIALOG'}
                                    onTreadmillIdAdd={exId => {
                                        let newIds = dialogsIds.concat([exId]);
                                        onChange({
                                            dialogsIds: newIds
                                        })
                                    }}
                                />
                                <AddingCell onClick={() => {
                                    setAddingMode('dialogs');
                                }}>
                                    <AddComponent text={'Добавить'}/>
                                </AddingCell>
                            </BottomAddingRow>

                        }

                    </div>
                }

                {realTab != 'questionnaires' ? null :
                    <div>

                        {questionnairesIds.map((exId, i) => {
                            return (
                                <ExItem key={`${exId}_${i}`}>

                                    {canEdit == false ? null :
                                        <DeleteControlsPlaceholder className={'delete_controls_placeholder'}>
                                            <CopyImg src={close_x}
                                                     onClick={() => {
                                                         let newIds = questionnairesIds.filter(b => (b != exId));
                                                         onChange({
                                                             questionnairesIds: newIds
                                                         })
                                                     }}
                                            />
                                        </DeleteControlsPlaceholder>
                                    }

                                    <QuestionnairePanel
                                        userId={userId}
                                        id={exId}
                                        isPreview={isPreview}
                                    />
                                </ExItem>
                            )
                        })}

                        {dialogsIds.length > 0 ? null :
                            <VoidListLottiePlaceholder>
                                <LottieLoader json={treadmills_lottie}/>
                                <VoidListTextPlaceholder>
                                    В данном задании ещё нет опросников.
                                </VoidListTextPlaceholder>
                            </VoidListLottiePlaceholder>
                        }

                        {canEdit == false ? null :
                            <BottomAddingRow>
                                <PasteTool
                                    type={'QUESTIONNAIRE'}
                                    onTreadmillIdAdd={exId => {
                                        let newIds = questionnairesIds.concat([exId]);
                                        onChange({
                                            questionnairesIds: newIds
                                        })
                                    }}
                                />
                                <AddingCell onClick={() => {
                                    setAddingMode('questionnaires');
                                }}>
                                    <AddComponent text={'Добавить'}/>
                                </AddingCell>
                            </BottomAddingRow>

                        }

                    </div>
                }

                {realTab != 'tests' ? null :
                    <div>
                        {testsIds.length > 0 ?
                            <TestsListInfoTool
                                userId={userId}
                                canEdit={canEdit}
                                ids={testsIds}
                                canDelete={true}
                                onDelete={delId => {
                                    let newIds = testsIds.filter(b => (b != delId));
                                    onChange({
                                        testsIds: newIds
                                    })
                                }}
                            />
                            :
                            <VoidListLottiePlaceholder>
                                <LottieLoader json={exam_lottie}/>
                                <VoidListTextPlaceholder>
                                    В данном задании ещё нет тестов.
                                </VoidListTextPlaceholder>
                            </VoidListLottiePlaceholder>
                        }

                        {canEdit == false ? null :
                            <PasteTool
                                type={'TEST'}
                                onTestIdAdd={exId => {
                                    let newIds = testsIds.concat([exId]);
                                    onChange({
                                        testsIds: newIds
                                    })
                                }}
                            />
                        }

                        {canEdit == false ? null :
                            <AddingCell onClick={() => {
                                setAddingMode('tests');
                            }}>
                                <AddComponent text={'Добавить'}/>
                            </AddingCell>
                        }

                        {/*{JSON.stringify(testsIds)}*/}
                    </div>
                }

            </SelectedTabPlaceholder>

            {editItem == undefined ? null :
                <NiceModal onClose={() => {
                    setEditItemId(undefined);
                }}>

                    <ItemEditModalInner
                        style={{
                            width: (editItem.type == 'image' ? 'calc(100vw - 160px)' : undefined),
                            height: (editItem.type == 'image' ? 'calc(100vh - 160px)' : 'auto'),
                            maxHeight: (editItem.type == 'image' ? 'calc(100vh - 90px)' : '90vh'),
                            overflowY: (editItem.type == 'image' ? 'none' : 'auto')
                        }}
                    >
                        <SlideItemEditTool
                            type={editItem?.type}
                            item={editItem}
                            mode={'edit'}
                            onChange={updItem => {
                                let newItems = items.map((a, i) => (a.id == editItem.id ? updItem : a));
                                onChange({
                                    items: newItems
                                });
                                setEditItemId(undefined);
                            }}
                        />
                    </ItemEditModalInner>

                </NiceModal>
            }

            {addingMode == undefined ? null :
                <NiceModal onClose={() => {
                    setAddingMode(undefined);
                }}>

                    <ItemEditModalInner
                        style={{height: `calc(100vh - 120px)`}}
                    >
                        <SearchOrCreateMaterialsPanel
                            type={addingMode}
                            ids={allSelIds}
                            onSelect={x => {
                                if (addingMode == 'exercises') {
                                    let newIds = exercisesIds.concat([x.id]);
                                    onChange({
                                        exercisesIds: newIds
                                    })
                                }
                                if (addingMode == 'dialogs') {
                                    let newIds = dialogsIds.concat([x.id]);
                                    onChange({
                                        dialogsIds: newIds
                                    })
                                }
                                if (addingMode == 'questionnaires') {
                                    let newIds = questionnairesIds.concat([x.id]);
                                    onChange({
                                        questionnairesIds: newIds
                                    })
                                }

                                if (addingMode == 'cards') {
                                    let newIds = drochkaExercisesIds.concat([x.id]);
                                    onChange({
                                        drochkaExercisesIds: newIds
                                    })
                                }
                                if (addingMode == 'tests') {
                                    let newIds = testsIds.concat([x.id]);
                                    onChange({
                                        testsIds: newIds
                                    })
                                }
                                if (addingMode == 'treadmills') {
                                    let newIds = treadmillsIds.concat([x.id]);
                                    onChange({
                                        treadmillsIds: newIds
                                    })
                                }
                                // setAddingMode(undefined);
                            }}
                        />
                    </ItemEditModalInner>

                </NiceModal>
            }


        </Wrapper>
    );
}

const AddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const AddingIcon = styled.img`
  height: 58px;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const AddingText = styled.div`
  color: #ffc02c;
  margin-top: 2px;
`;

const BottomAddingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const AddingCell = styled.div`
  margin-left: 7px;
  margin-right: 7px;
`;

const ItemEditModalInner = styled.div`
  width: 1300px;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: calc(100vw - 80px);
  }
`;

const DeleteControlsPlaceholder = styled.div`
  display: none;
  position: absolute;
  z-index: 2;
  right: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.75);
  padding: 5px;
`;

const CopyImg = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const BufferPastePlaceholder = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PastImage = styled.img`
  width: 60px;
  cursor: pointer;
  opacity: 0.75;

  :hover {
    opacity: 1;
  }
`;

const VoidListLottiePlaceholder = styled.div`
  width: 100%;
  height: 320px;
`;

const VoidListTextPlaceholder = styled.div`
  text-align: center;
  margin-top: -50px;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
`;


const SelectedTabPlaceholder = styled.div`

`;

const ExItem = styled.div`
  margin-bottom: 15px;
  position: relative;

  :hover .delete_controls_placeholder {
    display: block;
  }
`;

const Wrapper = styled.div`
  min-height: 300px;

`;

const TopTabsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  
  @media (max-width: 720px) {
    overflow-x: auto;
  }
`;

const TabItem = styled.div`
  margin-left: 7px;
  margin-right: 7px;
  border-bottom: ${props => (props.selected == true ? '4px solid #FFC02C' : '4px solid transparent')};
  padding-bottom: 5px;
  cursor: pointer;
  font-size: 14px;

  :first-of-type {
    margin-left: 0px;
  }

  :hover {
    opacity: 0.8;
  }
`;