import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import Tabs, {TabItem} from "../../ui/Tabs";
import CommentTool from "./CommentTool";
import GeneralAITool from "./GenealAITool";
import GrammarGapsTool from "./GrammarGapsTool";
import WordsGapsTool from "./WordsGapsTool";
import SimpleAISearchTool from "../../ai/tools/SimpleAISearchTool";
import TextToSpeechTool from "../../ai/tools/TextToSpeechTool";

export default function AiTools(props) {


    return (
        <Wrapper>

            <Tabs
                tabs={[
                    {
                        label: 'Комментирование ответа студента',
                        content: (
                            <TabItem>
                                <CommentTool/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Gen AI',
                        content: (
                            <TabItem>
                                <GeneralAITool/>
                            </TabItem>
                        )
                    },

                    {
                        label: 'Grammar - Gaps',
                        content: (
                            <TabItem>
                                <GrammarGapsTool/>
                            </TabItem>
                        )
                    },

                    {
                        label: 'Words - Gaps',
                        content: (
                            <TabItem>
                                <WordsGapsTool/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Search - experimantal',
                        content: (
                            <TabItem>
                                <SimpleAISearchTool/>
                            </TabItem>
                        )
                    },
                    {
                        label: 'Text to Speech',
                        content: (
                            <TabItem>
                                <TextToSpeechTool/>
                            </TabItem>
                        )
                    }


                ]}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;