import React, {useState, useCallback} from 'react';
import styled from 'styled-components';

import {BlueButton} from "../../ira/ui/Buttons";
import {Input} from "../../ira/ui/Inputs";
import {translate} from "../../../utils/Translate";

import Spin from 'arui-feather/spin'
import {useMappedState} from "redux-react-hook";

export default function UpdateFolderForm(props) {
    const {
        onSave = d => {

        },
        onCancel = () => {

        },
        loading = false
    } = props;

    const [name, setName] = useState(props?.name || '');
    const {
        language
    } = useMappedState(useCallback(state => state.language.language))

    return (
        <Wrapper>
            <Field style={{marginBottom: 15}}>
                <Input value={name}
                       autoFocus={true}
                       placeholder={'Название'}
                       onChange={evt => {
                           setName(evt.target.value);
                       }}/>
            </Field>

            <Field>
                <BlueButton onClick={() => {
                    if (loading) {
                        return;
                    }
                    onSave({
                        name: name
                    })
                }}>
                    <Spin visible={loading}/>
                    {/*{translate('button__save', language)}*/}
                    Сохранить
                </BlueButton>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 5px;
`;